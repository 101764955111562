import { Divider } from '@mui/material';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';

const SecuritySection = () => {
    const user = getUserInfoFromLocalStorage()!;
    return (
        <div className="security">
            <div className="font-semibold text-[18px] mb-[14px]">Security</div>
            <div className="flex flex-wrap gap-[10px] justify-between items-center lg:w-[722px] rounded-[8px] bg-my-gray bg-opacity-20 border border-my-gray border-opacity-40 p-[18px]">
                <div>
                    <div className="text-black text-[16px] font-medium">
                        Enable login with phone number
                    </div>
                    <div className="text-my-black">
                        We'll send a 6-digit verification code to your phone
                        number.
                    </div>
                </div>
                <ButtonWithIcon
                    onClick={() => {}}
                    text="Enable"
                    disabled={!user?.phone}
                    className={`!rounded-[8px] !h-[42px] ${
                        !user?.phone ? '!bg-my-gray' : '!bg-my-black'
                    }`}
                />
            </div>
            <Divider className="!my-8 !max-w-[722px]" />
        </div>
    );
};

export default SecuritySection;
