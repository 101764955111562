import { isEqual } from 'lodash';
import { queryClient } from 'routes';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { SavedFilter } from 'types/PropertyFilters';

export const useFilterLogic = (
    childRef?: React.RefObject<{ closePopup: () => void }>,
) => {
    const { currentFilter, savedFilterList, setCurrentFilter } =
        useFiltersStore();
    const { currentPage, fetchCompetitorAllPropertiesSort } =
        usePropertiesStore();

    const currentIndex = savedFilterList.findIndex((filter) =>
        isEqual(filter.filter, currentFilter),
    );

    const onFilterClicked = (filter: SavedFilter) => {
        setCurrentFilter(filter.filter);
        if (childRef?.current) childRef.current.closePopup();
        queryClient.resetQueries({
            queryKey: [
                'fetchCompetitorAllProperties',
                currentPage,
                filter.filter,
                fetchCompetitorAllPropertiesSort,
            ],
        });
    };

    return {
        currentFilter,
        savedFilterList,
        currentIndex,
        onFilterClicked,
        setCurrentFilter,
    };
};
