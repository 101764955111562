import MainLayout from 'pages/MainLayout';
import { useUtilsStore } from 'store/useUtilsStore';
import CopmetitorsTab from './competitors';
import OverViewTab from './overview';
import { useEffect, useState } from 'react';
import LimitedModal from 'components/Modals/Limited';
import { isExceed } from 'util/getUserPlan';

const MainPage = () => {
    const { topTabIndex } = useUtilsStore();
    const [showLimitedModalFlag, setShowLimitedModalFlag] =
        useState<boolean>(false);

    useEffect(() => {
        const isExceedFlag = isExceed();
        setShowLimitedModalFlag(isExceedFlag && topTabIndex === 1);
    }, [topTabIndex]);

    return (
        <MainLayout>
            <div className="lg:max-w-hello mx-auto">
                <div className={topTabIndex === 0 ? '' : 'hidden'}>
                    <OverViewTab />
                </div>

                <div className={topTabIndex === 1 ? '' : 'hidden'}>
                    <CopmetitorsTab />
                </div>
            </div>

            <LimitedModal
                visible={showLimitedModalFlag}
                onClose={() => setShowLimitedModalFlag(false)}
            />
        </MainLayout>
    );
};

export default MainPage;
