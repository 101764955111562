import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { Agent } from 'types/Agent';
import { AgentFilterType } from 'types/AgentFilterType';
import { isExceed } from 'util/getUserPlan';
import {
    getUserInfoFromLocalStorage,
    saveUserInfoToLocalStorage,
} from 'util/localStorageHelper';
import { create } from 'zustand';

interface CompetitorsStore {
    competitors: Agent[];
    currentPage: number;
    countPerPage: number;
    filter: AgentFilterType;
    setPage: (page: number) => void;
    setFilter: (filter: any) => void;
    fetchAllCompetitors: () => Promise<Agent>;
    totalCount: number;
    totalPages: number;
    addCompetitors: (competitors: string[]) => Promise<void>;
    addAllCompetitors: () => Promise<void>;
    selectedCompetitors: string[];
    setSelectedCompetitors: (competitors: string[]) => void;
    removeCompetitor: (competitorId: string) => Promise<void>;
    savedCompetitors: Partial<Agent>[];
    savedCompetitorsCurrentPage: number;
    savedCompetitorsTotalCount: number;
    savedCompetitorsTotalPages: number;
    fetchSavedCompetitors: () => Promise<number>;
    setSavedCompetitorsPage: (page: number) => void;
    savedCompetitorsCountPerPage: number;
    selectedSavedCompetitor: Partial<Agent> | null;
    setSelectedSavedCompetitor: (competitor: Partial<Agent> | null) => void;
    showSavedCompetitorInfo: boolean;
    setShowSavedCompetitorInfo: (show: boolean) => void;
    savedCompetitorsSortAndFilters: {
        sort: string;
        searchTerm: string;
    };

    setSavedCompetitorsSortAndFilters: (
        sort: string,
        searchTerm: string,
    ) => void;
}

const useCompetitorsStore = create<CompetitorsStore>((set, get) => ({
    competitors: [],
    currentPage: 1,
    countPerPage: 15,
    filter: {
        searchTerm: '',
        country: '',
        city: '',
    },
    totalCount: 0,
    loading: false,
    totalPages: 0,
    setPage: (page) => set({ currentPage: page }),
    setFilter: (filter) => set({ filter }),
    get selectedCompetitors() {
        return getUserInfoFromLocalStorage()?.savedCompetitors || [];
    },
    savedCompetitors: [],
    savedCompetitorsCurrentPage: 1,
    savedCompetitorsTotalCount: 0,
    savedCompetitorsTotalPages: 0,
    setSelectedCompetitors: (competitors) =>
        set({ selectedCompetitors: competitors }),
    setSavedCompetitorsPage: (page) =>
        set({ savedCompetitorsCurrentPage: page }),
    savedCompetitorsCountPerPage: 15,
    selectedSavedCompetitor: null,
    setSelectedSavedCompetitor: (competitor) =>
        set({ selectedSavedCompetitor: competitor }),
    showSavedCompetitorInfo: false,
    setShowSavedCompetitorInfo: (show) =>
        set({ showSavedCompetitorInfo: show }),
    savedCompetitorsSortAndFilters: {
        sort: '',
        searchTerm: '',
    },
    setSavedCompetitorsSortAndFilters: (sort, searchTerm) =>
        set({
            savedCompetitorsSortAndFilters: {
                sort,
                searchTerm,
            },
        }),
    fetchAllCompetitors: async () => {
        const { currentPage, countPerPage, filter } = get();
        try {
            const { data } = await axiosInstance.post(
                `/agents/get?page=${currentPage - 1}&count=${countPerPage}`,
                { filter },
            );
            set({
                competitors: data.data.agents,
                totalCount: data.data.totalCount,
                totalPages: Math.ceil(data.data.totalCount / countPerPage),
            });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    async addCompetitors(competitors: string[]) {
        try {
            const { data } = await axiosInstance.post(
                '/user/add-save-competitors',
                {
                    competitors,
                },
            );
            saveUserInfoToLocalStorage(data.data.user);
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    removeCompetitor: async (competitorId) => {
        const { savedCompetitors, selectedSavedCompetitor } = get();
        try {
            const { data } = await axiosInstance.delete(
                `user/remove-save-competitor/${competitorId}`,
            );
            saveUserInfoToLocalStorage(data.data.user);
            const newCompetitors = savedCompetitors.filter(
                (competitor) =>
                    competitor.companyId !== selectedSavedCompetitor?.companyId,
            );
            set({
                savedCompetitors: newCompetitors,
                selectedSavedCompetitor: null,
                showSavedCompetitorInfo: false,
                selectedCompetitors: newCompetitors.map(
                    (competitor) => competitor.companyId,
                ) as string[],
            });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    async addAllCompetitors() {
        const { setSelectedCompetitors } = get();
        try {
            const { data } = await axiosInstance.post(
                '/user/add-all-competitors',
            );
            setSelectedCompetitors(data.data);
            const userInfo = getUserInfoFromLocalStorage()!;
            userInfo.savedCompetitors = data.data;
            saveUserInfoToLocalStorage(userInfo);
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    async fetchSavedCompetitors() {
        const {
            savedCompetitorsCurrentPage,
            countPerPage,
            savedCompetitorsSortAndFilters,
        } = get();

        const isExceedFlag = isExceed();
        if (isExceedFlag) {
            set({
                savedCompetitors: [],
                savedCompetitorsTotalCount: 0,
                savedCompetitorsTotalPages: 0,
            });

            return 0;
        }

        try {
            const { sort, searchTerm } = savedCompetitorsSortAndFilters;
            const { data } = await axiosInstance.post(
                `/agents/getSaved?page=${
                    savedCompetitorsCurrentPage - 1
                }&count=${countPerPage}`,
                {
                    filter: {
                        searchTerm,
                    },
                    sort: { field: sort },
                },
            );
            set({
                savedCompetitors: data.data.agents,
                savedCompetitorsTotalCount: data.data.totalCount,
                savedCompetitorsTotalPages: Math.ceil(
                    data.data.totalCount / countPerPage,
                ),
            });
            return data.data.totalCount;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useCompetitorsStore;
