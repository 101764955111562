import { SubmitHandler, useForm } from 'react-hook-form';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';

interface PaginationProps {
    totalPages: number;
    onPageChange: (page: number) => void;
    currentPage: number;
    countPerPage?: number;
}

const PrevButton: React.FC<{ isActive: boolean }> = ({ isActive }) => (
    <div
        className={`${isActive ? 'border-opacity-40' : 'border-opacity-30 opacity-25'} cursor-pointer flex items-center justify-center w-[48px] h-[28px] rounded-[5px] border border-[#121212]`}
    >
        <span className="text-[20px]">{'<'}</span>
    </div>
);

const NextButton: React.FC<{ isActive: boolean }> = ({ isActive }) => (
    <div
        className={`${isActive ? 'border-opacity-40' : 'border-opacity-20 opacity-25'} cursor-pointer flex items-center justify-center w-[48px] h-[28px] rounded-[5px] border border-[#121212]`}
    >
        <span className="text-[20px]">{'>'}</span>
    </div>
);

interface FormData {
    pageNumber: number;
}

const CompetitorPagination: React.FC<PaginationProps> = ({
    totalPages,
    onPageChange,
    currentPage,
}) => {
    const { filter } = useCompetitorsStore();

    const { register, handleSubmit, setValue } = useForm<FormData>({
        defaultValues: {
            pageNumber: currentPage,
        },
    });

    const changePage = (newPage: number, isDecrease: boolean = false) => {
        queryClient.invalidateQueries({
            queryKey: ['fetchAllCompetitors', newPage, filter],
            exact: true,
        });
        onPageChange(isDecrease ? newPage - 1 : newPage);
        setValue('pageNumber', isDecrease ? newPage - 1 : newPage);
    };

    const increaseCurrentPage = () => {
        const newPage = Math.min(currentPage + 1, totalPages);
        if (newPage !== currentPage) {
            changePage(newPage);
        }
    };

    const decreaseCurrentPage = () => {
        const newPage = Math.max(currentPage - 1, 1);
        if (newPage !== currentPage) {
            changePage(newPage);
        }
    };

    const onSubmit: SubmitHandler<FormData> = ({ pageNumber }) => {
        const newPage = pageNumber;
        if (newPage >= 1 && newPage <= totalPages) {
            changePage(newPage + 1, true);
        }
    };

    if (totalPages < 2) return null;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex gap-[10px] items-center w-fit sm:text-[14px] text-[12px] font-medium"
        >
            <button
                type="button"
                onClick={decreaseCurrentPage}
                disabled={currentPage <= 1}
            >
                <PrevButton isActive={currentPage > 1} />
            </button>
            <div className="flex gap-[10px] opacity-80 items-center">
                <span>Page</span>
                <div className="flex items-center rounded-[5px] border border-[#121212] border-opacity-20 h-[28px] pl-[5px]">
                    <input
                        type="number"
                        className="w-16"
                        {...register('pageNumber', {
                            valueAsNumber: true,
                            onBlur: handleSubmit(onSubmit),
                        })}
                    />
                </div>
                <span>{`of ${totalPages}`}</span>
            </div>
            <button
                type="button"
                onClick={increaseCurrentPage}
                disabled={currentPage >= totalPages}
            >
                <NextButton isActive={currentPage < totalPages} />
            </button>
        </form>
    );
};

export default CompetitorPagination;
