import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useOtpStore from 'store/useOtpStore';

type Options = {
    successMessage?: string;
    onSuccess?: () => void;
};

const useCheckOtpMutation = ({ onSuccess, successMessage }: Options = {}) => {
    const { checkOtp } = useOtpStore();
    const { showMessage } = useToast();

    return useMutation({
        mutationFn: checkOtp,
        mutationKey: ['checkOtp'],
        onSuccess: () => {
            showMessage('Success', successMessage!);
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error Occurred', error.message);
        },
    });
};

export default useCheckOtpMutation;
