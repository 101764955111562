import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as LocationIcon } from 'assets/svg/LocationIcon.svg';
import { ReactComponent as AddressDotSvg } from 'assets/svg/address-dot.svg';

const OriginalLocationToolTip = ({
    originalLocation,
    onClose,
}: {
    originalLocation: any;
    onClose: () => void;
}) => {
    return (
        <div className="relative bg-black rounded-[8px] px-[12px] py-[38px] max-w-[288px]">
            <div
                onClick={onClose}
                className="absolute w-[28px] h-[28px] cursor-pointer right-[10px] top-[10px] rounded-full flex items-center justify-center"
            >
                <CloseOutlinedIcon className={`!text-[20px] text-white`} />
            </div>

            <div className="flex flex-col gap-[16px] text-white">
                <div className="text-[12px] font-semibold">
                    <LocationIcon />
                    <div className="flex items-center flex-wrap gap-[5px] pt-[10px]">
                        {'Original Location: '}
                        {originalLocation?.city && (
                            <>
                                <span>{originalLocation.city}</span>
                                <AddressDotSvg className="text-white" />
                            </>
                        )}
                        {originalLocation?.state && (
                            <>
                                <span>{originalLocation.state}</span>
                                <AddressDotSvg className="text-white" />
                            </>
                        )}
                        {originalLocation?.country && (
                            <>
                                <span>{originalLocation.country}</span>
                            </>
                        )}
                    </div>
                </div>

                <div className="text-opacity-90 text-[10px]">
                    {
                        'Our AI sometimes identifies a slightly different location based on the listing description.'
                    }
                </div>
            </div>
        </div>
    );
};

export default OriginalLocationToolTip;
