import { ReactNode } from 'react';
import styles from './GradientButton.module.css';

const GradientButton = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${styles['gradient-border']} ${className}`}>
      {children}
    </div>
  );
};

export default GradientButton;
