export interface PropertyStatistics {
    totalProperties: number;
    totalPrecentSinceYesterday: number;
    propertiesOn: number;
    propertiesAddedLast24Hours: number;
    propertiesOff: number;
}

export enum PropertyStatisticsActionEnum {
    VIEW = 'view',
    CLICK = 'click',
}
