const BackgroundLayer = () => {
  return (
    <div className="z-[-1]">
      <div className="z-[-2] fixed w-screen h-screen bg-white bg-opacity-60"></div>
      <div className="z-[-2] fixed w-screen h-screen bg-my-gray bg-opacity-20"></div>

      <div className="z-[-1] fixed rounded-full w-[800px] h-[800px] lg:bottom-[-500px] bottom-[-600px] lg:left-[-500px] left-[-600px] bg-my-blue bg-opacity-40 blur-[300px]"></div>

      <div className="z-[-1] fixed rounded-full w-[900px] h-[900px] lg:bottom-[-500px] bottom-[-800px] lg:right-[-600px] right-[-800px] bg-my-blue bg-opacity-40 blur-[400px]"></div>

      <div className="z-[-1] fixed rounded-full w-[1400px] h-[1600px] lg:top-[-1100px] top-[-1200px] lg:right-[-1100px] right-[-1200px] bg-my-green bg-opacity-40 blur-[400px]"></div>
    </div>
  );
};

export default BackgroundLayer;
