import { ReactComponent as GlobalSvg } from 'assets/svg/Global.svg';
import AppRoutes from 'routes';

const App = () => {
    return (
        <>
            <GlobalSvg />
            <AppRoutes />
        </>
    );
};
export default App;
