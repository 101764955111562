import { ReactComponent as SaveFilterPreferenceSvg } from 'assets/svg/SaveFilterPreference.svg';
import { FilterSetPane } from 'components/Filter';
import { SaveFilterModal } from 'components/Modals';
import { isEqual } from 'lodash';
import React, { useRef, useState } from 'react';
import { queryClient } from 'routes';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertyFilterType } from 'types/PropertyFilters';
import { DEFAULT_FILTER } from 'util/Constants';
import { getFilterCount, GetUniqueString } from 'util/Funcs';
import { LocalSavedFilter } from 'util/Types';
import MobileFilterSection from './MobileFilterSection';

interface FilterSetPaneHandles {
    getFilter: () => PropertyFilterType;
    clearFilter: () => void;
}
const FilterPanel: React.FC = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const filterRef = useRef<FilterSetPaneHandles>();
    const { currentFilter, addFilter, setCurrentFilter } = useFiltersStore();

    const onSaveClicked = (filter: LocalSavedFilter) => {
        addFilter(filter);
        setExpanded(false);
    };

    const onClearClicked = () => {
        setCurrentFilter(DEFAULT_FILTER);
        filterRef!.current!.clearFilter();
        if (!isEqual(DEFAULT_FILTER, currentFilter)) {
            resetQueries(DEFAULT_FILTER);
        }
    };

    const openSaveFilterModal = () => {
        setExpanded(true);
    };
    const { currentPage, fetchCompetitorAllPropertiesSort } =
        usePropertiesStore();

    const resetQueries = (filter: PropertyFilterType) => {
        queryClient.resetQueries({
            queryKey: [
                'fetchCompetitorAllProperties',
                currentPage,
                filter,
                fetchCompetitorAllPropertiesSort,
            ],
        });
    };

    const handleFiltersChange = (filter: PropertyFilterType) => {
        setCurrentFilter(filter);
        if (!isEqual(filter, currentFilter)) {
            handleFormResetAI();
            resetQueries(filter);
        }
    };

    const handleFormResetAI = () => {
        const event = new CustomEvent('resetAllForms');
        window.dispatchEvent(event);
    };

    return (
        <>
            <div className="md:grid hidden lg:grid-cols-6 md:grid-cols-3 gap-[10px]">
                <FilterSetPane
                    lock={true}
                    value={currentFilter}
                    handleChanged={handleFiltersChange}
                    ref={filterRef}
                />
                <div className="flex gap-[10px] w-max">
                    {getFilterCount(currentFilter) > 0 && (
                        <div
                            className="flex items-center gap-[5px] cursor-pointer text-my-red"
                            onClick={onClearClicked}
                        >
                            <div
                                className={`rounded-full w-[25px] h-[25px] flex justify-center items-center cursor-pointer border border-my-red`}
                            >
                                <div className="leading-[25px] text-[25px]">
                                    {'×'}
                                </div>
                            </div>
                            <div>Clear</div>
                        </div>
                    )}

                    <div
                        className="flex items-center gap-[5px] cursor-pointer text-my-brown"
                        onClick={openSaveFilterModal}
                    >
                        <SaveFilterPreferenceSvg />
                        <span> Save Filter Preferences </span>
                    </div>
                </div>
            </div>

            <div className="md:hidden">
                <MobileFilterSection onOpenSaveModal={openSaveFilterModal} />
            </div>

            <SaveFilterModal
                visible={expanded}
                onClose={() => setExpanded(false)}
                onSave={onSaveClicked}
                filter={{
                    id: GetUniqueString(),
                    title: '',
                    filter: currentFilter,
                }}
            />
        </>
    );
};

export default FilterPanel;
