import { getDomainFavLink } from 'util/Funcs';

interface FavAvatarProps {
    url: string;
    className?: string;
    imageSize: number;
    containerSize: number;
}

const FavAvatar = (props: FavAvatarProps) => {
    const { url, imageSize, containerSize, className } = props;

    return (
        <div
            className={`rounded-full border-opacity-80 flex justify-center items-center overflow-hidden bg-white ${className || 'border border-my-gray '}`}
            title={url}
            style={{
                width: `${containerSize}px`,
                height: `${containerSize}px`,
                minWidth: `${containerSize}px`,
            }}
        >
            <img
                alt={'FAV ICON'}
                src={getDomainFavLink(url)}
                style={{
                    width: `${imageSize}px`,
                    height: `${imageSize}px`,
                    minWidth: `${imageSize}px`,
                }}
            />
        </div>
    );
};

export default FavAvatar;
