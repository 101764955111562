import { BlackButton } from 'pages/auth';
import HelloDataMark from 'pages/HelloDataMark';
import { logoutUserAndClearLocalStorage } from 'util/localStorageHelper';
import { ReactComponent as NewEmailUpdatedSvg } from 'assets/svg/New-Email-Updated.svg';

const EmailUpdatedPage = () => {
    const handleLogin = () => {
        logoutUserAndClearLocalStorage();
    };
    return (
        <div className="h-screen w-screen md:flex lg:items-center md:justify-center md:bg-my-gray">
            <div
                className={`flex flex-col gap-[40px] md:w-[620px] pt-[100px] lg:pt-0`}
            >
                <div className="flex justify-center">
                    <HelloDataMark />
                </div>
                <div className="rounded-[20px] bg-white flex flex-col gap-[20px] px-[36px] py-[48px] text-center">
                    <div>
                        <NewEmailUpdatedSvg className="w-[110px] h-[110px] mx-auto" />
                    </div>

                    <div className="font-semibold text-[20px]">
                        New Email has been updated
                    </div>
                    <div className="leading-[24px]">
                        Please log in with the new email address that you have
                        updated.
                    </div>

                    <div className="pt-[5px]">
                        <BlackButton className="w-full" onClick={handleLogin}>
                            Login
                        </BlackButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EmailUpdatedPage;
