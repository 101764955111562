import { ReactComponent as CompetitorChartSvg } from 'assets/svg/Main/TopTab-Competitor-Chart.svg';
import { ReactComponent as OverviewSvg } from 'assets/svg/Main/TopTab-Overview.svg';
import { useNavigate } from 'react-router-dom';
import { useUtilsStore } from 'store/useUtilsStore';
import styled from 'styled-components';
import { ROUTES } from 'util/Constants';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';

const CustomTopTab = styled.div`
    display: flex;
    cursor: pointer;
    gap: 10px;
    height: 100%;
    align-items: center;
`;

const TopTabs = () => {
    const { topTabIndex, setTopTabIndex } = useUtilsStore();

    const navigate = useNavigate();

    const userInfo = getUserInfoFromLocalStorage();

    const onboardingDidNotFinish =
        !userInfo ||
        !userInfo.accountType ||
        !userInfo.subscriptionId ||
        !userInfo.savedCompetitors ||
        userInfo.savedCompetitors.length === 0;
    const browserPath = window.location.pathname;

    const onTopTabClicked = (idx: number) => {
        if (browserPath !== ROUTES.MAIN) navigate(ROUTES.MAIN);
        setTopTabIndex(idx);
    };

    if (onboardingDidNotFinish) return null;

    return (
        <div className="rounded-[8px] bg-my-gray bg-opacity-40 text-[12px] font-medium leading-[24px] p-[4px] h-[48px] flex gap-[10px] items-center">
            <CustomTopTab
                className={`md:px-[20px] px-[5px] ${
                    browserPath === ROUTES.MAIN && topTabIndex === 0
                        ? 'bg-white rounded-[6px]'
                        : ''
                }`}
                onClick={() => onTopTabClicked(0)}
            >
                <OverviewSvg />
                <span>{'Overview'}</span>
            </CustomTopTab>
            <CustomTopTab
                className={`md:px-[20px] px-[5px] ${
                    browserPath === ROUTES.MAIN && topTabIndex === 1
                        ? 'bg-white rounded-[6px]'
                        : ''
                }`}
                onClick={() => onTopTabClicked(1)}
            >
                <CompetitorChartSvg />
                <span>{'Competitors'}</span>
            </CustomTopTab>
        </div>
    );
};

export default TopTabs;
