import styles from './blurimage.module.css';
import { ReactComponent as ClickHereSvg } from 'assets/svg/ClickHere.svg';

const BlurImage = ({ imgurl }: { imgurl: string }) => {
    return (
        <a href={imgurl} target="_blank" rel="noopener noreferrer">
            <div
                className={`relative w-[100px] h-[60px] overflow-hidden flex items-center justify-center rounded-[5px] cursor-pointer ${styles['hover-container']}`}
            >
                <div className="w-full h-full absolute top-0 left-0 z-[2]"></div>

                <div
                    className={`${styles['text']} z-[3] flex gap-[5px] text-[10px] justify-center items-center`}
                >
                    <ClickHereSvg />
                    <span>Click here</span>
                </div>
                <img alt={'Blur'} src={imgurl} className=" w-full" />
            </div>
        </a>
    );
};

export default BlurImage;
