import { ReactNode, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { GradientButton } from 'components';
interface FAQItem {
    title: string;
    answer: string;
}

const Questions: FAQItem[] = [
    {
        title: 'What is Hello:data? ',
        answer: 'Hello:data is an all-encompassing real estate competitor analysis tool designed for real estate agents. It enables agents to track competitors’ activities, grasp current trends, discover new listings, connect with fellow agents, and forge partnerships. This powerful tool takes your business to new heights by overcoming stagnation and fostering growth through accurate, global competitor data.',
    },
    {
        title: 'How does Hello:Data differ from other real estate data analytics tools?',
        answer: 'Hello:data cuts through the noise in the real estate market by focusing on what you truly need. Unlike other tools that simply dump API data at you for analysis, Hello:data empowers you to take control.  Easily add your competitors with just basic information, giving you a massive advantage in tracking their activity. This is especially powerful for local agents who miss out on competitor details in other platforms.',
    },
    {
        title: 'What are the benefits of using Hello:Data?',
        answer: 'HelloData empowers you to track your competitors with unparalleled control.  Analyze their strategies and leverage them to inform your business decisions. Gain inspiration from industry leaders, or add top competitors to your database. Showcase their listings to your clients and network with their agents to broaden your horizons. HelloData unlocks a world of countless opportunities.',
    },
    {
        title: 'Is Hello:Data free?',
        answer: 'Absolutely! To get the most out of our tool, the free version allows you to track up to 2 competitors.',
    },
    {
        title: 'How much does Hello:Data cost?',
        answer: "Hello:data is incredibly affordable considering the wealth of benefits it offers. Whether you're running a small business seeking a tailored plan or an established company looking to track your competitors, we have standard and premium packages to meet your needs.",
    },
    {
        title: "Can I select competitors that I'm not familiar with?",
        answer: "The best feature of our tool is that you can track any competitor, even those you're not familiar with.",
    },
    {
        title: 'How can I add a competitor myself?',
        answer: 'Easily add competitors to your analysis. Simply provide their website address and some key details. Our AI-powered data extractor will then deliver all their listing data within minutes.',
    },
    {
        title: 'How do I change the selected competitor?',
        answer: 'Once you choose a competitor, the number of times you change will depend on the package you subscribed to',
    },
    {
        title: 'How can I save my competitor data?',
        answer: 'When you find a listing you like, you can save it directly within the app. Later, you can download this saved data as a CSV file for deeper competitor analysis.',
    },
];

const FAQAccordion = ({ faq }: { faq: FAQItem }) => {
    return (
        <Accordion disableGutters sx={{ boxShadow: 'none' }}>
            <AccordionSummary
                expandIcon={
                    <div className="rounded-full w-[32px] h-[32px] border border-my-gray border-opacity-40 flex items-center justify-center">
                        <ExpandMoreIcon />
                    </div>
                }
                sx={{
                    height: '90px',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded div':
                        {
                            backgroundColor: 'rgba(49, 118, 188, 0.1)',
                        },
                }}
            >
                <div className="font-medium text-[18px] leading-[28px]">
                    {faq.title}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="text-[16px] leading-[28px]">{faq.answer}</div>
            </AccordionDetails>
        </Accordion>
    );
};

const FAQSection = () => {
    return (
        <div className="lg:px-[90px] md:px-[32px] px-[12px]" id="faq">
            <div className="max-w-[956px] mx-auto">
                <div className="flex justify-center pb-[30px]">
                    <GradientButton>
                        <div className="flex items-center justify-between rounded-[5px] h-[30px] px-[15px] gap-[10px]">
                            <img
                                alt={'FAQ '}
                                src="/image/Landing/FAQ-Icon.png"
                                className="w-[14px] h-[14px]"
                            />
                            <span>{`Frequently Asked Qusetions`}</span>
                        </div>
                    </GradientButton>
                </div>

                <div className="text-center max-w-[816px] mx-auto">
                    <div className="font-semibold text-[28px] pb-[12px]">
                        {'Everything you need to know'}
                    </div>
                    <div className="text-[14px] leading-[28px] pb-[28px]">
                        {
                            "New to HelloData? Our comprehensive FAQ section covers a wide range of topics to get you started. If you can't find the answer you're looking for, don't hesitate to contact us directly."
                        }
                    </div>
                </div>

                <div>
                    {Questions.map((faq, index) => (
                        <FAQAccordion faq={faq} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQSection;
