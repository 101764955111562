import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import {
    DEFAULT_DURATION,
    DEFAULT_PLAN,
    SubscriptionPlans,
} from 'util/Constants';
import {
    getUserInfoFromLocalStorage,
    saveUserInfoToLocalStorage,
} from 'util/localStorageHelper';
import { Plans, RegisterAccount } from 'util/Types';
import { create } from 'zustand';

interface OnboardingState {
    account: RegisterAccount;
    setAccount: (setAccount: RegisterAccount) => void;
    duration: number;
    setDuration: (duration: number) => void;
    plan: Plans;
    setPlan: (plan: Plans) => void;
    payPricing: () => Promise<void>;
    saveOnboardingInfo: () => Promise<void>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
}

export const useOnboardingStore = create<OnboardingState>()((set, get) => {
    return {
        account: { type: '', websiteUrl: '', referralSource: '' },
        setAccount: (account) => set({ account }),

        duration: DEFAULT_DURATION,
        setDuration: (duration) => set({ duration }),

        plan: DEFAULT_PLAN,
        setPlan: (plan) => set({ plan }),

        get currentStep() {
            const user = getUserInfoFromLocalStorage()!;
            if (
                user?.savedCompetitors &&
                user?.savedCompetitors?.length === 0
            ) {
                return 0;
            }
            if (!user?.subscriptionId) {
                return 1;
            }
            if (!user?.accountType) {
                return 2;
            }
            return 3;
        },
        setCurrentStep: (step) => set({ currentStep: step }),

        payPricing: async () => {
            try {
                const { plan, duration, setCurrentStep, currentStep } = get();

                const selectedPlan = SubscriptionPlans.find(
                    (p) => p.title === plan,
                )!;
                const planId =
                    duration === 1
                        ? selectedPlan.priceId['month']
                        : selectedPlan.priceId['year'];

                const { data, status } = await axiosInstance.post(
                    '/subscription/create-session',
                    {
                        plan: planId,
                    },
                );
                const { sessionId } = data;

                if (plan === 'Free') {
                    setCurrentStep(currentStep + 1);
                    return;
                }

                if (status === 201 || status === 200) {
                    const stripe = await loadStripe(
                        process.env.REACT_APP_STRIPE_KEY!,
                    );
                    if (stripe) {
                        stripe.redirectToCheckout({ sessionId });
                    }
                }
            } catch (error) {
                if (axios.isAxiosError(error))
                    throw new Error(error.response?.data.message);
                throw new Error(error as string);
            }
        },

        async saveOnboardingInfo() {
            const user = getUserInfoFromLocalStorage()!;
            const { account } = get();
            try {
                const { data } = await axiosInstance.post(
                    `/user/on-boarding/${
                        account.type === 'Personal' ? 'personal' : 'company'
                    }`,
                    { ...account, userId: user?.userId },
                );
                saveUserInfoToLocalStorage(data.data?.user);
            } catch (error) {
                if (axios.isAxiosError(error))
                    throw new Error(error.response?.data.message);
                throw new Error(error as string);
            }
        },
    };
});
