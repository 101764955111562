import { Button } from '@mui/material';
import ModalLayout from './ModalLayout';
import { getUserPlan } from 'util/getUserPlan';
import { useModals } from 'pages/MainProvider';

const LimitedModal = ({
    visible,
    onClose,
}: {
    visible: boolean;
    onClose: () => void;
}) => {
    const { showSelectCompetitorModal } = useModals();
    const currentPlan = getUserPlan();

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false}>
            <div className="w-auto max-w-[550px] bg-white px-[44px] pt-[60px] pb-[40px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[10px]">
                <div>
                    <img
                        alt={'Plan Limited'}
                        src="/image/Plan-Limited.png"
                        className="mx-auto"
                    />
                    <div className="font-semibold text-[16px] pt-[20px]">{`Competitor Limit Reached`}</div>
                    <div className="pt-[10px]">
                        {`Your current plan allows you to track up to `}
                        <span className="font-bold">
                            {currentPlan.maxTrackUpCount}
                        </span>
                        {` competitors. Please adjust your selection to continue accessing your competitor data.`}
                    </div>
                </div>

                <Button
                    variant="outlined"
                    className="!h-[42px]"
                    sx={{ textTransform: 'none' }}
                    onClick={showSelectCompetitorModal}
                >
                    {`Manage Competitors`}
                </Button>
            </div>
        </ModalLayout>
    );
};

export default LimitedModal;
