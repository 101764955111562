import MainLayout from '../MainLayout';
import OnboardingContent from './Content';

const OnboardingPage = () => {
    return (
        <MainLayout>
            <OnboardingContent />
        </MainLayout>
    );
};

export default OnboardingPage;
