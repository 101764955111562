import useCompetitorsStore from 'store/useCompetitorsStore';
import StatCard from './StatCard';

const CompetitorOverviewGrid = () => {
    const { selectedSavedCompetitor } = useCompetitorsStore();
    return (
        <div className="relative">
            <div className="font-medium leading-[24px] pb-[10px]">
                Competitor overview
            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 gap-[10px] p-[5px] rounded-[10px] bg-white">
                <StatCard
                    label="Total Listings"
                    value={selectedSavedCompetitor?.propertyCount || 0}
                />
                <StatCard
                    label="New Listings"
                    value={
                        selectedSavedCompetitor?.propertiesAddedLast24Hours || 0
                    }
                />
                <StatCard
                    label="Property no longer listed"
                    value={selectedSavedCompetitor?.propertiesOff || 0}
                />
            </div>
        </div>
    );
};

export default CompetitorOverviewGrid;
