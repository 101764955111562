import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { create } from 'zustand';

interface StaisticsProps {
    totalAgents: number;
    agentsAddedLast24Hours: number;
    totalProperties: number;
    totalPrecentSinceYesterday: number;
    propertiesAddedLast24Hours: number;
    propertiesOff: number;
}

interface LandingState {
    statistics: StaisticsProps | null;
    getStatistics: () => Promise<StaisticsProps>;
}

const useLandingStore = create<LandingState>((set, get) => ({
    statistics: null,
    getStatistics: async () => {
        try {
            const { data } = await axiosInstance.get(
                `/properties/total-statistics`,
            );
            set(data.data);
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useLandingStore;
