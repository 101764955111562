import { Button } from '@mui/material';
import ModalLayout from './ModalLayout';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'util/Constants';

interface UpgradePlanProps {
    visible: boolean;
    onClose: () => void;
}

const UpgradePlanModal = (props: UpgradePlanProps) => {
    const { visible, onClose } = props;
    const navigate = useNavigate();

    const onUpgradeClick = () => {
        navigate(`${ROUTES.SETTING}?tab=2`);
    };

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="md:w-[550px] rounded-[10px] px-[22px] pt-[60px] pb-[10px] bg-white">
                <div className="flex flex-col gap-[5px]">
                    <div className="px-[38px] text-center pb-[15px]">
                        <img
                            src="/image/upgrade-plan.png"
                            className="mx-auto pb-[35px]"
                        />
                        <div className="text-[18px] font-medium leading-[24px] pb-[10px]">
                            {'Fetch all listing details from the database'}
                        </div>
                        <div className="text-[14px] text-opacity-80 leading-[24px]">
                            {
                                'Unlock all countries and cities for searching all listings within the database, without adding agent competitors to your profile'
                            }
                        </div>
                    </div>

                    <Button
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '42px',
                            width: '100%',
                        }}
                        variant="contained"
                        className="!bg-gradient-green !font-medium"
                        onClick={onUpgradeClick}
                    >
                        Upgrade
                    </Button>

                    <Button
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '42px',
                            width: '100%',
                        }}
                        className="!text-my-black !font-medium"
                        onClick={onClose}
                    >
                        No Thanks
                    </Button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default UpgradePlanModal;
