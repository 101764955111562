import { Divider } from '@mui/material';
import BottomCard from './BottomCard';
import Layout from './Layout';

const AccurateData = () => {
    return (
        <Layout
            title={'Accurate data'}
            Body={
                <>
                    <div className="flex items-center lg:pl-[90px] mr-[-24px]">
                        <img
                            alt={'Accurate-Top '}
                            src="/image/Landing/Accurate-Top.png"
                        />
                        <Divider className="flex-grow" />
                    </div>

                    <div className="flex items-center lg:pr-[90px] ml-[-24px]">
                        <Divider className="flex-grow" />
                        <img
                            alt={'Accurate-Bottom '}
                            src="/image/Landing/Accurate-Bottom.png"
                        />
                    </div>
                </>
            }
            Bottom={
                <BottomCard
                    title={'Speed up yoiur analysis'}
                    content={
                        'Transform real estate data into actionable insights that reveal needs and opportunities. Scan, analyze, and uncover advantages quickly.'
                    }
                />
            }
        />
    );
};

export default AccurateData;
