import { SubscriptionPlans } from './Constants';
import { getUserInfoFromLocalStorage } from './localStorageHelper';
import { PlanTypes } from './Types';

export const getUserPlan = (): PlanTypes => {
    const user = getUserInfoFromLocalStorage();

    const { priceId = '', interval = 'month' } =
        user!.subscriptionDetails || {};
    const plan =
        SubscriptionPlans.find((p) => p.priceId[interval] === priceId) ||
        SubscriptionPlans[0];

    return plan;
};

export const isPremiumUser = (): boolean => getUserPlan().title === 'Premium';

export const isExceed = (): boolean => {
    const user = getUserInfoFromLocalStorage();
    const plan = getUserPlan();

    return (user?.savedCompetitors.length || 0) > plan.maxTrackUpCount;
};
