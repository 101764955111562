import { ReactComponent as DefaultPropertyImageSvg } from 'assets/svg/Default-Property-Image.svg';
import { ReactComponent as SeeDetailsMobileSvg } from 'assets/svg/Property-See-Details-Mobile.svg';

import NewLabel from 'components/Labels/NewCompetitor';
import {
    GetCountryCode,
    convertDateIntoLocaleString,
    formatNumber,
    isNew,
} from 'util/Funcs';
import { ORDER_TYPE, PropertyDTO, SortDTO } from 'util/Types';
import styles from './propertytable.module.css';
import { Button, Menu } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import PropertyDetailModal from 'components/Modals/PropertyDetail';
import { useEffect, useRef, useState } from 'react';
import { OneLetterAvatar } from 'components/LetterAvatar';
import { AiPercentage } from 'components/Labels';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DEFAULT_SORT } from 'util/Constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getUserPlan, isExceed } from 'util/getUserPlan';
import { useModals } from 'pages/MainProvider';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onSort: (_s: SortDTO) => void;
}

interface SortHeaderItemProps {
    title: string;
    currentSort: SortDTO;
    className?: string;
    sortField?: string;
    onSort?: (s: SortDTO) => void;
}

const SORT_TYPES: ORDER_TYPE[] = ['default', 'ASC', 'DESC'];
const SORT_ICONS = [
    <ArrowRightIcon />,
    <ArrowDropDownIcon />,
    <ArrowDropUpIcon />,
];

const HeaderItem = ({
    className = '',
    title,
    currentSort,
    sortField = '',
    onSort = () => {},
}: SortHeaderItemProps) => {
    const [sortStatus, setSortStatus] = useState<number>(0);

    useEffect(() => {
        if (currentSort.field !== sortField) setSortStatus(0);
        else {
            setSortStatus(SORT_TYPES.indexOf(currentSort.order));
        }
    }, [currentSort]);

    const onSortClicked = () => {
        const newSortIndex = (sortStatus + 1) % 3;
        setSortStatus(newSortIndex);
        if (sortField) {
            onSort({
                field: sortField,
                order: SORT_TYPES[newSortIndex],
            });
        }
    };

    return (
        <div
            className={`flex items-center gap-[2px] cursor-pointer ${className}`}
            onClick={onSortClicked}
        >
            <span className={styles['headertitle']}>{title}</span>
            {sortField && <div>{SORT_ICONS[sortStatus]}</div>}
        </div>
    );
};

const MIN_W = 400;
const MIN_H = 200;

const DateItem = ({ property }: { property: PropertyDTO }) => {
    const isNewProperty: boolean = isNew(property.createdAt);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (!property.imageUrls) {
            setImage('');
            return;
        }

        const filterImages = async () => {
            const validImages = [];

            for (const imgurl of property.imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    validImages.push(imgurl);
                }

                if (validImages.length > 0) break;
            }

            setImage(validImages.length > 0 ? validImages[0] : '');
        };

        filterImages();
    }, [property.imageUrls]);

    return (
        <div className="relative flex gap-[10px] overflow-hidden">
            {isNewProperty ? (
                <NewLabel />
            ) : (
                <div className="min-w-[42px] w-[42px] h-[42px] border border-my-gray border-opacity-60 rounded-[5px] overflow-hidden flex items-center justify-center">
                    {image ? (
                        <img src={image} width={42} height={42} />
                    ) : (
                        <DefaultPropertyImageSvg />
                    )}
                </div>
            )}

            <div className="flex flex-col justify-between">
                <div className="font-medium truncate">
                    <span>{property.title}</span>
                    <div className="z-[2] absolute right-0 top-[50%] h-[300%] w-[80%] bg-white blur-[40px] translate-x-[50%] translate-y-[-50%]"></div>
                </div>
                <div className="text-[12px]">
                    {convertDateIntoLocaleString(property.createdAt)}
                </div>
            </div>
        </div>
    );
};

const AdditionalDetailForMobile = ({
    property,
    onClickShowDetail,
}: {
    property: PropertyDTO | null;
    onClickShowDetail: (p: PropertyDTO) => void;
}) => {
    return (
        <div className="px-[12px] min-w-[330px] flex flex-col gap-[12px]">
            <div className="flex flex-col">
                <div className="flex justify-between items-center">
                    <span>{`Area`}</span>
                    <span className="font-bold">
                        {property?.address.City || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`Class`}</span>
                    <span className="font-bold">
                        {property?.details.propertyType || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`User principal`}</span>
                    <span className="font-bold">
                        {property?.details.listingType || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`Consruction Area`}</span>
                    <span className="font-bold">{`${property?.details.sizeLand || 0} sqm`}</span>
                </div>
            </div>
            <Button
                variant="outlined"
                onClick={() => {
                    if (property) onClickShowDetail(property);
                }}
            >
                {'Details'}
            </Button>
        </div>
    );
};

const PropertyTable = ({ propertyList, onSort }: PropertyTableProps) => {
    const { showSelectCompetitorModal } = useModals();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expandAdditionalDetail: boolean = Boolean(anchorEl);

    const [currentSort, setCurrentSort] = useState<SortDTO>(DEFAULT_SORT);
    const [selectedProperty, setSelectedProperty] =
        useState<PropertyDTO | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const onClickShowDetail = (_p: PropertyDTO) => {
        setAnchorEl(null);
        setSelectedProperty(_p);
        setExpanded(true);
    };

    const onSortClicked = (_s: SortDTO) => {
        setCurrentSort(_s);
        onSort(_s);
    };

    const openAdditionalPanelClicked = (
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
        p: PropertyDTO,
    ) => {
        setAnchorEl(evt.currentTarget);
        setSelectedProperty(p);
    };

    const isExceedFlag = isExceed();
    const currentPlan = getUserPlan();

    return (
        <>
            <div className={`w-full font-medium ${styles['propertytable']}`}>
                {/* header */}
                <div
                    className={`${styles['tr']} ${styles['thead']} grid grid-cols-9 items-center text-my-black text-opacity-60 border-y border-my-gray border-opacity-40 py-[15px] px-[10px] mb-[12px] bg-my-gray bg-opacity-20`}
                >
                    <HeaderItem
                        title="Listing Date"
                        sortField="propertyCreatedAt"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        className="col-span-2"
                    />
                    <HeaderItem
                        title="Competitor"
                        sortField="companyName"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="Country"
                        sortField="addressCountry"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="City"
                        sortField="addressCity"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="Property Type"
                        sortField="propertyType"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="Price"
                        sortField="price"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="Constructed area"
                        sortField="sizeLand"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                    />
                    <HeaderItem
                        title="Plot area"
                        sortField="sizePlot"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        className="md:block hidden"
                    />
                </div>

                {isExceedFlag ? (
                    <div className="bg-white py-[30px]">
                        <div className="mx-auto w-fit max-w-[550px] bg-white px-[44px] pt-[60px] pb-[40px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[10px]">
                            <div>
                                <img
                                    alt={'Plan Limited'}
                                    src="/image/Plan-Limited.png"
                                    className="mx-auto"
                                />
                                <div className="font-semibold text-[16px] pt-[20px]">{`Competitor need to be adjusted`}</div>
                                <div>
                                    {`Your current plan allows you to track up to `}
                                    <span className="font-bold">
                                        {currentPlan.maxTrackUpCount}
                                    </span>
                                    {` competitors. Please adjust your selection to continue accessing your competitor data.`}
                                </div>
                            </div>

                            <Button
                                variant="outlined"
                                className="!h-[42px]"
                                sx={{ textTransform: 'none' }}
                                onClick={showSelectCompetitorModal}
                            >
                                {`Manage Competitors`}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`flex flex-col gap-[4px] text-opacity-80 ${styles['body']}`}
                    >
                        {propertyList.map(
                            (property: PropertyDTO, index: number) => {
                                const aiScore = Math.floor(
                                    (property?.aiScore || -1) * 100,
                                );

                                return (
                                    <div
                                        key={index}
                                        className={`${styles['tr']} grid grid-cols-9 items-center border-opacity-40 py-[8px] px-[10px]`}
                                    >
                                        <div className="col-span-2 pr-[12x]">
                                            <DateItem property={property} />
                                        </div>
                                        <div className="">
                                            <div className="flex items-center gap-[5px]">
                                                <OneLetterAvatar
                                                    title={
                                                        property.details
                                                            .listingType ||
                                                        'Sales'
                                                    }
                                                    colorClass={
                                                        property.details
                                                            .listingType ===
                                                        'Rental'
                                                            ? 'text-my-green'
                                                            : 'text-my-blue'
                                                    }
                                                />
                                                <span>
                                                    {property.companyName || ''}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex items-center gap-[5px]">
                                                {property.address.Country && (
                                                    <ReactCountryFlag
                                                        svg
                                                        countryCode={
                                                            GetCountryCode(
                                                                property.address
                                                                    .Country ||
                                                                    '',
                                                            ) || ''
                                                        }
                                                        style={{
                                                            width: '1.5em',
                                                            height: '1.5em',
                                                        }}
                                                    />
                                                )}
                                                <span>
                                                    {property.address.Country}
                                                </span>
                                            </div>
                                        </div>
                                        <div>{`${property.address.City || ''}`}</div>
                                        <div>{`${property.details.propertyType || ''}`}</div>
                                        <div>
                                            {property.details.price &&
                                            property.details.price > 0
                                                ? `${formatNumber(property.details.price)} ${getSymbolFromCurrency(property.details.currency) || ''}`
                                                : 'On Request'}
                                        </div>
                                        <div>{`${formatNumber(property.details.sizeLand || 0)} sqm`}</div>
                                        <div className="flex justify-between items-center">
                                            <span className="md:inline-block hidden">
                                                {`${formatNumber(property.details.sizePlot || 0)} sqm`}
                                            </span>
                                            <div></div>
                                            <div className="flex items-center text-my-blue gap-[10px] justify-end">
                                                {aiScore >= 0 && (
                                                    <AiPercentage
                                                        percentage={aiScore}
                                                    />
                                                )}
                                                {/* <span className="cursor-pointer">
                                                <FavoriteIcon />
                                            </span> */}
                                                <div className="lg:block hidden">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() =>
                                                            onClickShowDetail(
                                                                property,
                                                            )
                                                        }
                                                    >
                                                        {'Details'}
                                                    </Button>
                                                </div>

                                                <div
                                                    className={`lg:hidden w-[50px] h-[48px] flex items-center justify-center border ${expandAdditionalDetail && property.webPageId === selectedProperty?.webPageId ? 'bg-my-black text-white' : 'border-my-blue text-my-blue'} cursor-pointer`}
                                                    onClick={(
                                                        ev: React.MouseEvent<
                                                            HTMLDivElement,
                                                            MouseEvent
                                                        >,
                                                    ) =>
                                                        openAdditionalPanelClicked(
                                                            ev,
                                                            property,
                                                        )
                                                    }
                                                >
                                                    <SeeDetailsMobileSvg
                                                        className={
                                                            expandAdditionalDetail &&
                                                            property.webPageId ===
                                                                selectedProperty?.webPageId
                                                                ? 'rotate-180'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                )}
            </div>

            <PropertyDetailModal
                property={selectedProperty}
                visible={expanded}
                onClose={() => setExpanded(false)}
            />

            <Menu
                anchorEl={anchorEl}
                open={expandAdditionalDetail}
                onClose={() => setAnchorEl(null)}
            >
                <AdditionalDetailForMobile
                    property={selectedProperty}
                    onClickShowDetail={onClickShowDetail}
                />
            </Menu>
        </>
    );
};

export default PropertyTable;
