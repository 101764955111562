// src/CustomSnackbar.tsx
import { ReactNode, forwardRef, Ref } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SnackbarContent, SnackbarContentProps } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface CustomSnackbarProps {
    title?: string;
    message?: string;
    onClose?: () => void;
    icon?: ReactNode;
}

const CustomSnackbar = forwardRef((props: CustomSnackbarProps, ref: Ref<HTMLDivElement>) => {
    const { title, message, onClose, icon, ...other } = props;

    return (
        <SnackbarContent
            className='bg-[#121212] text-white'
            ref={ref}
            message={
                <div className='relative flex items-center gap-[5px]'>
                    <div className='w-[45px] h-[45px] rounded-[7px] relative flex items-center justify-center min-w-[45px]'>
                        <div className='bg-white opacity-10 absolute w-full h-full top-0 left-0 rounded-[7px]'></div>
                        {icon || <ErrorOutlineOutlinedIcon />}
                    </div>

                    <div className='flex flex-col gap-[5px]'>
                        {title && <div className='text-[14px] font-semibold'>{title}</div>}
                        {message && <div className='text-[12px]'>{message}</div>}
                    </div>
                </div>
            }
            action={
                <IconButton size="medium" aria-label="close" color="inherit" onClick={onClose} sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Change this to your desired color
                    },
                }}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            {...other}
        />
    );
});

export default CustomSnackbar;
