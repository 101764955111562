import { useTooltip } from 'components/Providers/ToolTipProvider';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { GetFlag, SetFlag } from 'util/AppApi';

const SelectCompetitorTooltip = () => {
    const { hideTooltip } = useTooltip();

    const onCloseClick = () => {
        SetFlag('selectCompetitorTooltip', true);
        hideTooltip();
    };

    return (
        <div className="absolute lg:right-[43px] lg:top-[150px] md:right-[31px] bottom-[60px] px-[20px] md:px-0">
            <div className="relative bg-black rounded-[10px] px-[12px] py-[18px] md:w-[336px]">
                <div
                    onClick={onCloseClick}
                    className="absolute w-[28px] h-[28px] cursor-pointer right-[10px] top-[10px] rounded-full flex items-center justify-center bg-my-gray bg-opacity-30"
                >
                    <CloseOutlinedIcon className={`!text-[14px] text-white`} />
                </div>
                <div className="text-[12px] flex flex-col gap-[16px] pt-[18px] text-center text-white">
                    <img
                        alt={'ToolTip '}
                        src="/image/Tooltip/lamp.png"
                        className="mx-auto"
                    />
                    <div className="text-opacity-90">
                        {
                            'Choose a competitor based on your strongest capabilities and the most effective combinations. Our tool offers a comprehensive comparison feature'
                        }
                    </div>

                    <div className="font-semibold">
                        {
                            'You can Swap competitors within your monthly quota before billing cycle ends'
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectCompetitorTooltip;
