import CompetitorTable from 'components/CompetitorTable';
import FilterSection from './FilterSection';
import HeaderSection from './HeaderSection';

const SelectCompetitorPanel = () => {
    return (
        <div className="rounded-[8px] bg-white lg:min-w-[900px] border border-my-gray">
            <HeaderSection />
            <FilterSection />
            <div className="p-[20px] min-h-[250px]">
                <CompetitorTable />
            </div>
        </div>
    );
};

export default SelectCompetitorPanel;
