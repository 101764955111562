import { GetRandomColorClassName, GetTwoLetterName } from 'util/Funcs';
import { Button, Avatar } from '@mui/material';
import { memo } from 'react';

const DEF_COLORS = [
    { bg: 'bg-my-yellow', color: '#C88A02' },
    { bg: 'bg-my-pink', color: '#AD1619' },
    { bg: 'bg-my-purple', color: '#26ADE3' },
];

const areEqual = (
    prevProps: { title: string },
    nextProps: { title: string },
) => {
    return prevProps.title === nextProps.title;
};

interface OneLetterAvatarProps {
    title: string;
    colorClass?: string;
}
export const OneLetterAvatar = memo((props: OneLetterAvatarProps) => {
    const { title, colorClass = '' } = props;
    // const randIndex = Math.ceil(Math.random() * 255) % 3;
    // const palette = DEF_COLORS[randIndex];
    const oneLetterName = !title ? '?' : title[0];

    return (
        <div
            className={`rounded-full min-w-[28px] w-[28px] h-[28px] border border-my-gray border-opacity-60 p-[3px] flex items-center justify-center bg-opacity-20 ${colorClass}`}
            // style={{ color: palette.color }}
        >
            {oneLetterName}
        </div>
    );
}, areEqual);

export const TwoLetterAvatar = memo(
    ({ title, profileUrl }: { title: string; profileUrl: string }) => {
        // const randomColor = GetRandomColorClassName();
        const twoLetterName = GetTwoLetterName(title);
        return (
            <>
                <div className="rounded-full w-[48px] h-[48px] border border-my-gray border-opacity-60 p-[3px] flex items-center justify-center">
                    <Avatar
                        alt="Avatar"
                        src={profileUrl}
                        sx={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#FFC034',
                            '&.muiavatar-root': {
                                width: '40px',
                                height: '40px',
                            },
                        }}
                    >
                        <span className="font-poppins font-medium text-[16px] -tracking-[1px] uppercase text-black">
                            {twoLetterName}
                        </span>
                    </Avatar>
                </div>
            </>
        );
    },
    areEqual,
);
