import { Button, Divider, Menu } from '@mui/material';
import DetailPane from 'components/Modals/PropertyDetail/DetailPane';
import { PropertyDTO } from 'util/Types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { DEFAULT_MODAL_Z_INDEX } from 'components/Modals/ModalLayout';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertyStatisticsActionEnum } from 'types/PropertyStatistics';

interface PropertyDetailsProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    property: PropertyDTO | null;
    onDetailClicked: () => void;
}
const PropertyDetails = (props: PropertyDetailsProps) => {
    const { savePropertyStatistics } = usePropertiesStore();
    const { anchorEl, onClose, property, onDetailClicked } = props;

    if (!property) return null;

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            sx={{
                padding: '0px',
                '& .MuiPaper-root': {
                    borderRadius: '10px',
                },
                '& .MuiList-root': {
                    padding: '0px',
                },
                zIndex: DEFAULT_MODAL_Z_INDEX + 1,
            }}
        >
            <div className="bg-my-gray bg-opacity-10 max-w-[450px]">
                <div className="px-[8px] py-[14px] bg-white flex justify-between">
                    <span className="text-[12px] text-black font-medium">
                        {property.title}
                    </span>

                    <div onClick={onClose} className="cursor-pointer">
                        <CloseOutlinedIcon />
                    </div>
                </div>

                <Divider className="w-full" />

                <div className="px-[8px] text-[10px] font-medium pb-[10px]">
                    <DetailPane
                        property={property}
                        additionalDetails={null}
                        onExplorerListingClicked={() => {
                            savePropertyStatistics({
                                webPageId: property?.webPageId,
                                action: PropertyStatisticsActionEnum.CLICK,
                            });
                            window.open(property?.webPageUrl);
                        }}
                    />
                </div>

                <Divider className="w-full" />

                <div className="px-[8px] py-[10px] flex justify-end items-center">
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', height: '24px' }}
                        onClick={onDetailClicked}
                    >
                        <span className="text-my-blue text-[12px]">
                            {'Details'}
                        </span>
                    </Button>
                </div>
            </div>
        </Menu>
    );
};

export default PropertyDetails;
