// DoughnutChart.js
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const colors = ['#C51918', '#FFB400', '#039487'];
const limits = [40, 70, 100];
const defColor = 'rgba(48, 148, 135, 0.1)';

const getColor = (percentage: number) => {
    if (percentage < 0)
        return '#000';
    if (percentage > 100)
        return colors[limits.length - 1];

    for (var i = 0; i < limits.length; i++) {
        if (percentage > limits[i] && percentage <= limits[i + 1])
            return colors[i + 1];
    }
    return colors[0];
}
const AiPercentage = ({ percentage, size = 36 }: { percentage: number, size?: number }) => {
    const curcolor = getColor(percentage);
    const data = {
        datasets: [{
            backgroundColor: [curcolor, defColor],
            data: [percentage, 100 - percentage],
            borderWidth: 0
        }]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
        cutout: '70%',
        // borderWidth: 0
    };

    let percentagestr = `${percentage}`;
    if (percentage < 0)
        percentagestr = '-';
    else if (percentage > 100)
        percentagestr = `${percentage}+`

    return (
        <div className={`w-[${size}px] h-[${size}px] relative`}>
            <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center'>
                <div className={`rounded-full w-[60%] h-[60%] flex items-center justify-center font-medium text-[12px] text-[${curcolor}] leading-[18px]`} style={{ backgroundColor: defColor }}>
                    {percentagestr}
                </div>
            </div>
            <Doughnut data={data} options={options} />
        </div>
    )
}

export default AiPercentage;