import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { GradientButton } from 'components';
import { CustomOutlinedInput } from '../auth';

const ContactSection = () => {
    const [email, setEmail] = useState<string>('');
    const onGetInTouchClicked = () => {};

    const onGetStartedFreeClicked = () => {};

    return (
        <div>
            <div
                className="py-[40px] border-y border-y-my-gray border-opacity-60"
                id="contact"
            >
                <div className="text-center md:block hidden">
                    <div className="font-nunito text-[28px] font-extrabold">
                        {'Contact us'}
                    </div>
                    <div className="pt-[12px] leading-[24px]">
                        {'Got any question? Don’t hesitate to reach'}
                    </div>
                    <div className="pt-[20px]">
                        <LoadingButton
                            onClick={onGetInTouchClicked}
                            variant="outlined"
                            className={`!rounded-[5px] h-[42px] w-fit`}
                            // loading={loading}
                            loadingIndicator={
                                <CircularProgress color="primary" size={24} />
                            }
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                backgroundColor: '#121212',
                                '&:hover': {
                                    color: 'black',
                                },
                            }}
                        >
                            {'Get in touch'}
                        </LoadingButton>
                    </div>
                </div>

                <div className="md:hidden px-[12px]">
                    <div className="font-nunito text-[18px] font-extrabold">
                        {'Never miss an update'}
                    </div>
                    <div className="pt-[12px] leading-[24px]">
                        {
                            "Stay ahead of the data innovation curve! Sign up for hello:data's monthly newsletter and get the latest news, blog posts, and product updates delivered straight to your inbox."
                        }
                    </div>
                    <div className="pt-[20px] flex gap-[20px] justify-between">
                        <CustomOutlinedInput
                            name={'email'}
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder="Email Address"
                            startIcon={<></>}
                        />

                        <LoadingButton
                            onClick={onGetInTouchClicked}
                            variant="outlined"
                            className={`!rounded-[5px] h-[50px] !w-fit !min-w-[100px]`}
                            // loading={loading}
                            loadingIndicator={
                                <CircularProgress color="primary" size={24} />
                            }
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                backgroundColor: '#121212',
                                '&:hover': {
                                    color: 'black',
                                },
                            }}
                        >
                            {'Sign Up'}
                        </LoadingButton>
                    </div>
                </div>
            </div>

            <div
                className="lg:px-[100px] md:px-[32px] px-[12px] border-b border-my-gray border-opacity-60"
                id="requestdemo"
            >
                <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:gap-[190px] md:gap-[60px] py-[32px] lg:max-w-hello mx-auto">
                    <div className="mx-auto flex flex-col justify-center">
                        <div className="flex">
                            <GradientButton>
                                <div className="flex items-center justify-between rounded-[5px] h-[30px] px-[15px] gap-[10px]">
                                    <img
                                        alt={'See Competitor '}
                                        src="/image/Landing/Landing-See-Competitors.png"
                                        className="w-[14px] h-[14px]"
                                    />
                                    <span>{`See your competitors differently`}</span>
                                </div>
                            </GradientButton>
                        </div>

                        <div className="pt-[20px] font-nunito text-[24px] font-extrabold">
                            {'Track.Analyze.Compare'}
                        </div>

                        <div className="pt-[12px] pb-[28px] text-[16px]">
                            {
                                'The newest competitor analysis powerhouse for real estate industry'
                            }
                        </div>

                        <LoadingButton
                            onClick={onGetStartedFreeClicked}
                            variant="outlined"
                            className={`!rounded-[5px] h-[42px] w-fit`}
                            // loading={loading}
                            loadingIndicator={
                                <CircularProgress color="primary" size={24} />
                            }
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                backgroundColor: '#121212',
                                '&:hover': {
                                    color: 'black',
                                },
                            }}
                        >
                            {"Get started - it's free"}
                        </LoadingButton>
                    </div>

                    <div className="mx-auto">
                        <img
                            alt={'Contact '}
                            src="/image/Landing/Landing-Contact-Logo.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
