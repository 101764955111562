import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { LoadingAnimation, PropertyTable } from 'components';
import { PropertyPagination } from 'components/Pagination';
import { queryClient } from 'routes';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertySort } from 'types/PropertyFilters';
import AISearchPanel from './AISearch';
import DrawMapPanel from './Sections/DrawMapPanel';
import ExportPanel from './Sections/ExportPanel';
import FilterPanel from './Sections/FilterPanel';
import InfoPanel from './Sections/InfoPanel';
import SavedFilterPanel from './Sections/SavedFilterPanel';

const OverViewContent = () => {
    const { currentFilter, savedFilterList } = useFiltersStore();

    const {
        currentPage,
        totalPropertiesCount,
        loadingProperties,
        allCompetitorProperties,
        fetchCompetitorAllProperties,
        fetchCompetitorAllPropertiesSort,
        fetchCompetitorAllPropertiesSetSort,
    } = usePropertiesStore();

    const { isLoading } = useQuery({
        queryKey: [
            'fetchCompetitorAllProperties',
            currentPage,
            currentFilter,
            fetchCompetitorAllPropertiesSort,
        ],
        queryFn: () =>
            fetchCompetitorAllProperties(
                currentFilter,
                fetchCompetitorAllPropertiesSort,
            ),
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    });

    const handleSort = (sort: PropertySort) => {
        fetchCompetitorAllPropertiesSetSort(sort);
        queryClient.resetQueries({
            queryKey: [
                'fetchCompetitorAllProperties',
                currentPage,
                currentFilter,
                sort,
            ],
        });
    };

    return (
        <div className="flex flex-col gap-[20px] py-[20px] lg:px-0 px-[20px]">
            <InfoPanel />
            <DrawMapPanel />
            <div className="rounded-[10px] bg-white border border-my-gray border-opacity-60 ">
                <div
                    className={`px-[16px] py-[20px] ${isLoading || loadingProperties ? 'pointer-events-none opacity-50' : ''}`}
                >
                    <FilterPanel />
                </div>
                {savedFilterList.length > 0 && <SavedFilterPanel />}
                <div className="px-[16px] py-[12px]">
                    <AISearchPanel />
                </div>
                <Divider className="w-full opacity-60" />
                <div className="px-[16px] py-[12px]">
                    <ExportPanel />
                </div>
                <div className="pb-[20px] relative">
                    {(isLoading || loadingProperties) && <LoadingAnimation />}

                    <PropertyTable
                        propertyList={allCompetitorProperties}
                        onSort={handleSort}
                    />

                    {totalPropertiesCount > 1 && (
                        <div className="mx-auto w-fit pt-[10px]">
                            <PropertyPagination />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OverViewContent;
