import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import { useTooltip } from 'components/Providers/ToolTipProvider';
import { SelectCompetitorTooltip } from 'components/ToolTips';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useCompetitorsStore from 'store/useCompetitorsStore';
import { useOnboardingStore } from 'store/useOnboardingStore';
import { GetFlag } from 'util/AppApi';
import { ROUTES } from 'util/Constants';
import { isValidWebsiteUrl } from 'util/isValidWebsiteUrl';
import { BlackButton } from '../auth';
import IntroduceSection from './Introduce';
import SelectCompetitorSection from './SelectCompetitor';
import SelectPlanSection from './SelectPlan';
import OnboardingStepper from './Stepper';

const onboardingSteps = ['Create', 'Payment', 'Introduce'];

const OnboardingContent = () => {
    const { showTooltip, hideTooltip } = useTooltip();
    const {
        currentStep,
        setCurrentStep,
        payPricing,
        saveOnboardingInfo,
        account,
    } = useOnboardingStore();
    const { addCompetitors, selectedCompetitors } = useCompetitorsStore();
    const { showMessage } = useToast();

    const { mutate: mutateCompetitors } = useMutation({
        mutationKey: ['addCompetitors'],
        mutationFn: addCompetitors,
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSuccess: () => {
            showMessage('Success', 'Competitors saved successfully');
            setCurrentStep(currentStep + 1);
        },
    });
    const { mutate: mutatePayPricing } = useMutation({
        mutationKey: ['payPricing'],
        mutationFn: payPricing,
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });
    const { mutate: mutateSaveOnboarding } = useMutation({
        mutationKey: ['saveOnboardingInfo'],
        mutationFn: saveOnboardingInfo,
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
        },
    });

    const handleSaveOnboardingInfo = () => {
        if (!account.referralSource) {
            showMessage('Alert', 'Please Select where did you hear about us!');
            return;
        }
        if (!isValidWebsiteUrl(account.websiteUrl)) {
            showMessage('Alert', 'Please enter a valid website URL!');
            return;
        }
        mutateSaveOnboarding();
    };

    const handleSavedCompetitors = () => {
        if (selectedCompetitors.length === 0) {
            showMessage('Alert', 'Please select Competitors!');
            return;
        }
        mutateCompetitors(selectedCompetitors);
    };

    const onNextClicked = async () => {
        hideTooltip();

        const steps = [
            () => handleSavedCompetitors(),
            () => mutatePayPricing(),
            () => handleSaveOnboardingInfo(),
        ];

        steps[currentStep] && steps[currentStep]();
    };

    const onPrevClicked = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const isMutating = useIsMutating() > 0;

    useEffect(() => {
        if (GetFlag('selectCompetitorTooltip')) return;
        showTooltip(<SelectCompetitorTooltip />);
    }, []);

    if (currentStep === 3) {
        return <Navigate to={ROUTES.MAIN} />;
    }

    return (
        <div className="lg:pb-[30px] pb-[60px]">
            <div className="h-[56px] relative border-b border-my-gray border-opacity-60">
                <OnboardingStepper
                    titles={onboardingSteps}
                    completedIndex={currentStep}
                />

                <div className="md:flex hidden h-full items-center absolute top-0 right-0 pr-[30px]">
                    {currentStep > 0 && (
                        <div
                            className="px-[20px] cursor-pointer flex item-center justify-center"
                            onClick={onPrevClicked}
                        >
                            <span>Back</span>
                        </div>
                    )}

                    <BlackButton
                        className="!h-[36px] !rounded-[5px]"
                        onClick={onNextClicked}
                        loading={isMutating}
                    >
                        <div>{`${currentStep === 2 ? 'Finish' : 'Next Step'}`}</div>
                    </BlackButton>
                </div>
            </div>

            <div
                className={`${currentStep === 0 ? 'block' : 'hidden'} lg:max-w-hello lg:mx-auto mx-[10px]`}
            >
                <SelectCompetitorSection />
            </div>

            <div
                className={`${currentStep === 1 ? 'block' : 'hidden'} lg:max-w-hello lg:mx-auto mx-[10px]`}
            >
                <SelectPlanSection />
            </div>

            <div
                className={`${currentStep === 2 ? 'block' : 'hidden'} lg:max-w-hello lg:mx-auto mx-[10px]`}
            >
                <IntroduceSection />
            </div>

            <div className="md:hidden flex items-center justify-between px-[20px] py-[10px] fixed left-0 bottom-0 w-full bg-white border-t border-my-gray">
                <div
                    className="px-[20px] cursor-pointer flex item-center justify-center"
                    onClick={onPrevClicked}
                >
                    <span>Back</span>
                </div>
                <BlackButton
                    className="!h-[36px] !rounded-[5px]"
                    onClick={onNextClicked}
                    loading={isMutating}
                >
                    <div>Next Step</div>
                </BlackButton>
            </div>
        </div>
    );
};

export default OnboardingContent;
