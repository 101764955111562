import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as NewListingSvg } from 'assets/svg/Main/Overview-Number-clock.svg';
import { ReactComponent as CompetitorListingSvg } from 'assets/svg/Main/Overview-Number-listing.svg';
import { ReactComponent as UnlistedSvg } from 'assets/svg/Main/Overview-Number-unlisted.svg';
import { ReactComponent as TotalCompetitorsSvg } from 'assets/svg/Main/Overview-Number.svg';
import { ReactComponent as PercentIncreaseSvg } from 'assets/svg/Main/Percent-Increasing.svg';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import usePropertiesStore from 'store/usePropertiesStore';
import styled from 'styled-components';
import { formatNumber } from 'util/Funcs';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';
import { isExceed } from 'util/getUserPlan';

const CustomInfoCard = styled.div`
    display: flex;
    gap: 12px;
    padding: 14px;
    height: 84px;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
`;

interface InfoCardProps {
    icon: React.ComponentType;
    label: string;
    value: number;
    isLoading: boolean;
    isWarn?: boolean;
}

const InfoCard = React.memo(
    ({
        icon: Icon,
        label,
        value,
        isLoading,
        isWarn = false,
    }: InfoCardProps) => (
        <CustomInfoCard>
            <div className="rounded-[10px] border bg-my-blue bg-opacity-10 border-my-blue p-[12px]">
                <Icon />
            </div>
            <div className="flex flex-col justify-between h-full flex-grow">
                <div className="font-medium">{label}</div>
                <div className="flex items-end justify-between">
                    <div className="font-semibold text-my-blue text-[24px]">
                        {isLoading ? <Skeleton /> : formatNumber(value)}
                    </div>
                    {isWarn && (
                        <div className="text-[#ECA200] text-[10px] flex items-center gap-[2px]">
                            <WarningRoundedIcon className="!text-[16px]" />
                            <span>Exceed limit!</span>
                        </div>
                    )}
                </div>
            </div>
        </CustomInfoCard>
    ),
);

const InfoPanel = () => {
    const user = getUserInfoFromLocalStorage();
    const { propertyStatistics, fetchPropertiesStatistics } =
        usePropertiesStore();

    const { isLoading, error, isError, isRefetching } = useQuery({
        queryKey: ['fetchPropertiesStatistics'],
        queryFn: fetchPropertiesStatistics,
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    });

    if (isError) {
        return <div>{error.message}</div>;
    }

    return (
        <div className="rounded-[10px] bg-white leading-[24px] border border-my-gray border-opacity-60 ">
            {/* percent */}
            <div className="p-[16px] flex justify-end">
                <div className="flex items-center flex-wrap gap-[5px] text-my-blue bg-my-blue bg-opacity-10 md:h-[30px] md:justify-normal justify-end px-[16px] rounded-[5px] font-medium">
                    <span>{'Hello:data Property Listings '}</span>
                    <PercentIncreaseSvg />
                    <div>
                        {isLoading ? (
                            <Skeleton className="h-full w-[60px]" />
                        ) : (
                            <span>{`${formatNumber(
                                propertyStatistics.totalProperties,
                            )} (${formatNumber(propertyStatistics.totalPrecentSinceYesterday)}%)`}</span>
                        )}
                    </div>
                </div>
            </div>

            <Divider className="w-full" />

            <div className="p-[16px] grid lg:grid-cols-4 md:grid-cols-2 gap-[16px]">
                <InfoCard
                    icon={TotalCompetitorsSvg}
                    label="Competitors"
                    value={user?.savedCompetitors.length || 0}
                    isLoading={isLoading || isRefetching}
                    isWarn={isExceed()}
                />

                <InfoCard
                    icon={CompetitorListingSvg}
                    label="Competitor Listings"
                    value={propertyStatistics.propertiesOn}
                    isLoading={isLoading || isRefetching}
                />
                <InfoCard
                    icon={NewListingSvg}
                    label="New Listing last 24hr"
                    value={propertyStatistics.propertiesAddedLast24Hours}
                    isLoading={isLoading || isRefetching}
                />
                <InfoCard
                    icon={UnlistedSvg}
                    label="Unlisted Properties last 30 days"
                    value={propertyStatistics.propertiesOff}
                    isLoading={isLoading || isRefetching}
                />
            </div>
        </div>
    );
};

export default InfoPanel;
