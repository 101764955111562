import ModalLayout from './ModalLayout';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import { useEffect, useState } from 'react';
import LocationInput from 'pages/auth/sign-up/LocationInput';
import { useToast } from 'components/Providers/ToastProvider';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { ERROR_CODE, GetFlag, GetUserFromStorage, POST } from 'util/AppApi';
import { AzureLocation } from 'types/Azure';
import { useTooltip } from 'components/Providers/ToolTipProvider';
import { AddCompetitorTooltip } from 'components/ToolTips';

interface AddCompetitorDTO {
    c_name: string;
    c_property_filter: string;
    c_property_url: string;

    website_url: string;
    country: string;
    city: string;
    email?: string;
    phone_number?: string;
    address?: string;
}

const AddedCompetitor = ({
    competitor,
    index,
    onRemoveClicked,
}: {
    competitor: AddCompetitorDTO;
    index: number;
    onRemoveClicked: () => void;
}) => {
    return (
        <div className="px-[18px] py-[14px] flex gap-[10px] items-center justify-between">
            <div className="flex gap-[10px] items-center">
                <div className="border border-my-gray rounded-full w-[32px] h-[32px] flex items-center justify-center">
                    {index + 1}
                </div>

                <div>
                    <div className="truncate">
                        <span className="text-[16px] font-medium">
                            {competitor.c_name}
                        </span>
                        <span>{` (${competitor.website_url})`}</span>
                    </div>
                    <div className="text-my-black text-opacity-80 text-[12px]">{`${competitor.country} ${competitor.city}`}</div>
                </div>
            </div>

            <div className="cursor-pointer" onClick={onRemoveClicked}>
                <DeleteOutlinedIcon />
            </div>
        </div>
    );
};

const AddCompetitorModal = ({
    visible,
    onClose,
}: {
    visible: boolean;
    onClose: () => void;
}) => {
    const { showTooltip } = useTooltip();

    useEffect(() => {
        if (!visible || GetFlag('addCompetitorTooltip')) return;
        showTooltip(<AddCompetitorTooltip />);
    }, [visible]);

    const [loading, setLoading] = useState<boolean>(false);
    const [competitor, setCompetitor] = useState<AddCompetitorDTO>({
        c_name: '',
        website_url: '',
        country: '',
        city: '',
        c_property_filter: '',
        c_property_url: '',
        email: '',
    });
    const [competitorList, setCompetitorList] = useState<AddCompetitorDTO[]>(
        [],
    );
    const { showMessage } = useToast();

    const onDataChange = (field: keyof AddCompetitorDTO, value: string) => {
        const updated = { ...competitor, [field]: value };
        setCompetitor(updated);
    };

    const onLocationClicked = (v: AzureLocation) => {
        setCompetitor({
            ...competitor,
            country: v.country,
            city: v.city,
            address: v.title,
        });
    };

    const onAddClicked = () => {
        if (loading) return;

        if (competitor.c_name && competitor.website_url && competitor.address) {
            if (
                !(
                    competitor.website_url.startsWith('https://') ||
                    competitor.website_url.startsWith('http://')
                )
            ) {
                showMessage('Alert', 'Please input valid website!');
                return;
            }

            // if (!competitor.email?.includes('@')) {
            //     showMessage('Alert', 'Please input valid email.');
            //     return;
            // }

            setCompetitorList([...competitorList, competitor]);
            setCompetitor({
                c_name: '',
                website_url: '',
                country: '',
                city: '',
                address: '',
                c_property_filter: '',
                c_property_url: '',
                email: '',
            });
        } else {
            showMessage('Alert', 'Input all field!');
        }
    };

    const onRemoveClicked = (index: number) => {
        const newList = [
            ...competitorList.slice(0, index),
            ...competitorList.slice(index + 1),
        ];
        setCompetitorList(newList);
    };

    const onSaveClicked = async () => {
        if (loading) return;

        setLoading(true);
        const user = GetUserFromStorage();
        try {
            const response = await POST('/agents', {
                agentList: competitorList,
                userId: user?.userId,
            });

            if (
                response.status === ERROR_CODE.CODE_CREATED ||
                response.status === ERROR_CODE.CODE_SUCCESS
            ) {
                showMessage('Success', 'Successfully added.');

                onClose();
            } else {
                showMessage('Failed', `${response.data}`);
            }
        } catch {
            showMessage('Failed', 'Can not add competitor.');
        }

        setLoading(false);
    };

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="bg-white rounded-[10px] pb-[24px] pt-[50px] px-[20px] flex flex-col gap-[16px] text-[12px]">
                <div>
                    <div className="font-semibold text-[22px]">
                        {'Add Competitors'}
                    </div>

                    <div className="text-[12px]">
                        {
                            'Simply provide us with the relevant information, and our AI-powered scraper will handle the rest.'
                        }
                    </div>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 gap-[16px]">
                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">
                            {"Competitor's Name"}
                        </div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            value={competitor.c_name}
                            onChange={(e) => {
                                onDataChange('c_name', e.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">
                            {'Website URL'}
                        </div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            value={competitor.website_url}
                            placeholder="e.g. https://www.hellohere.com"
                            onChange={(e) => {
                                onDataChange('website_url', e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-[16px]">
                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">
                            {'Subpath (Optional)'}
                        </div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            value={competitor.c_property_filter}
                            placeholder="e.g /en/property"
                            onChange={(e) => {
                                onDataChange(
                                    'c_property_filter',
                                    e.target.value,
                                );
                            }}
                        />
                    </div>

                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">
                            {'Email (Optional)'}
                        </div>
                        <input
                            type="email"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            value={competitor.email}
                            onChange={(e) => {
                                onDataChange('email', e.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">
                            {'Phone Number (Optional)'}
                        </div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            value={competitor.phone_number}
                            onChange={(e) => {
                                onDataChange('phone_number', e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div>
                    <div className="text-[12px] font-medium pb-[5px]">
                        {'Address'}
                    </div>
                    <div className="h-[42px] text-[12px]">
                        <LocationInput
                            value={competitor.address || ''}
                            onLocationClicked={onLocationClicked}
                            showIcon={false}
                        />
                    </div>
                </div>

                <LoadingButton
                    onClick={onAddClicked}
                    variant="outlined"
                    className={`!rounded-[5px] h-[42px] w-full !px-[25px]`}
                    // loading={loading}
                    loadingIndicator={
                        <CircularProgress color="primary" size={24} />
                    }
                    sx={{
                        color: '#FFF',
                        textTransform: 'none',
                        backgroundColor: '#000',
                        fontSize: '12px',
                        '&:hover': {
                            color: 'black',
                        },
                    }}
                >
                    <div className="flex gap-[5px] items-center">
                        <PlusSvg />
                        <span>Add</span>
                    </div>
                </LoadingButton>

                {competitorList.length > 0 && (
                    <>
                        <div className="w-full h-1 border-b border-my-gray"></div>
                        <div className="font-semibold text-[16px]">
                            {'Added Competitors'}
                        </div>
                        <div className=" rounded-[5px] border border-my-gray max-h-[300px] overflow-y-scroll">
                            {competitorList.map((item, index) => {
                                return (
                                    <AddedCompetitor
                                        competitor={item}
                                        index={index}
                                        key={index}
                                        onRemoveClicked={() =>
                                            onRemoveClicked(index)
                                        }
                                    />
                                );
                            })}
                        </div>

                        <div>
                            <LoadingButton
                                onClick={onSaveClicked}
                                variant="outlined"
                                className={`!rounded-[5px] h-full w-full !px-[25px]`}
                                loading={loading}
                                loadingIndicator={
                                    <CircularProgress
                                        color="primary"
                                        size={24}
                                    />
                                }
                                sx={{
                                    color: '#FFF',
                                    textTransform: 'none',
                                    backgroundColor: '#3176BC',
                                    fontSize: '12px',
                                    '&:hover': {
                                        color: 'black',
                                    },
                                }}
                            >
                                {'Save'}
                            </LoadingButton>
                        </div>
                    </>
                )}
            </div>
        </ModalLayout>
    );
};

export default AddCompetitorModal;
