import { Button } from '@mui/material';

interface ButtonWithIconProps {
    onClick?: () => void;
    text: string;
    disabled: boolean;
    className: string;
    icon?: JSX.Element;
}

const ButtonWithIcon = ({
    onClick,
    text,
    disabled,
    className,
    icon,
}: ButtonWithIconProps) => (
    <Button
        onClick={onClick}
        className={className}
        disabled={disabled}
        type="submit"
    >
        <div className="text-[14px] text-white font-poppins capitalize flex gap-[10px] px-[10px]">
            {icon && icon}
            {text}
        </div>
    </Button>
);

export default ButtonWithIcon;
