import { useTooltip } from 'components/Providers/ToolTipProvider';
import { SetFlag } from 'util/AppApi';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import styles from './ToolTip.module.css';

const AddCompetitorTooltip = () => {
    const { hideTooltip } = useTooltip();

    const onCloseClick = () => {
        SetFlag('addCompetitorTooltip', true);
        hideTooltip();
    };

    return (
        <div className="absolute lg:left-[50%] left-[100px] top-[50%] translate-y-[-50%]">
            <div
                className={`relative bg-black rounded-[10px] px-[12px] py-[18px] max-w-[180px] text-white lg:ml-[-500px] ${styles['tooltip']}`}
            >
                <div
                    onClick={onCloseClick}
                    className="absolute w-[28px] h-[28px] cursor-pointer right-[10px] top-[10px] rounded-full flex items-center justify-center bg-my-gray bg-opacity-30"
                >
                    <CloseOutlinedIcon className={`!text-[14px] text-white`} />
                </div>
                <div className="text-[12px] flex flex-col gap-[10px]">
                    <div>
                        <img alt={'lamp'} src="/image/Tooltip/Lamp.png" />
                        <div className="font-semibold">
                            {
                                'To create a competitor, you are required to provide the following:'
                            }
                        </div>
                    </div>

                    <div className="flex gap-[5px]">
                        <div className="min-w-[16px]">
                            <img
                                alt={'world'}
                                src="/image/Tooltip/World.png"
                                className="min-w-max"
                            />
                        </div>
                        <div>
                            <span>{'Website:'}</span>
                            <span>
                                {' Enter the main website of the competitor.'}
                            </span>
                        </div>
                    </div>

                    <div className="flex gap-[5px]">
                        <div className="min-w-[16px]">
                            <img
                                alt={'Time'}
                                src="/image/Tooltip/Time.png"
                                className="min-w-max"
                            />
                        </div>
                        <div>
                            <span>{'Time:'}</span>
                            <span>
                                {
                                    " Our competitor analysis typically takes 2-3 business hours to complete. We'll notify you by email as soon as your report is ready."
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCompetitorTooltip;
