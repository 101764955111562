import { ReactNode } from 'react';
import styles from './workflow.module.css';

interface NumberSectionProps {
    image: ReactNode;
    title: string;
    description: string;
    className?: string;
}

const NumberSection = ({
    image,
    title,
    description,
    className = '',
}: NumberSectionProps) => {
    return (
        <div
            className={`${className} relative text-center text-white flex flex-col gap-[16px]`}
        >
            <div className="flex justify-center items-center h-[90px]">
                {image}
            </div>
            <div className="flex flex-col gap-[8px]">
                <div className="font-semibold text-[16px]">{title}</div>
                <div className="text-[14px]">{description}</div>
            </div>
        </div>
    );
};

const HelloDataWorkFlowSection = () => {
    return (
        <div className="bg-my-blue text-white pt-[72px] pb-[104px]">
            <div className="lg:px-[160px] md:px-[40px] px-[12px] lg:max-w-hello mx-auto text-center">
                <div className="font-medium pb-[14px]">
                    {'HOW HELLO:DATA WORKS'}
                </div>
                <div className="md:text-[36px] text-[24px] leading-[36px] pb-[90px]">
                    {'Three steps to analyze competitors'}
                </div>
                <div className="relative grid lg:grid-cols-3 md:grid-cols-1 lg:gap-[135px] md:gap-[88px] gap-[30px] md:max-w-[315px] lg:max-w-max mx-auto">
                    <NumberSection
                        className={`${styles['number']}`}
                        image={
                            <img
                                alt={'Landing-Analyze-Step1'}
                                src="/image/Landing/Landing-Analyze-Step1.png"
                            />
                        }
                        title="Sign up"
                        description="Provide your basic information and location to begin your journey with us."
                    />
                    <NumberSection
                        className={styles['number']}
                        image={
                            <img
                                alt={'Landing-Analyze-Step2'}
                                src="/image/Landing/Landing-Analyze-Step2.png"
                            />
                        }
                        title="Add Competitors"
                        description="You can add your competitors or select hundreds from our database. You can swap competitors anytime."
                    />
                    <NumberSection
                        image={
                            <img
                                alt={'Landing-Analyze-Step3'}
                                src="/image/Landing/Landing-Analyze-Step3.png"
                            />
                        }
                        title="Start analyzing"
                        description="Hello:data will provide you with thousands of property listings in an all-in-one dashboard."
                    />

                    {/* <div className="absolute flex justify-around top-0 left-0 w-full">
                        <WorkFlowArrowSvg />
                        <WorkFlowArrowSvg />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default HelloDataWorkFlowSection;
