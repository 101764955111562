import { Divider } from '@mui/material';
import { ReactComponent as TableNextSvg } from 'assets/svg/Table-Next.svg';
import { queryClient } from 'routes';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import styled from 'styled-components';

const PageItemDiv = styled.div`
    width: 40px;
    height: 40px;
    line-height: 40px;

    @media (max-width: 500px) {
        width: 30px;
    }
`;

const NextButton = ({ onClick }: { onClick: () => void }) => {
    const { totalPageCount } = usePropertiesStore();
    const isVisible = !isNaN(totalPageCount) && totalPageCount > 1;

    return isVisible ? (
        <div
            className="!w-fit flex items-center gap-2 h-[41px] rounded-[5px] md:px-[35px] px-[12px] py-[10px] cursor-pointer"
            onClick={onClick}
        >
            <span className="font-medium leading-[21px] normal-case md:block hidden">
                Next
            </span>
            <TableNextSvg />
        </div>
    ) : null;
};

const PrevButton = ({ onClick }: { onClick: () => void }) => {
    const { totalPageCount } = usePropertiesStore();
    const isVisible = !isNaN(totalPageCount) && totalPageCount > 1;

    return isVisible ? (
        <div
            className="!w-fit flex items-center gap-2 h-[41px] rounded-[5px] md:px-[35px] px-[12px] py-[10px] cursor-pointer"
            onClick={onClick}
        >
            <TableNextSvg className="rotate-180" />
            <span className="font-medium leading-[21px] normal-case md:block hidden">
                Prev
            </span>
        </div>
    ) : null;
};

const PageItem = ({
    title,
    sep,
    selected = false,
    onClick = () => {},
}: {
    title: string;
    sep: boolean;
    selected?: boolean;
    onClick?: () => void;
}) => {
    return (
        <>
            <PageItemDiv
                className={`relative text-center cursor-pointer ${selected && 'outline outline-my-blue outline-1 bg-my-blue bg-opacity-10'}`}
                onClick={onClick}
            >
                {title}
            </PageItemDiv>
            {sep && (
                <Divider orientation="vertical" className="!h-[38px] w-[1px]" />
            )}
        </>
    );
};

const PropertyPagination: React.FC = () => {
    const { currentFilter } = useFiltersStore();
    const {
        currentPage,
        setCurrentPage,
        totalPageCount,
        fetchCompetitorAllPropertiesSort,
    } = usePropertiesStore();
    const sw = window.screen.width;
    const boundaryCountLast = 2;
    const boundaryCountFirst = Math.min(
        Math.floor(
            (sw > 744 ? (sw - 260) / 60 : (sw - 100) / 38) - boundaryCountLast,
        ),
        7,
    );

    if (totalPageCount < 2) return null;

    const onNext = () => {
        const nextpage = currentPage + 1;
        if (nextpage < totalPageCount && nextpage >= 0)
            handlePageChange(nextpage);
    };

    const onPrev = () => {
        const nextpage = currentPage - 1;
        if (nextpage < totalPageCount && nextpage >= 0)
            handlePageChange(nextpage);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        queryClient.resetQueries({
            queryKey: [
                'fetchCompetitorAllProperties',
                page,
                currentFilter,
                fetchCompetitorAllPropertiesSort,
            ],
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const isDotEnabled =
        totalPageCount > boundaryCountFirst + boundaryCountLast;
    return (
        <div className="p-2">
            <div className="flex justify-center items-center border border-my-gray border-opacity-60 rounded-[5px]">
                {isDotEnabled && <PrevButton onClick={onPrev} />}

                <Divider orientation="vertical" className="!h-[38px] w-[1px]" />
                {isDotEnabled ? (
                    <>
                        {[...Array(boundaryCountFirst)].map((item, index) => {
                            return (
                                <PageItem
                                    title={`${index + 1}`}
                                    sep={true}
                                    onClick={() => handlePageChange(index)}
                                    key={index}
                                    selected={currentPage === index}
                                />
                            );
                        })}
                        <PageItem
                            title={`...`}
                            sep={true}
                            selected={
                                currentPage >= boundaryCountFirst &&
                                currentPage <
                                    totalPageCount - boundaryCountFirst - 1
                            }
                        />
                        {[...Array(boundaryCountLast)].map((item, index) => {
                            const id =
                                totalPageCount + index - boundaryCountLast + 1;
                            return (
                                <PageItem
                                    title={`${id}`}
                                    sep={true}
                                    onClick={() => handlePageChange(id - 1)}
                                    key={index}
                                    selected={currentPage === id - 1}
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        {[...Array(totalPageCount)].map((item, index) => {
                            return (
                                <PageItem
                                    title={`${index + 1}`}
                                    sep={true}
                                    onClick={() => handlePageChange(index)}
                                    key={index}
                                    selected={currentPage === index}
                                />
                            );
                        })}
                    </>
                )}

                {isDotEnabled && <NextButton onClick={onNext} />}
            </div>
        </div>
    );
};

export default PropertyPagination;
