import styles from './Stepper.module.css'

const OnboardingStepper = ({ titles, completedIndex }: { titles: string[], completedIndex: number }) => {

    return (
        <ul className={`${styles['stepper']} w-full h-full`}>
            {
                titles.map((title: string, index: number) => {
                    return (
                        <li className={`font-medium p-[20px] md:pr-[40px] pr-[20px] ${styles['stepper_item']} ${completedIndex >= index ? `text-my-blue ${styles['complete']}` : 'text-my-gray'} h-full`} key={index}>
                            <div className='flex gap-[3px] items-center pl-[5px] md:pl-[20px]'>
                                <div className={`${completedIndex >= index ? 'bg-my-blue' : 'bg-my-gray'} text-white rounded-full w-[28px] h-[28px] flex justify-center items-center`}>{index + 1}</div>
                                <span>{title}</span>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default OnboardingStepper