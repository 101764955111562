import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import { useNavigate } from 'react-router-dom';
import useOtpStore from 'store/useOtpStore';
import { ROUTES } from 'util/Constants';

type Options = {
    successMessage?: string;
    onSuccess?: () => void;
};

const useUpdateUserEmailMutation = ({
    onSuccess,
    successMessage = 'Your email has been changed!',
}: Options) => {
    const { updateUserEmail } = useOtpStore();
    const { showMessage } = useToast();
    const navigate = useNavigate();

    return useMutation({
        mutationKey: ['updateUserEmail'],
        mutationFn: updateUserEmail,
        onSuccess: (status) => {
            if (status === 200) {
                showMessage('Success', successMessage);
                navigate(ROUTES.EMAIL_UPDATED);
                // logoutUserAndClearLocalStorage();``
            }
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error Occurred', error.message);
        },
    });
};

export default useUpdateUserEmailMutation;
