import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ReactComponent as CountryLockSvg } from 'assets/svg/Country-Lock.svg';

import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_DEBOUNCE_TIME } from 'util/Constants';
import DefaultDropDown from './DefaultDropDown';
import { Region, DEFAULT_REGION } from 'types/Region';
import { UpgradePlanModal } from 'components/Modals';
import { isPremiumUser } from 'util/getUserPlan';
import useRegionStore from 'store/useRegionStore';

interface CityFilterProps {
    lock?: boolean;
    placeholder?: string;
    region: Region;
    selectedCity?: any;
    onChanged?: (_c: string) => void;
}

const MAX_ALL_CITY_COUNT = 100;

const CheckIncludeWithSpecialChar = (word: string, term: string): boolean => {
    if (!word) return false;
    if (!term) return true;

    const lowerTxt = term
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    return word
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(lowerTxt);
};

const CityFilter = ({
    lock = false,
    selectedCity = '',
    onChanged = (c: string) => {},
    region = DEFAULT_REGION,
    placeholder = 'Area',
}: CityFilterProps) => {
    const [showUpgradePlanModal, setShowUpgradePlanModal] =
        useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const childRef = useRef<{ closePopup: () => void }>(null);
    const { regionList, totalCities: allCities } = useRegionStore();

    const itemSelected = useCallback(
        (item: string) => {
            onChanged(item);
            if (childRef.current) {
                childRef.current.closePopup();
            }
        },
        [onChanged],
    );

    const onSearchTermChanged = (_txt: string) => {
        setSearchTerm(_txt);
    };

    const onClearClicked = () => {
        onChanged('');
    };

    const onShowModal = () => {
        if (childRef.current) {
            childRef.current.closePopup();
        }
        if (!isPremiumUser()) setShowUpgradePlanModal(true);
    };

    const UnLockedCityItem = ({ city }: { city: string }) => {
        return (
            <div
                className="truncate px-[10px] py-[6px] hover:bg-my-gray hover:bg-opacity-60 cursor-pointer"
                onClick={() => itemSelected(city)}
            >
                {city}
            </div>
        );
    };

    const LockedCityItem = ({ city }: { city: string }) => {
        return (
            <div
                className="px-[10px] py-[6px] cursor-pointer"
                onClick={onShowModal}
            >
                <div className="flex gap-[10px] items-center justify-between opacity-40">
                    <span>{city}</span>
                    <CountryLockSvg />
                </div>
            </div>
        );
    };

    return (
        <>
            <DefaultDropDown
                ref={childRef}
                Title={
                    <div className="truncate">
                        {selectedCity || placeholder}
                    </div>
                }
                IsEmpty={!selectedCity}
                onClearClicked={onClearClicked}
            >
                {/* searching */}
                <div className="px-[10px] py-[5px]">
                    <div className="rounded-[5px] border border-my-gray border-opacity-60 flex items-center justify-between gap-[5px] px-[10px] py-[5px]">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) =>
                                onSearchTermChanged(e.target.value)
                            }
                            className="flex-grow focus:outline-none w-full"
                        />
                        <SearchOutlinedIcon />
                    </div>
                </div>
                <div className="max-h-[300px] overflow-y-scroll">
                    {/* city list */}
                    {region.name === '' &&
                        allCities
                            .filter((city) =>
                                CheckIncludeWithSpecialChar(
                                    city.title,
                                    searchTerm,
                                ),
                            )
                            .slice(0, MAX_ALL_CITY_COUNT)
                            .map((city, index) =>
                                lock && city.lock ? (
                                    <LockedCityItem
                                        key={index}
                                        city={city.title}
                                    />
                                ) : (
                                    <UnLockedCityItem
                                        key={index}
                                        city={city.title}
                                    />
                                ),
                            )}
                    {(lock
                        ? region.cities
                        : [...region.cities, ...region.nonCities]
                    )
                        .filter((city) =>
                            CheckIncludeWithSpecialChar(city, searchTerm),
                        )
                        .map((city, index) => (
                            <UnLockedCityItem key={index} city={city} />
                        ))}

                    {lock &&
                        region.nonCities
                            .filter((city) =>
                                CheckIncludeWithSpecialChar(city, searchTerm),
                            )
                            .map((city, index) => (
                                <LockedCityItem key={index} city={city} />
                            ))}
                </div>
            </DefaultDropDown>

            <UpgradePlanModal
                visible={showUpgradePlanModal}
                onClose={() => setShowUpgradePlanModal(false)}
            />
        </>
    );
};

export default CityFilter;
