import BottomCard from './BottomCard';
import Layout from './Layout';

const GivingControl = () => {
    return (
        <Layout
            title={'Giving you control'}
            Body={
                <div>
                    <img
                        alt={'Giving Control '}
                        src="/image/Landing/Landing-Feature-GivingControl.png"
                        className="mx-auto"
                    />
                </div>
            }
            Bottom={
                <BottomCard
                    title={'Outsmart your competitors'}
                    content={
                        'Get competitor data instantly with Hello:data. Choose from thousands of competitors or provide their info for comprehensive insights.'
                    }
                />
            }
        />
    );
};

export default GivingControl;
