import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Avatar, Box, Button } from '@mui/material';
import { useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import styled from 'styled-components';
import { GetTwoLetterName } from 'util/Funcs';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';

const CenteredContent = styled.div`
    text-align: center;
    position: relative;
`;

interface AvatarUploadProps {
    imageUrl: string;
    control: Control<any>;
    name: string;
}

const AvatarUpload = ({ imageUrl, control, name }: AvatarUploadProps) => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const user = getUserInfoFromLocalStorage();
    const { field } = useController({ control, name });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newImageFile = event.target?.files?.[0];

        if (newImageFile) {
            field.onChange(newImageFile);
            if (inputFileRef.current) {
                inputFileRef.current.src = URL.createObjectURL(newImageFile);
            }
        }
    };

    const onButtonClicked = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    return (
        <CenteredContent>
            <Avatar
                alt="Avatar"
                src={field.value ? URL.createObjectURL(field.value) : imageUrl}
                sx={{
                    width: '90px',
                    height: '90px',
                    backgroundColor: '#FFC034',
                    '&.muiavatar-root': {
                        width: '90px',
                        height: '90px',
                    },
                }}
            >
                <span className="font-poppins font-medium text-[36px] -tracking-[1px] uppercase text-black">
                    {GetTwoLetterName(user?.fullName || '')}
                </span>
            </Avatar>

            <Box className="!absolute !left-[60px] !top-[60px]">
                <input
                    ref={inputFileRef}
                    accept="image/*"
                    hidden
                    id="avatar-image-upload"
                    type="file"
                    onChange={handleOnChange}
                />
                <label htmlFor="avatar-image-upload">
                    <Button
                        variant="contained"
                        color="primary"
                        className="!bg-white !rounded-full !border-solid !border-[5px] !border-[#ECF0F6] !w-5 !h-7 !p-0 shadow-md !min-w-7 flex justify-center items-center"
                        onClick={onButtonClicked}
                    >
                        <ArrowUpwardIcon
                            className="!w-[20px] !h-[20px]"
                            sx={{ color: '#292D32' }}
                        />
                    </Button>
                </label>
            </Box>
        </CenteredContent>
    );
};

export default AvatarUpload;
