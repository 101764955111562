import { BlackButton } from 'pages/auth';

interface CancelButtonProps {
    loading?: boolean;
    onClick?: () => void;
}
const CancelButton = (props: CancelButtonProps) => {
    const { loading = false, onClick } = props;

    return (
        <>
            <BlackButton
                loading={loading}
                className="!h-[40px] !w-[120px]"
                onClick={onClick}
            >
                {'Cancel'}
            </BlackButton>
        </>
    );
};

export default CancelButton;
