import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppGuard, AuthRedirect } from 'guards';
import {
    LandingPage,
    MainPage,
    SettingPage,
    SignInPage,
    SignUpPage,
    VerifyEmailPage,
} from 'pages';
import CopmetitorsTab from 'pages/main/competitors';
import OnboardingPage from 'pages/on-boarding';
import SubscriptionCanceled from 'pages/on-boarding/SelectPlan/Cancel';
import SubscriptionSuccess from 'pages/on-boarding/SelectPlan/Success';
import EmailUpdatedPage from 'pages/setting/account/EmailUpdated';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'util/Constants';

export const queryClient = new QueryClient();

const routeConfig = [
    { path: '*', element: <SignInPage /> },
    { path: ROUTES.LADNING, element: <LandingPage /> },
    {
        path: ROUTES.SIGN_IN,
        element: (
            <AuthRedirect>
                <SignInPage />
            </AuthRedirect>
        ),
    },
    {
        path: ROUTES.SIGN_UP,
        element: (
            <AuthRedirect>
                <SignUpPage />
            </AuthRedirect>
        ),
    },
    { path: ROUTES.VERIFY_EMAIL, element: <VerifyEmailPage /> },
    {
        path: ROUTES.ONBOARDING,
        element: (
            <AppGuard>
                <OnboardingPage />
            </AppGuard>
        ),
    },
    {
        path: ROUTES.MAIN,
        element: (
            <AppGuard>
                <MainPage />
            </AppGuard>
        ),
    },
    {
        path: ROUTES.COMPETITORS,
        element: (
            <AppGuard>
                <CopmetitorsTab />
            </AppGuard>
        ),
    },
    {
        path: ROUTES.SETTING,
        element: (
            <AppGuard>
                <SettingPage />
            </AppGuard>
        ),
    },
    { path: ROUTES.SUBSCRIPTION_SUCCESS, element: <SubscriptionSuccess /> },
    { path: ROUTES.SUBSCRIPTION_CANCELED, element: <SubscriptionCanceled /> },
    { path: ROUTES.EMAIL_UPDATED, element: <EmailUpdatedPage /> },
];

const AppRoutes = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    {routeConfig.map(({ path, element }, index) => (
                        <Route key={index} path={path} element={element} />
                    ))}
                </Routes>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default AppRoutes;
