import { ClipLoader } from 'react-spinners';

interface LoadingSpinnerProps {
    loading: boolean;
    className?: string;
}

const LoadingSpinner = ({ loading, className }: LoadingSpinnerProps) =>
    loading ? (
        <ClipLoader
            color="#000"
            loading={loading}
            className={`!w-[30px] !h-[30px] !ml-[20px] ${className}`}
        />
    ) : null;

export default LoadingSpinner;
