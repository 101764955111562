import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useRegionStore from 'store/useRegionStore';
import { DEFAULT_REGION, Region } from 'types/Region';
import { DEFAULT_FILTER, PROPERTY_TYPES, SALE_TYPE } from 'util/Constants';
import { FilterType } from 'util/Types';
import BedBathFilter from './BedBathFilter';
import CityFilter from './CityFilter';
import CountryFilter from './CountryFilter';
import RangeFilter from './RangeFilter';
import SimpleFilter from './SimpleFilter';

const CONST_AREA_LIMIT: [number, number] = [0, 1000000];
const PLOT_AREA_LIMIT: [number, number] = [0, 10000000];
const PRICE_AREA_LIMIT: [number, number] = [0, 1000000000];

interface FilterSetPaneProps {
    lock?: boolean;
    value?: FilterType;
    heightClass?: string;
    handleChanged?: (f: FilterType) => void;
}

const FilterSetPane = forwardRef((props: FilterSetPaneProps, ref) => {
    const {
        lock = false,
        heightClass = 'h-[42px]',
        value: prevFilter = null,
        handleChanged = (f: FilterType) => {},
    } = props;
    const [filter, setFilter] = useState<FilterType>(DEFAULT_FILTER);
    const { regionList } = useRegionStore();
    const [region, setRegion] = useState<Region>(DEFAULT_REGION);

    useImperativeHandle(ref, () => ({
        getFilter() {
            return filter;
        },
        clearFilter() {
            setFilter(DEFAULT_FILTER);
            setRegion(DEFAULT_REGION);
        },
    }));

    const onFilterChanged = (
        key: keyof FilterType,
        value: Record<string, any> | string,
    ) => {
        const newFilter = { ...filter, [key]: value };
        setFilter(newFilter);
        handleChanged(newFilter);
    };

    const regionChanged = (region: Region) => {
        setRegion(region);
        const newFilter = { ...filter, country: region.name, city: '' };
        setFilter(newFilter);
        handleChanged(newFilter);
    };

    const onCityChanged = (cityName: string) =>
        onFilterChanged('city', cityName);

    const onBedBathChanged = (bedCount: number, bathCount: number) =>
        onFilterChanged('bedbath', { bedIdx: bedCount, bathIdx: bathCount });

    const onPropertyTypeChanged = (propertyType: string) =>
        onFilterChanged('type', propertyType);

    const onSaleTypeChanged = (saleType: string) =>
        onFilterChanged('listingType', saleType);

    const onConstAreaChanged = (constructionAreaRange: [number, number]) =>
        onFilterChanged('constructionArea', {
            from: constructionAreaRange[0],
            to: constructionAreaRange[1],
        });

    const onPlotAreaChanged = (plotAreaRange: [number, number]) =>
        onFilterChanged('plotArea', {
            from: plotAreaRange[0],
            to: plotAreaRange[1],
        });

    const onPriceChanged = (priceRange: [number, number]) =>
        onFilterChanged('price', { from: priceRange[0], to: priceRange[1] });

    useEffect(() => {
        if (prevFilter) {
            setFilter(prevFilter);
            const prevRegions = regionList?.filter(
                (c) => c.name === prevFilter.country,
            );

            if (Array.isArray(prevRegions) && prevRegions?.length > 0) {
                setRegion(prevRegions[0]);
            }
        }
    }, [prevFilter, regionList]);

    return (
        <>
            <div className={`${heightClass}`}>
                <CountryFilter
                    lock={lock}
                    selectedRegion={region}
                    onChanged={regionChanged}
                />
            </div>

            <div className={`${heightClass}`}>
                <CityFilter
                    lock={lock}
                    selectedCity={filter.city}
                    onChanged={onCityChanged}
                    region={region}
                />
            </div>

            <div className={`${heightClass}`}>
                <SimpleFilter
                    stringlist={PROPERTY_TYPES}
                    placeholder="Type"
                    value={filter.type}
                    onChanged={onPropertyTypeChanged}
                />
            </div>

            <div className={`${heightClass}`}>
                <RangeFilter
                    title={'Price'}
                    limit={PRICE_AREA_LIMIT}
                    unit="€"
                    value={[filter.price.from, filter.price.to]}
                    onChanged={onPriceChanged}
                    step={100}
                />
            </div>

            <div className={`${heightClass}`}>
                <BedBathFilter
                    onCallback={onBedBathChanged}
                    bedIdx={filter.bedbath.bedIdx}
                    bathIdx={filter.bedbath.bathIdx}
                />
            </div>

            <div className={`${heightClass}`}>
                <SimpleFilter
                    stringlist={SALE_TYPE}
                    placeholder="Rental, Sales"
                    value={filter.listingType}
                    onChanged={onSaleTypeChanged}
                />
            </div>

            <div className={`${heightClass}`}>
                <RangeFilter
                    title={'Construction Area'}
                    limit={CONST_AREA_LIMIT}
                    unit="m2"
                    value={[
                        filter.constructionArea.from,
                        filter.constructionArea.to,
                    ]}
                    onChanged={onConstAreaChanged}
                    step={1000}
                />
            </div>

            <div className={`${heightClass}`}>
                <RangeFilter
                    title={'Plot Area'}
                    limit={PLOT_AREA_LIMIT}
                    unit="m2"
                    value={[filter.plotArea.from, filter.plotArea.to]}
                    onChanged={onPlotAreaChanged}
                    step={1000}
                />
            </div>
        </>
    );
});

export default FilterSetPane;
