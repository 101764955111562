import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import { Link } from 'react-router-dom';
import { useAuthStore } from 'store/useAuthStore';
import { ROUTES } from 'util/Constants';
import BlackButton from '../BlackButton';
import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

const ResendVerifyEmail = () => {
    const {
        userEmailAndId: { email },
        resendVerficationEmail,
    } = useAuthStore();
    const { showMessage } = useToast();

    const { mutate } = useMutation({
        mutationKey: ['resendVerficationEmail'],
        mutationFn: resendVerficationEmail,
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
        onSuccess: () => {
            showMessage('Success', 'Verification mail sent successfully.');
        },
    });

    const onResendVerificationLinkClicked = async () => mutate();

    return (
        <div className="rightpanel flex lg:items-center lg:justify-normal justify-center px-[16px] pt-[76px] lg:px-[48px] lg:pt-[0px]">
            <div className="flex flex-col max-w-[550px]">
                <div>
                    <div className="flex gap-[5px] items-center text-[18px] pb-[30px]">
                        <MainLogoSvg />
                    </div>

                    <div className="flex flex-col gap-[10px]">
                        <div className="text-black font-semibold text-[20px] leading-[21px]">
                            Verify your email
                        </div>
                        <div className="text-[#121212] text-[14px] leading-[24px] opacity-80">
                            <span>A verification email has been sent to </span>
                            <span className="font-semibold">{email} </span>
                            <span>
                                Please check your inbox for the email and click
                                the verification link to activate your account.
                            </span>
                        </div>

                        <div className="flex gap-[20px] text-[14px] items-center">
                            <BlackButton
                                className="!h-[36px]"
                                onClick={onResendVerificationLinkClicked}
                            >
                                <div>Resend Verification Link</div>
                            </BlackButton>

                            <Link className="text-my-blue" to={ROUTES.SIGN_IN}>
                                Continue to the login page
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResendVerifyEmail;
