import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { Region } from 'types/Region';
import { create } from 'zustand';

interface CityItem {
    title: string;
    lock: boolean;
}

interface RegionState {
    fetchRegions: () => Promise<Region[]>;
    regionList: Region[];
    totalCities: CityItem[];
}

const useRegionStore = create<RegionState>((set) => ({
    regionList: [],
    totalCities: [],
    fetchRegions: async () => {
        try {
            const { data } = await axiosInstance.get(`/properties/filters`);
            const receivedRegionList: Region[] = data.data;

            let allCities: CityItem[] = [];
            receivedRegionList.map((region) => {
                allCities = allCities.concat(
                    region.cities.map((city) => {
                        return { title: city, lock: false };
                    }),
                );
                allCities = allCities.concat(
                    region.nonCities.map((city) => {
                        return { title: city, lock: true };
                    }),
                );
            });
            // Use reduce to get distinct values based on the 'name' property
            const uniqueCities = allCities.reduce<CityItem[]>(
                (acc, current) => {
                    const existingItem = acc.find(
                        (item) => item.title === current.title,
                    );
                    if (!existingItem) {
                        acc.push(current);
                    }
                    return acc;
                },
                [],
            );

            set({
                regionList: receivedRegionList,
                totalCities: uniqueCities,
            });

            return data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
        }
    },
}));

export default useRegionStore;
