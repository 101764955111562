import { CustomAzureMap } from 'components/Map';
import ModalLayout from './ModalLayout';

const AzureMapModal = ({
    visible,
    onClose,
}: {
    visible: boolean;
    onClose: () => void;
}) => {
    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="rounded-[10px] overflow-hidden">
                <div className="text-my-blue font-medium md:h-[48px] flex items-center justify-center bg-white p-[20px]">
                    {
                        'Click and hold on the text box and drag it to the desired location on the map.'
                    }
                </div>
                <div
                    className="lg:w-[1100px] w-full"
                    style={{ height: 'calc(100vh - 160px)' }}
                >
                    <CustomAzureMap />
                </div>
            </div>
        </ModalLayout>
    );
};

export default AzureMapModal;
