import { useAuthStore } from 'store/useAuthStore';
import EnterInfo from './EnterInfo';
import ResendVerifyEmail from './ResendVerifyEmail';

const SignUpPage = () => {
    const {
        userEmailAndId: { userId },
    } = useAuthStore();

    return (
        <div className="lg:grid grid-cols-2 w-screen h-screen py-[24px] px-[18px]">
            <div className="relative hidden lg:block rounded-[15px] bg-my-green bg-opacity-40 overflow-hidden border border-my-gray">
                <img
                    alt={'SignUp Logo '}
                    src="/image/SignUp-Logo.png"
                    className="absolute top-[144px] left-[72px]"
                />
            </div>
            {userId ? <ResendVerifyEmail /> : <EnterInfo />}
        </div>
    );
};

export default SignUpPage;
