import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CompetitorPagination, LoadingAnimation } from 'components';
import FavAvatar from 'components/FavAvatar';
import SimpleFilter from 'components/Filter/SimpleFilter';
import { debounce, findKey } from 'lodash';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import { Agent } from 'types/Agent';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';
import styles from './competitors.module.css';

type SortListType = {
    [key: string]: string;
};
const sortList: SortListType = {
    Date: 'createdAt',
    Name: 'name',
    Country: 'country',
};

interface FormData {
    search: string;
}

const LeftPanel = () => {
    const user = getUserInfoFromLocalStorage();

    const debouncedRefetch = useRef(
        debounce(() => {
            inValidateAndRefetch();
        }, 500),
    );

    const { register, watch } = useForm<FormData>({
        defaultValues: {
            search: '',
        },
    });

    const {
        fetchSavedCompetitors,
        savedCompetitors,
        savedCompetitorsCurrentPage,
        savedCompetitorsTotalPages,
        setSavedCompetitorsPage,
        savedCompetitorsCountPerPage,
        selectedSavedCompetitor,
        setSelectedSavedCompetitor,
        setShowSavedCompetitorInfo,
        savedCompetitorsSortAndFilters,
        setSavedCompetitorsSortAndFilters,
    } = useCompetitorsStore();

    const key = findKey(
        sortList,
        (val) => val === savedCompetitorsSortAndFilters.sort,
    );

    const { isLoading } = useQuery({
        queryKey: ['fetchSavedCompetitors'],
        queryFn: fetchSavedCompetitors,
        refetchOnWindowFocus: false,
    });

    const inValidateAndRefetch = () => {
        queryClient.resetQueries({
            queryKey: ['fetchSavedCompetitors'],
        });
    };

    const handleFilterChange = (selectedValue: keyof SortListType) => {
        const newFilter = {
            sort: sortList[selectedValue],
            searchTerm: watch('search'),
        };
        setSavedCompetitorsSortAndFilters(newFilter.sort, newFilter.searchTerm);
        inValidateAndRefetch();
    };

    const onCompetitorSelected = (competitor: Agent) => {
        setSelectedSavedCompetitor(competitor);
        setShowSavedCompetitorInfo(true);
        queryClient.resetQueries({
            queryKey: ['fetchPropertiesForCompetitor', competitor.companyId],
        });
    };

    const handlePageChange = (page: number) => {
        setSavedCompetitorsPage(page);
        inValidateAndRefetch();
    };

    return (
        <div className="relative h-full">
            <div className="p-[13px]">
                <div className="rounded-[5px] border border-my-gray border-opacity-60 flex items-center justify-between gap-[5px] px-[10px] py-[5px] h-[42px]">
                    <SearchOutlinedIcon />
                    <input
                        type="text"
                        className="flex-grow focus:outline-none w-full"
                        placeholder="Search Competitor"
                        {...register('search', {
                            onChange: (e) => {
                                setSavedCompetitorsSortAndFilters(
                                    savedCompetitorsSortAndFilters.sort,
                                    e.target.value,
                                );
                                debouncedRefetch.current();
                            },
                        })}
                    />
                </div>
            </div>

            <Divider className="w-full" />
            <div className="px-[13px] py-[6px] flex items-center justify-between h-[48px]">
                <div className="font-semibold text-[16px] truncate">
                    {`${user?.savedCompetitors.length} Competitors`}
                </div>
                <div className="min-w-[150px] h-full text-[12px]">
                    <SimpleFilter
                        placeholder="Sort By"
                        value={key || ''}
                        onChanged={handleFilterChange}
                        stringlist={Object.keys(sortList)}
                    />
                </div>
            </div>

            <Divider className="w-full" />
            <div className={`relative overflow-auto ${styles['leftcontent']}`}>
                {isLoading ? (
                    <LoadingAnimation />
                ) : (
                    savedCompetitors.map(
                        (competitor: Partial<Agent>, index: number) => {
                            const selected =
                                selectedSavedCompetitor?.companyId ===
                                competitor.companyId;
                            return (
                                <div
                                    key={index}
                                    className={`cursor-pointer ${selected ? 'bg-my-blue text-white' : ''}`}
                                    onClick={() =>
                                        onCompetitorSelected(
                                            competitor as Agent,
                                        )
                                    }
                                >
                                    <div className="p-[11px]">
                                        <div className="flex gap-[15px] items-center">
                                            <a
                                                href={
                                                    new URL(
                                                        competitor.websiteUrl ||
                                                            '',
                                                    ).origin
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FavAvatar
                                                    url={competitor.websiteUrl!}
                                                    imageSize={30}
                                                    containerSize={56}
                                                />
                                            </a>
                                            <div className="flex-grow max-w-[270px]">
                                                <div className="flex flex-col gap-[5px] w-full">
                                                    <div className="font-medium truncate">
                                                        {competitor.name}
                                                    </div>
                                                    <div
                                                        className={`text-[12px] truncate text-opacity-60 flex items-center ${!selected ? 'text-black' : ''}`}
                                                    >
                                                        <LocationOnOutlinedIcon />
                                                        <div className="text-[12px] truncate font-medium">{`${competitor.city ? `${competitor.city},` : 'Unknown'} ${competitor.country || ''}`}</div>
                                                    </div>
                                                    <div className="text-[12px] font-semibold truncate">{`${competitor.propertyCount || 0} property listings`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Divider className="w-full" />
                                </div>
                            );
                        },
                    )
                )}
            </div>

            <div className="h-[30px]"></div>

            <div className="absolute bottom-0 left-0 w-full">
                {savedCompetitorsTotalPages > 1 && (
                    <Divider className="w-full" />
                )}
                <div className="flex justify-center relative py-[5px] bg-white">
                    <CompetitorPagination
                        totalPages={savedCompetitorsTotalPages}
                        currentPage={savedCompetitorsCurrentPage}
                        onPageChange={handlePageChange}
                        countPerPage={savedCompetitorsCountPerPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default LeftPanel;
