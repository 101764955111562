import { ReactComponent as DashboardSvg } from 'assets/svg/Feature-Dashboard-Icon.svg';
import { ReactComponent as CompetitorProfileSvg } from 'assets/svg/Feature-CompetitorProfile-Icon.svg';
import { ReactComponent as AISearchSvg } from 'assets/svg/Feature-AISearch-Icon.svg';
import { ReactComponent as MapSearchSvg } from 'assets/svg/Feature-MapSearch-Icon.svg';
import { ReactNode, useState } from 'react';
import { MenuItem, Select } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CTab = ({
    children,
    selected = false,
    index,
    onClick,
}: {
    children: ReactNode;
    selected?: boolean;
    index: number;
    onClick: (c: number) => void;
}) => {
    return (
        <div
            className="p-[8px] text-center text-[#121212] text-opacity-30 cursor-pointer"
            style={
                selected
                    ? {
                          color: '#4EBBBB',
                          background:
                              'linear-gradient(to top, #D9D9D9, rgba(78, 187, 187, 0.2) 5%, #fff 100%)',
                      }
                    : {}
            }
            onClick={() => onClick(index)}
        >
            {children}
        </div>
    );
};
const OverviewSection = () => {
    const [index, setIndex] = useState<number>(0);

    return (
        <div className="lg:px-[100px] md:px-[32px] px-[12px] lg:max-w-hello mx-auto">
            {/* header */}
            <div>
                <div className="px-[12px] md:hidden">
                    <Select
                        value={index}
                        onChange={(e) => setIndex(Number(e.target.value))}
                        displayEmpty
                        className="!w-full !h-full rounded-[8px] border-[#DFE1E1] leading-6 px-1 bg-my-green bg-opacity-10 !text-my-green"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300, // Set your desired max height here
                                },
                            },
                        }}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        <MenuItem value={0} className="h-[50px]">
                            <div className="flex items-center gap-[20px]">
                                <DashboardSvg />
                                <div className="font-medium truncate">
                                    {'Powerful Dashboard'}
                                </div>
                            </div>
                        </MenuItem>

                        <MenuItem value={1} className="h-[50px]">
                            <div className="flex items-center gap-[20px]">
                                <CompetitorProfileSvg />
                                <div className="font-medium">
                                    {'Competitor Profile'}
                                </div>
                            </div>
                        </MenuItem>

                        <MenuItem value={2} className="h-[50px]">
                            <div className="flex items-center gap-[20px]">
                                <AISearchSvg />
                                <div className="font-medium">{'AI Search'}</div>
                            </div>
                        </MenuItem>

                        <MenuItem value={3} className="h-[50px]">
                            <div className="flex items-center gap-[20px]">
                                <MapSearchSvg />
                                <div className="font-medium">
                                    {'Map Search'}
                                </div>
                            </div>
                        </MenuItem>
                    </Select>
                </div>

                <div className="md:grid hidden grid-cols-4 max-w-[800px] mx-auto">
                    <CTab index={0} selected={index === 0} onClick={setIndex}>
                        <div>
                            <DashboardSvg className="mx-auto" />
                        </div>
                        <div className="font-medium">
                            {'Powerful Dashboard'}
                        </div>
                    </CTab>

                    <CTab index={1} selected={index === 1} onClick={setIndex}>
                        <div>
                            <CompetitorProfileSvg className="mx-auto" />
                        </div>
                        <div className="font-medium">
                            {'Competitor Profile'}
                        </div>
                    </CTab>

                    <CTab index={2} selected={index === 2} onClick={setIndex}>
                        <div>
                            <AISearchSvg className="mx-auto" />
                        </div>
                        <div className="font-medium">{'AI Search'}</div>
                    </CTab>

                    <CTab index={3} selected={index === 3} onClick={setIndex}>
                        <div>
                            <MapSearchSvg className="mx-auto" />
                        </div>
                        <div className="font-medium">{'Map Search'}</div>
                    </CTab>
                </div>
            </div>

            <div className="w-full max-h-[760px] overflow-hidden p-[12px] md:border-t border-my-gray flex">
                <div className="rounded-[5px] overflow-hidden">
                    <img
                        alt={'Dashboard '}
                        src="/image/Landing/Landing-Feature-Dashboard.png"
                        className={index === 0 ? '' : 'hidden'}
                    />
                    <img
                        alt={'Dashboard '}
                        src="/image/Landing/Landing-Feature-CompetitorOverview.png"
                        className={index === 1 ? '' : 'hidden'}
                    />
                    <img
                        alt={'Dashboard '}
                        src="/image/Landing/Landing-Feature-AI-Search.png"
                        className={index === 2 ? '' : 'hidden'}
                    />
                    <img
                        alt={'Dashboard '}
                        src="/image/Landing/Landing-Feature-Map-Search.png"
                        className={index === 3 ? '' : 'hidden'}
                    />
                </div>
            </div>
        </div>
    );
};

export default OverviewSection;
