import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as AISearchSvg } from 'assets/svg/AI-Search-Icon.svg';
import { ReactComponent as LogoSearchSvg } from 'assets/svg/LogoSearch.svg';
import { ErrorMessage } from 'components';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { queryClient } from 'routes';
import { aiSearchSchema } from 'schemas/aiSearchValidationSchema';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { DEFAULT_FILTER } from 'util/Constants';

interface FormData {
    searchTerm: string;
}

const AiSearchButton = () => {
    return (
        <div className="relative flex">
            <div
                className="absolute top-0 left-0 rounded-[5px] opacity-20 w-full h-full z-0"
                style={{
                    background:
                        'linear-gradient(0deg, #3176BC 0%, #4EBBB8 100%)',
                }}
            ></div>
            <div
                className="flex items-center justify-center w-full gap-[5px] rounded-[5px] text-white text-[12px] m-[4px] z-[1] px-[20px] py-[5px]"
                style={{
                    background:
                        'linear-gradient(90deg, #3176BC 0%, #4EBBB8 100%)',
                }}
            >
                <AISearchSvg />
                <span className="truncate py-[5px]">{`AI Property search`}</span>
            </div>
        </div>
    );
};

const AISearchPanel = () => {
    const { currentFilter, setCurrentFilter } = useFiltersStore();

    const { control, handleSubmit, getValues, setValue, formState, reset } =
        useForm<FormData>({
            resolver: yupResolver(aiSearchSchema),
            defaultValues: { searchTerm: '' },
        });
    const { fetchAiProperties, currentPage, fetchCompetitorAllPropertiesSort } =
        usePropertiesStore();

    const { mutate, isPending } = useMutation({
        mutationFn: fetchAiProperties,
        mutationKey: ['fetchAiProperties'],
    });

    const onClickClear = () => {
        setValue('searchTerm', '');
        queryClient.resetQueries({
            queryKey: [
                'fetchCompetitorAllProperties',
                currentPage,
                currentFilter,
                fetchCompetitorAllPropertiesSort,
            ],
        });
    };

    const onSubmit: SubmitHandler<FormData> = ({ searchTerm }) => {
        mutate(searchTerm);
        setCurrentFilter(DEFAULT_FILTER);
    };

    useEffect(() => {
        const resetHandler = () => reset();
        window.addEventListener('resetAllForms', resetHandler);
        return () => {
            window.removeEventListener('resetAllForms', resetHandler);
        };
    }, [reset]);

    return (
        <form
            className="flex md:flex-row flex-col gap-[12px] justify-between md:h-[48px]"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="h-[48px] w-full">
                <Controller
                    name="searchTerm"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="h-[48px] w-full rounded-[8px] border border-my-gray border-opacity-80">
                            <div className="flex justify-between items-center gap-[5px] h-full px-[10px]">
                                <LogoSearchSvg />
                                <input
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Search for any property you desire...."
                                    className="flex-grow"
                                />
                                {getValues('searchTerm')?.length > 0 && (
                                    <div
                                        className="text-my-blue cursor-pointer"
                                        onClick={onClickClear}
                                    >
                                        Clear
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                />
                {formState.errors.searchTerm && (
                    <ErrorMessage
                        msg={`${formState.errors.searchTerm.message}`}
                    />
                )}
            </div>
            <button
                className="min-w-[200px] cursor-pointer"
                type="submit"
                disabled={isPending}
            >
                <AiSearchButton />
            </button>
        </form>
    );
};

export default AISearchPanel;
