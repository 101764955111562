import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useUserStore from 'store/useUserStore';

type UpdateUserInfoMutationOptions = {
    onSuccess?: () => void;
    successMessage?: string;
};

const useUpdateUserInfoMutation = ({
    onSuccess,
    successMessage = 'Successfully Changed  Full Name.',
}: UpdateUserInfoMutationOptions) => {
    const { showMessage } = useToast();
    const { updateUserInfo } = useUserStore();

    return useMutation({
        mutationFn: updateUserInfo,
        mutationKey: ['updateUserInfo'],
        onSuccess: () => {
            showMessage('Success', successMessage!);
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });
};

export default useUpdateUserInfoMutation;
