import { ReactComponent as EditFilterSvg } from 'assets/svg/EditFilter.svg';
import { ReactComponent as RemoveFilterSvg } from 'assets/svg/RemoveFilter.svg';
import { useState } from 'react';
import { useFiltersStore } from 'store/useFiltersStore';
import { DEFAULT_FILTER } from 'util/Constants';
import { GetUniqueString } from 'util/Funcs';
import { LocalSavedFilter } from 'util/Types';
import ModalLayout from './ModalLayout';
import SaveFilterModal from './SaveFilter';

interface ManageFilterModalProps {
    visible: boolean;
    onClose: () => void;
}

const ManageFilterModal = ({ visible, onClose }: ManageFilterModalProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { removeFilter, updateFilter, savedFilterList } = useFiltersStore();

    const [filterEdited, setFilterEdited] = useState({
        id: GetUniqueString(),
        title: '',
        filter: DEFAULT_FILTER,
    });

    const [editFilterIndex, setEditFilterIndex] = useState<number>(-1);

    const onOpenEditModalClicked = (
        index: number,
        filter: LocalSavedFilter,
    ) => {
        setExpanded(true);
        setEditFilterIndex(index);
        setFilterEdited(filter);
    };

    const updateFilterClicked = (filter: LocalSavedFilter) => {
        updateFilter(editFilterIndex, filter);
        setExpanded(false);
    };

    return (
        <>
            {!expanded && (
                <ModalLayout visible={visible} onClose={onClose}>
                    <div className="rounded-[10px] bg-white overflow-hidden md:w-[600px]">
                        <div className="h-[60px] px-[23px] flex items-center bg-my-gray">
                            Manage saved filters
                        </div>
                        <div className="px-[23px] py-[10px] ">
                            <div className="min-h-[50px] max-h-[300px] overflow-auto">
                                <div className="flex flex-col gap-[10px]">
                                    {savedFilterList.map((filter, index) => (
                                        <div
                                            key={index}
                                            className="rounded-[5px] flex justify-between items-center h-[48px] border border-my-gray border-opacity-60 px-[10px]"
                                        >
                                            <div className="truncate ">
                                                {filter.title}
                                            </div>
                                            <div className="flex gap-[10px]">
                                                <div
                                                    onClick={() =>
                                                        onOpenEditModalClicked(
                                                            index,
                                                            filter,
                                                        )
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <EditFilterSvg />
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        removeFilter(index)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <RemoveFilterSvg />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalLayout>
            )}
            <SaveFilterModal
                visible={expanded}
                onClose={() => setExpanded(false)}
                filter={filterEdited}
                onSave={updateFilterClicked}
            />
        </>
    );
};

export default ManageFilterModal;
