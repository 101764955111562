import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useOtpStore from 'store/useOtpStore';

type Options = {
    successMessage?: string;
    successType?: string;
    onSuccess?: () => void;
};

const useSendOptForUpdateEmailMutation = ({
    onSuccess,
    successMessage = 'Enter your OTP!',
    successType = 'Sent Otp again.',
}: Options) => {
    const { sendOtpforUpdateEmail } = useOtpStore();
    const { showMessage } = useToast();

    return useMutation({
        mutationKey: ['sendOtpforUpdateEmail'],
        mutationFn: sendOtpforUpdateEmail,
        onSuccess: (status) => {
            if (status === 201) {
                showMessage(successType!, successMessage!);
            }
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error Occurred', error.message);
        },
    });
};

export default useSendOptForUpdateEmailMutation;
