import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

const HeaderTitle = () => {
    return (
        <div className="py-[10px] flex justify-center items-center">
            <MainLogoSvg />
        </div>
    );
};

export default HeaderTitle;
