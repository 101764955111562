import { Divider } from '@mui/material';
import { PropertyDTO } from 'util/Types';

const FeaturePane = ({ property }: { property: PropertyDTO | null }) => {
    const features: string[] | undefined = property?.details.features;
    if (!features || features.length === 0) return null;

    return (
        <>
            <Divider className="w-full" />

            <div className="flex items-center flex-wrap gap-[14px] text-[12px] text-my-black text-opacity-80">
                {features.map((feature: string, index: number) => {
                    if (!feature) return null;
                    return (
                        <div
                            className="flex items-center justify-center bg-my-gray bg-opacity-20 px-[20px] h-[32px] rounded-[10px]"
                            key={index}
                        >
                            <span>{feature}</span>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default FeaturePane;
