import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import styles from './Pricing.module.css';
import { GradientButton } from 'components';
import {
    DEFAULT_DURATION,
    DEFAULT_PLAN,
    FixedFeatures,
    MAX_LIMIT,
    PlanPeriods,
    ROUTES,
    SubscriptionPlans,
    TickPath,
} from 'util/Constants';
import { Plans, PlanTypes } from 'util/Types';

const PlanCard = ({
    selected,
    plan,
    duration,
}: {
    selected: boolean;
    plan: PlanTypes;
    duration: number;
}) => {
    const navigate = useNavigate();
    const features = [
        `Track up ${plan.maxTrackUpCount === MAX_LIMIT ? 'Unlimited' : plan.maxTrackUpCount} competitors`,
        `${plan.maxSwapCount === MAX_LIMIT ? 'Unlimited' : plan.maxSwapCount} competitor swap per month`,
        `${plan.maxPropertyData === MAX_LIMIT ? 'Unlimited' : `Max. ${plan.maxPropertyData}`} property data`,
        ...FixedFeatures,
    ];

    return (
        <div
            className={`cursor-pointer hover:shadow-hello rounded-[20px] border ${selected ? 'shadow-hello-blue border-my-blue border-opacity-80' : 'border-my-gray border-opacity-40'} px-[20px] py-[24px]`}
        >
            <img alt={'Plan '} src={plan.icon} />
            <div className="font-semibold text-[20px]">{plan.title}</div>
            <div className="text-[12px] truncate">{plan.description}</div>
            <div>
                <span className="text-[36px] font-semibold">{`$${plan.pricePerMonth}`}</span>
                <span className="text-[12px]">{`/per month`}</span>
            </div>

            <div className="border border-b-my-gray border-opacity-40"></div>
            <div className="text-[12px] flex flex-col gap-[16px] py-[16px]">
                <div className="font-semibold text-[14px]">{'Features'}</div>
                {features.map((feature, index) => (
                    <div className="flex gap-[5px] items-center" key={index}>
                        <img alt={'Tick '} src={TickPath} />
                        <div className="truncate">{feature}</div>
                    </div>
                ))}
            </div>
            <LoadingButton
                onClick={() => navigate(ROUTES.SIGN_UP)}
                variant="outlined"
                className={`!rounded-[5px] h-[42px] w-full`}
                // loading={loading}
                loadingIndicator={
                    <CircularProgress color="primary" size={24} />
                }
                sx={{
                    color: '#121212',
                    textTransform: 'none',
                    backgroundColor: '#FFF',
                    fontSize: '12px',
                    '&:hover': {
                        color: 'black',
                    },
                }}
            >
                {'Sign Up'}
            </LoadingButton>
        </div>
    );
};

const ComparePlanTable = ({ duration }: { duration: number }) => {
    const navigate = useNavigate();
    const [currentPlan, setCurrentPlan] = useState<Plans>(DEFAULT_PLAN);
    const plan: PlanTypes = SubscriptionPlans.filter(
        (_plan) => _plan.title === currentPlan,
    )[0];

    return (
        <>
            <div className="lg:hidden md:pb-[40px] pb-[20px]">
                <PlanSelector
                    currentPlan={currentPlan}
                    onChange={setCurrentPlan}
                />
            </div>

            {plan && (
                <table
                    className={`${styles['tablecontainer']} text-[14px] w-full lg:hidden table`}
                >
                    <tbody>
                        <tr>
                            <td>
                                <div className="font-medium text-[18px]">
                                    {'Compare plans'}
                                </div>
                                <div className="pt-[24px]">
                                    {'Find the package that fits your needs'}
                                </div>
                            </td>
                            <td className={`text-center`}>
                                <div className="font-semibold text-[20px]">
                                    {plan.title}
                                </div>
                                <div>
                                    <span className="text-[36px] font-semibold">{`$${plan.pricePerMonth}`}</span>
                                    <span className="text-[12px]">{`/per month`}</span>
                                </div>
                                <LoadingButton
                                    onClick={() => navigate(ROUTES.SIGN_UP)}
                                    variant="outlined"
                                    className={`!rounded-[5px] h-[42px] !px-[25px]`}
                                    // loading={loading}
                                    loadingIndicator={
                                        <CircularProgress
                                            color="primary"
                                            size={24}
                                        />
                                    }
                                    sx={{
                                        color: '#FFF',
                                        textTransform: 'none',
                                        backgroundColor: '#3176BC',
                                        fontSize: '12px',
                                        '&:hover': {
                                            color: 'black',
                                        },
                                    }}
                                >
                                    {'Sign Up'}
                                </LoadingButton>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Competitors'}</div>
                            </td>
                            <td className={`text-center`}>
                                {plan.maxSwapCount === MAX_LIMIT
                                    ? 'Unlimited'
                                    : plan.maxSwapCount}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Competitor change (Per month)'}</div>
                            </td>
                            <td className={`text-center`}>
                                {plan.maxSwapCount === MAX_LIMIT
                                    ? 'Unlimited'
                                    : plan.maxSwapCount}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Receive daily email reports'}</div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                    <img
                                        alt={'Table Tick (Receive)'}
                                        src="/image/Plan/Plan-Table-Tick.png"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Export data to CSV or PDF'}</div>
                            </td>
                            <td>
                                <div className="flex justify-center">
                                    <img
                                        alt={'Table Tick (Export)'}
                                        src="/image/Plan/Plan-Table-Tick.png"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Max property data'}</div>
                            </td>
                            <td className={`text-center`}>
                                {plan.maxPropertyData === MAX_LIMIT
                                    ? 'Unlimited'
                                    : plan.maxPropertyData}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'Map Search (Per Month)'}</div>
                            </td>
                            <td className={`text-center`}>
                                {plan.maxMapSearchPerMonth === MAX_LIMIT
                                    ? 'Unlimited'
                                    : plan.maxMapSearchPerMonth}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>{'AI Search (Per Month)'}</div>
                            </td>
                            <td className={`text-center`}>
                                {plan.maxAISearchPerMonth === MAX_LIMIT
                                    ? 'Unlimited'
                                    : plan.maxAISearchPerMonth}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}

            <table
                className={`${styles['tablecontainer']} text-[14px] w-full lg:table hidden`}
            >
                <tbody>
                    <tr>
                        <td>
                            <div className="font-medium text-[18px]">
                                {'Compare plans'}
                            </div>
                            <div className="pt-[24px]">
                                {'Find the package that fits your needs'}
                            </div>
                        </td>
                        {SubscriptionPlans.map((plan: PlanTypes, index) => {
                            return (
                                <td className={`text-center`} key={index}>
                                    <div className="font-semibold text-[20px]">
                                        {plan.title}
                                    </div>
                                    <div>
                                        <span className="text-[36px] font-semibold">{`$${plan.pricePerMonth}`}</span>
                                        <span className="text-[12px]">{`/per month`}</span>
                                    </div>
                                    <LoadingButton
                                        onClick={() => navigate(ROUTES.SIGN_UP)}
                                        variant="outlined"
                                        className={`!rounded-[5px] h-[42px] !px-[25px]`}
                                        // loading={loading}
                                        loadingIndicator={
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                            />
                                        }
                                        sx={{
                                            color: '#FFF',
                                            textTransform: 'none',
                                            backgroundColor: '#3176BC',
                                            fontSize: '12px',
                                            '&:hover': {
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        {'Sign Up'}
                                    </LoadingButton>
                                </td>
                            );
                        })}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Competitors'}</div>
                        </td>
                        {SubscriptionPlans.map((plan: PlanTypes, index) => {
                            return (
                                <td className={`text-center`} key={index}>
                                    {plan.maxSwapCount === MAX_LIMIT
                                        ? 'Unlimited'
                                        : plan.maxSwapCount}
                                </td>
                            );
                        })}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Competitor change (Per month)'}</div>
                        </td>
                        {SubscriptionPlans.map((plan, index) => {
                            return (
                                <td className={`text-center`} key={index}>
                                    {plan.maxSwapCount === MAX_LIMIT
                                        ? 'Unlimited'
                                        : plan.maxSwapCount}
                                </td>
                            );
                        })}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Receive daily email reports'}</div>
                        </td>
                        {SubscriptionPlans.map((plan: PlanTypes, index) => {
                            return (
                                <td key={index}>
                                    <div className="flex justify-center">
                                        <img
                                            alt={`Table Tick ${index}`}
                                            src="/image/Plan/Plan-Table-Tick.png"
                                        />
                                    </div>
                                </td>
                            );
                        })}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Export data to CSV or PDF'}</div>
                        </td>
                        {SubscriptionPlans.map((plan, index) => (
                            <td key={index}>
                                <div className="flex justify-center">
                                    <img
                                        alt={`Export ${index}`}
                                        src="/image/Plan/Plan-Table-Tick.png"
                                    />
                                </div>
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Max property data'}</div>
                        </td>
                        {SubscriptionPlans.map(
                            (plan: PlanTypes, index: number) => {
                                return (
                                    <td className={`text-center`} key={index}>
                                        {plan.maxPropertyData === MAX_LIMIT
                                            ? 'Unlimited'
                                            : plan.maxPropertyData}
                                    </td>
                                );
                            },
                        )}
                    </tr>

                    <tr>
                        <td>
                            <div>{'Map Search (Per Month)'}</div>
                        </td>
                        {SubscriptionPlans.map(
                            (plan: PlanTypes, index: number) => {
                                return (
                                    <td className={`text-center`} key={index}>
                                        {plan.maxMapSearchPerMonth === MAX_LIMIT
                                            ? 'Unlimited'
                                            : plan.maxMapSearchPerMonth}
                                    </td>
                                );
                            },
                        )}
                    </tr>

                    <tr>
                        <td>
                            <div>{'AI Search (Per Month)'}</div>
                        </td>
                        {SubscriptionPlans.map(
                            (plan: PlanTypes, index: number) => {
                                return (
                                    <td className={`text-center`} key={index}>
                                        {plan.maxAISearchPerMonth === MAX_LIMIT
                                            ? 'Unlimited'
                                            : plan.maxAISearchPerMonth}
                                    </td>
                                );
                            },
                        )}
                    </tr>
                </tbody>
            </table>
        </>
    );
};

const PeriodSelector = ({
    duration,
    onChange,
}: {
    duration: number;
    onChange: (v: number) => void;
}) => {
    return (
        <div className="rounded-[60px] h-[60px] border border-my-gray border-opacity-40 p-[8px] grid grid-cols-2 w-fit">
            {PlanPeriods.map((period, index) => (
                <div
                    className={`cursor-pointer rounded-[60px] h-full ${duration === period.duration ? 'text-white bg-my-black' : ''}`}
                    key={index}
                    onClick={() => onChange(period.duration)}
                >
                    <div className="w-full h-full flex items-center justify-center px-[40px]">
                        <span>{period.description}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

const PlanSelector = ({
    currentPlan,
    onChange,
}: {
    currentPlan: Plans;
    onChange: (v: Plans) => void;
}) => {
    return (
        <div className="rounded-[60px] h-[60px] border border-my-gray border-opacity-40 p-[8px] flex w-fit mx-auto md:text-[14px] text-[10px]">
            {SubscriptionPlans.map((plan: PlanTypes, index: number) => {
                return (
                    <div
                        className={`cursor-pointer rounded-[60px] h-full ${currentPlan === plan.title ? 'text-white bg-my-black' : ''}`}
                        key={index}
                        onClick={() => onChange(plan.title)}
                    >
                        <div className="w-full h-full flex items-center justify-center md:px-[40px] px-[20px]">
                            <span>{plan.title}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const PricingSection = () => {
    const [duration, setDuration] = useState<number>(DEFAULT_DURATION);
    const [currentPlan, setCurrentPlan] = useState<Plans>(DEFAULT_PLAN);

    return (
        <div
            className="lg:max-w-hello mx-auto lg:px-[96px] md:px-[32px] px-[12px]"
            id="pricing"
        >
            <div className="text-center max-w-[880px] mx-auto">
                <div className="flex justify-center pb-[30px]">
                    <GradientButton>
                        <div className="flex items-center justify-between rounded-[5px] h-[30px] px-[15px] gap-[10px]">
                            <img
                                alt={'Pricing Plan '}
                                src="/image/Landing/Landing-Pricing-Plan.png"
                                className="w-[14px] h-[14px]"
                            />
                            <span>{`Pricing plan`}</span>
                        </div>
                    </GradientButton>
                </div>
                <div className="md:text-[36px] text-[24px] text-my-black font-nunito font-extrabold leading-[36px]">
                    {'Real Estate Analytics Made Simple and Affordable'}
                </div>
                <div className="text-[14px] text-my-black text-opacity-80 pt-[24px] leading-[28px]">
                    {
                        'Our affordable pricing plans fit right in your budget.  Try our tool free to get started, or choose a paid package to unlock more features for competitor tracking.  Upgrade your plan anytime for even greater insights.'
                    }
                </div>
            </div>

            <div className="flex justify-center lg:pt-[60px] md:pt-[42px]">
                <PeriodSelector onChange={setDuration} duration={duration} />
            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[20px] pt-[36px]">
                {SubscriptionPlans.map((plan: PlanTypes, index: number) => (
                    <div onClick={() => setCurrentPlan(plan.title)} key={index}>
                        <PlanCard
                            plan={plan}
                            duration={duration}
                            selected={currentPlan === plan.title}
                        />
                    </div>
                ))}
            </div>

            <div className="md:pt-[90px] pt-[40px]">
                <ComparePlanTable duration={duration} />
            </div>
        </div>
    );
};

export default PricingSection;
