import { Control, Controller } from 'react-hook-form';
import OTPInput from 'react-otp-input';

interface OtpInputProps {
    control: Control<any>;
    name: string;
}

const originalClassName =
    'sm:h-[90px] lg:w-[72px] lg:leading-[90px] border text-[#4EBBB8] lg:text-[40px] sm:text-[30px] text-[25px] font-bold sm:w-[40px] h-[40px] w-[30px] leading-[40px] ';
const containerStyle =
    'mt-[20px] md:gap-[10px] sm:gap-[4px] gap-[2px] grid grid-cols-6';

const OtpInput: React.FC<OtpInputProps> = ({ control, name }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <OTPInput
                    value={value}
                    onChange={onChange}
                    inputType="number"
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                        <input
                            {...props}
                            className={`flex flex-1 bg-[#FBFBFB] border border-[#E1E1E1] rounded-[8px] ${originalClassName}`}
                        />
                    )}
                    containerStyle={containerStyle}
                />
            )}
        />
    );
};

export default OtpInput;
