import { ReactNode } from 'react';
import TitleButton from './TItleButton';

interface LayoutProps {
    title: string;
    Body: ReactNode;
    Bottom: ReactNode;
}

const Layout = ({ title, Body, Bottom }: LayoutProps) => {
    return (
        <div
            className="flex flex-col justify-between gap-[5px] rounded-[30px] md:h-[545px] px-[24px] pt-[16px] pb-[36px] bg-white overflow-hidden"
            style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
            <div>
                <TitleButton title={title} />
            </div>
            {Body}
            <div className="w-full bg-white">{Bottom}</div>
        </div>
    );
};

export default Layout;
