import { Menu } from "@mui/material";
import { forwardRef, ReactNode, useImperativeHandle, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ClearFilterButton from "./ClearFilterButton";

interface DefaultDropDownProps {
    Title: ReactNode;
    IsEmpty: boolean;
    children: ReactNode;
    onClearClicked: () => void
}

const DefaultDropDown = forwardRef((props: DefaultDropDownProps, ref) => {
    const { Title, IsEmpty, children, onClearClicked } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expanded = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useImperativeHandle(ref, () => ({
        closePopup() {
            onClose();
        }
    }));

    const onClose = () => {
        setAnchorEl(null);
    }

    return (
        <div className={`relative w-full h-full border border-my-gray rounded-[5px]`}>
            {/* caption */}
            <div className={`flex items-center justify-between gap-[15px] w-full h-full px-[10px] cursor-pointer`}
                onClick={handleClick}
            >
                <div>{Title}</div>
                {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
            {/* clear button */}
            {
                !expanded && (!IsEmpty) && <ClearFilterButton onClicked={onClearClicked} />
            }
            {/* Popups */}
            <Menu anchorEl={anchorEl} open={expanded} onClose={onClose} sx={{ marginTop: '10px' }}>
                {children}
            </Menu>
        </div >

    )
});

export default DefaultDropDown;