const ClearFilterButton = ({ onClicked }: { onClicked: () => void }) => {
    return (
        <div
            className={`absolute bg-red-600 rounded-full w-[17px] h-[17px] flex justify-center items-center cursor-pointer top-[-5px] right-[-5px] z-[3]`}
            onClick={onClicked}
        >
            <span className="text-white leading-[17px]">{'×'}</span>
        </div>
    );
};

export default ClearFilterButton;
