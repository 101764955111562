import { ReactComponent as FeatureBedroomSvg } from 'assets/svg/feature-bedroom.svg';
import { ReactComponent as FeatureBathroomSvg } from 'assets/svg/feature-bathroom.svg';
import { ReactComponent as FeatureBuildAreaSvg } from 'assets/svg/feature-buildarea.svg';
import { ReactComponent as FeaturePlotSizeSvg } from 'assets/svg/feature-plotsize.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatNumber } from 'util/Funcs';
import { PropertyDTO } from 'util/Types';
import { Button, Divider } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PropertyAdditionalDetail } from 'types/Property';

interface DetailPaneProps {
    property: PropertyDTO | null;
    onExplorerListingClicked?: () => void;
    additionalDetails: PropertyAdditionalDetail | null;
}
const DetailPane = (props: DetailPaneProps) => {
    const {
        additionalDetails,
        property,
        onExplorerListingClicked = () => {},
    } = props;

    if (!property?.details) return null;

    const details = property.details;
    return (
        <div className="flex flex-col gap-[12px] w-full">
            <div className="flex justify-between items-center">
                {/* Price */}
                <div className="text-[28px] font-bold text-[#63C3C1] pt-[8px]">
                    {details.price && details.price > 0
                        ? `${formatNumber(details.price)} ${getSymbolFromCurrency(details.currency) || ''} `
                        : 'On Request'}
                </div>
                {additionalDetails && (
                    <div className="text-[10px] leading-[10px] lg:block hidden">
                        <div className="font-semibold">{`Views`}</div>

                        <div className="flex items-center gap-[5px]">
                            <VisibilityIcon />
                            <span className="font-semibold text-[18px]">
                                {additionalDetails.view || 0}
                            </span>
                            {additionalDetails?.view && (
                                <>
                                    <span className="text-my-blue p-[4px] bg-my-blue bg-opacity-10 font-medium">{`+${additionalDetails.view}`}</span>
                                    <span>{`In this month`}</span>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Divider className="w-full" />
            <div className="flex justify-between items-center">
                <div className="relative flex flex-wrap gap-[12px]">
                    {details.bedrooms && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBedroomSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">
                                        {formatNumber(details.bedrooms)}
                                    </span>
                                    <span className="text-opacity-80">{` Bed room`}</span>
                                </span>
                            </div>
                            <Divider
                                className="h-full"
                                orientation="vertical"
                            />
                        </>
                    )}
                    {details.bathrooms && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBathroomSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">
                                        {formatNumber(details.bathrooms)}
                                    </span>
                                    <span className="text-opacity-80">{` Bath room`}</span>
                                </span>
                            </div>
                            <Divider
                                className="h-full"
                                orientation="vertical"
                            />
                        </>
                    )}

                    {details.sizeLand && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBuildAreaSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">
                                        {`${formatNumber(details.sizeLand)}m2`}
                                    </span>
                                    <span className="text-opacity-80">{` Built areas`}</span>
                                </span>
                            </div>
                            <Divider
                                className="h-full"
                                orientation="vertical"
                            />
                        </>
                    )}
                    {details.sizePlot && (
                        <div className="flex items-center gap-[8px]">
                            <FeaturePlotSizeSvg />

                            <span className="text-my-black">
                                <span className="font-bold">
                                    {`${formatNumber(details.sizePlot)}m2`}
                                </span>
                                <span className="text-opacity-80">{` Plot size`}</span>
                            </span>
                        </div>
                    )}
                </div>

                <div className="xl:block hidden">
                    <Button
                        variant="contained"
                        onClick={onExplorerListingClicked}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '5px',
                            height: '32px',
                            backgroundColor: '#4EBBBB',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#4EBBBB',
                            },
                        }}
                        className="truncate"
                    >
                        {'Explore Listing'}
                    </Button>
                </div>
            </div>
            {additionalDetails && (
                <div className="text-[10px] leading-[10px] lg:hidden flex flex-col gap-[4px]">
                    <Divider className="w-full" />
                    <div className="font-semibold pt-[8px]">{`Views`}</div>

                    <div className="flex items-center gap-[5px]">
                        <VisibilityIcon />
                        <span className="font-semibold text-[18px]">
                            {additionalDetails.view || 0}
                        </span>
                        {additionalDetails?.view && (
                            <>
                                <span className="text-my-blue p-[4px] bg-my-blue bg-opacity-10 font-medium">{`+${additionalDetails.view}`}</span>
                                <span>{`In this month`}</span>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailPane;
