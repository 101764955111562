const IntroductionSection = () => {
    return (
        <div className="text-center text-[14px] py-[36px] flex flex-col gap-[20px] px-[20px] max-w-[610px] mx-auto">
            <div className="font-semibold">{'PRICING PLAN'}</div>
            <div className="font-semibold text-[24px]">{'Choose the best value-based pricing'}</div>
            <div>
                <span>{`Our pricing plans are crafted to suit your budget and customized to your specific requirements, offering you the best deal and affordability.`}</span>
            </div>
        </div>
    )
}

export default IntroductionSection;