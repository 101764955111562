import { FormControl, MenuItem, Select } from '@mui/material';
import ClearFilterButton from './ClearFilterButton';

interface SimpleFilterProps {
    stringlist?: string[];
    placeholder: string;
    value: string;
    onChanged: (_s: string) => void;
}

const SimpleFilter = ({
    value,
    onChanged,
    stringlist = [],
    placeholder,
}: SimpleFilterProps) => {
    const clearFilterClicked = () => {
        onChanged('');
    };

    return (
        <div className="relative w-full h-full">
            {!value && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center px-[10px] truncate">
                    {placeholder}
                </div>
            )}

            <FormControl fullWidth className="!h-full">
                <Select
                    value={value}
                    onChange={(e) => onChanged(e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                        return selected;
                    }}
                    className="!w-full !h-full rounded-[8px] border-[#DFE1E1] leading-6 px-1"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300, // Set your desired max height here
                            },
                        },
                    }}
                >
                    {stringlist.map((item: string, idx: number) => (
                        <MenuItem
                            value={item}
                            key={idx}
                            sx={{ height: '24px' }}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* clear button */}
            {value && <ClearFilterButton onClicked={clearFilterClicked} />}
        </div>
    );
};

export default SimpleFilter;
