import { LocationType } from 'types/Azure';

export const getCurrentLocation = (
    setLocation = (v: LocationType | null) => {},
    setError: (_m: string) => void,
) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },
            (error: GeolocationPositionError) => {
                switch (error.code) {
                    case GeolocationPositionError.PERMISSION_DENIED:
                        setError('User denied the request for Geolocation.');
                        break;
                    case GeolocationPositionError.POSITION_UNAVAILABLE:
                        setError('Location information is unavailable.');
                        break;
                    case GeolocationPositionError.TIMEOUT:
                        setError('The request to get user location timed out.');
                        break;
                    default:
                        setError('An unknown error occurred.');
                        break;
                }
                setLocation(null);
            },
        );
    } else {
        setError('Geolocation is not supported by this browser.');
        setLocation(null);
    }
};

export const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
) => {
    var R = 6371000; // Radius of the Earth in meters
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c;
    return distance;
};

export const getDistancePerPixel = (zoomLevel: number) => {
    var earthCircumference = 40075017; // in meters
    var distancePerPixel = earthCircumference / (256 * Math.pow(2, zoomLevel));
    return distancePerPixel; // in meters per pixel
};
