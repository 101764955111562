import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useOtpStore from 'store/useOtpStore';

type Options = {
    successMessage?: string;
    onSuccess?: () => void;
};

const useSendOtpMutation = ({
    onSuccess,
    successMessage = 'Enter your OTP!',
}: Options) => {
    const { sendOtp } = useOtpStore();
    const { showMessage } = useToast();

    return useMutation({
        mutationFn: sendOtp,
        mutationKey: ['sendOtp'],
        onSuccess: (status) => {
            if (status === 201) {
                showMessage('Success', successMessage!);
            }
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error Occurred', error.message);
        },
    });
};

export default useSendOtpMutation;
