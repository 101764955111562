import { PropertyDTO } from 'util/Types';

import { ReactComponent as PropertyRentSvg } from 'assets/svg/Property-Rent.svg';
import { ReactComponent as PropertySaleSvg } from 'assets/svg/Property-Sale.svg';

interface PropertyMarkerProps {
    property: PropertyDTO;
    onClick?: () => void;
}
const PropertyMarker = (props: PropertyMarkerProps) => {
    const { property, onClick } = props;

    return (
        <>
            {property?.details?.listingType === 'Rental' ? (
                <PropertyRentSvg
                    className="w-[20px] h-[20px] translate-y-[50%]"
                    onClick={onClick}
                />
            ) : (
                <PropertySaleSvg
                    className="w-[20px] h-[20px] translate-y-[50%]"
                    onClick={onClick}
                />
            )}
        </>
    );
};

export default PropertyMarker;
