import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate } from 'react-router-dom';
import useRegionStore from 'store/useRegionStore';
import useUserStore from 'store/useUserStore';
import { ROUTES } from 'util/Constants';
import {
    getTokenFromLocalStorage,
    getUserInfoFromLocalStorage,
} from 'util/localStorageHelper';

export const AppGuard = ({
    children,
}: Readonly<{ children?: React.ReactNode }>) => {
    const userToken = getTokenFromLocalStorage();
    const userInfo = getUserInfoFromLocalStorage();

    const { fetchUser } = useUserStore();
    const { fetchRegions } = useRegionStore();

    const onboardingDidNotFinish =
        !userInfo ||
        !userInfo.accountType ||
        !userInfo.savedCompetitors ||
        !userInfo.subscriptionId ||
        userInfo.savedCompetitors.length === 0;

    const browserPath = window.location.pathname;

    useQuery({
        queryKey: ['fetchUser'],
        queryFn: fetchUser,
        enabled: !userInfo && !userToken,
    });
    useQuery({
        queryKey: ['fetchRegions'],
        queryFn: fetchRegions,
        enabled: () => {
            return !!userToken;
        },
        refetchOnWindowFocus: false,
    });

    if (!userToken) {
        return <Navigate to={ROUTES.SIGN_IN} />;
    }

    if (onboardingDidNotFinish && browserPath !== ROUTES.ONBOARDING) {
        return <Navigate to={ROUTES.ONBOARDING} />;
    }

    return <>{children}</>;
};
