import { AgentFilterType, FilterType, PlanTypes, SortDTO } from './Types';

export const DEFAULT_PAGE = 1;
export const COUNT_PER_PAGE = 15;
export const DEFAULT_COMPETITOR_COUNT = 2;
export const DEFAULT_DEBOUNCE_TIME = 500;
export const MAX_LIMIT = 999999;
export const TickPath = '/image/Landing/Landing-TickOnPlan.png';

export const FixedFeatures = [
    'Receive daily email reports',
    'Export data to CSV or PDF',
];

export const DEFAULT_DURATION = 1;
export const DEFAULT_PLAN = 'Free';

export const PlanPeriods = [
    { duration: 1, description: 'Monthly', discount: 0 },
    { duration: 12, description: 'Yearly', discount: 0.2 },
];

export const ROUTES = {
    LADNING: '/',

    SIGN_IN: '/sigin',
    SIGN_UP: '/sigup',
    VERIFY_EMAIL: '/verify-mail',

    ONBOARDING: '/onboarding',
    MAIN: '/overview',
    COMPETITORS: '/competitors',

    SETTING: '/setting',
    EMAIL_UPDATED: '/email-updated',

    TEST: '/test',

    SUBSCRIPTION_SUCCESS: '/subscription/success',
    SUBSCRIPTION_CANCELED: '/subscription/cancel',
};

export const SubscriptionPlans: PlanTypes[] = [
    {
        priceId:
            process.env.REACT_APP_NODE_ENV === 'production'
                ? {
                      month: 'price_1PAalPHfaSP6XcAkd9ksVyOd',
                      year: 'price_1PAalPHfaSP6XcAkvgTCr47D',
                  }
                : {
                      month: 'price_1OzKgsHfaSP6XcAkRFBjRnps',
                      year: 'price_1P7PUEHfaSP6XcAk1KONZXwD',
                  },
        icon: '/image/Plan/Plan-Free-Icon.png',
        title: 'Free',
        description: 'minimum experience',
        pricePerMonth: 0,
        maxTrackUpCount: 2,
        maxSwapCount: 2,
        maxPropertyData: 100,
        maxMapSearchPerMonth: 3,
        maxAISearchPerMonth: 3,
    },
    {
        priceId:
            process.env.REACT_APP_NODE_ENV === 'production'
                ? {
                      month: 'price_1PAalMHfaSP6XcAkXWhcX4DI',
                      year: 'price_1PAalMHfaSP6XcAkAIV8xnUc',
                  }
                : {
                      month: 'price_1OzKieHfaSP6XcAkto2cMA20',
                      year: 'price_1P7PTpHfaSP6XcAkqjQrQLAP',
                  },
        icon: '/image/Plan/Plan-Basic-Icon.png',
        title: 'Basic',
        description: 'Track your small competitors with ease',
        pricePerMonth: 49,
        maxTrackUpCount: 30,
        maxSwapCount: 5,
        maxPropertyData: MAX_LIMIT,
        maxMapSearchPerMonth: 10,
        maxAISearchPerMonth: 10,
    },
    {
        priceId:
            process.env.REACT_APP_NODE_ENV === 'production'
                ? {
                      month: 'price_1PAalLHfaSP6XcAkksM1A1Q5',
                      year: 'price_1PAalLHfaSP6XcAk1HBPcOPG',
                  }
                : {
                      month: 'price_1OzKk7HfaSP6XcAkuRsUDush',
                      year: 'price_1P7PScHfaSP6XcAk7gdqzBZZ',
                  },
        icon: '/image/Plan/Plan-Standard-Icon.png',
        title: 'Standard',
        description: 'Best for mid level businesses',
        pricePerMonth: 99,
        maxTrackUpCount: 100,
        maxSwapCount: 100,
        maxPropertyData: MAX_LIMIT,
        maxMapSearchPerMonth: 20,
        maxAISearchPerMonth: 20,
    },
    {
        priceId:
            process.env.REACT_APP_NODE_ENV === 'production'
                ? {
                      month: 'price_1PAalJHfaSP6XcAkGmEB7egg',
                      year: 'price_1PAalJHfaSP6XcAk0qyplya6',
                  }
                : {
                      month: 'price_1OzKlaHfaSP6XcAkoBtJ7XWq',
                      year: 'price_1P7PS1HfaSP6XcAkatFwfHNu',
                  },
        icon: '/image/Plan/Plan-Premium-Icon.png',
        title: 'Premium',
        description: 'Ultimate data experience ',
        pricePerMonth: 149,
        maxTrackUpCount: MAX_LIMIT,
        maxSwapCount: MAX_LIMIT,
        maxPropertyData: MAX_LIMIT,
        maxMapSearchPerMonth: MAX_LIMIT,
        maxAISearchPerMonth: MAX_LIMIT,
    },
];

export const FAV_PREFIX = 'http://www.google.com/s2/favicons?domain=';
export const USER_NOT_FOUND_MESSAGE = 'User not found';

export const PROPERTY_TYPES = [
    'Detached house',
    'Villa',
    'Terraced house',
    'Country house',
    'Townhouse',
    'Special property',
    'Castle',
    'Bungalow',
    'Terraced house',
    'Semi-detached house',
    'Apartment',
    'Flat',
    'Penthouse',
    'Maisonette',
    'Loft',
    'Attic Apartment',
    'Land',
    'Plot',
    'Parking',
    'Investment',
    'Commercial Space',
    'Agricultural and forestry',
    'Hotel',
    'Industry',
    'Gastronomy',
    'Office',
    'Storage rooms',
    'Garages',
    'Other',
];

export const SALE_TYPE = ['Sales', 'Rental', 'Rental, Sales'];

export const DEFAULT_FROMTO_TYPE = {
    from: 0,
    to: 0,
};

export const DEFAULT_AGENT_FILTER: AgentFilterType = {
    country: '',
    city: '',
    searchTerm: '',
};

export const DEFAULT_FILTER: FilterType = {
    country: '',
    city: '',
    type: '', //property type
    price: DEFAULT_FROMTO_TYPE,
    constructionArea: DEFAULT_FROMTO_TYPE,
    plotArea: DEFAULT_FROMTO_TYPE,
    bedbath: { bedIdx: 0, bathIdx: 0 },
    listingType: '',
};

export const DEFAULT_SORT: SortDTO = {
    field: 'propertyCreatedAt',
    order: 'DESC',
};

export const ExtraCountryCodes: { [key: string]: string } = {
    Unknown: '',
    'United States': 'US',
    'United Kingdom': 'GB',
    Bolivia: 'BO',
    'The Bahamas': 'BS',
    'Hong Kong SAR': 'HK',
    'Hong Kong': 'HK',
    Iran: 'IR',
    // Add other countries as needed
};
