import SelectCompetitorPanel from 'components/SelectCompetitor';
import { DEFAULT_COMPETITOR_COUNT } from 'util/Constants';

const SelectCompetitorSection = () => {
    return (
        <>
            <div className="text-center text-[14px] py-[36px] flex flex-col gap-[20px] px-[20px]">
                <div className="font-semibold">
                    {'MAKE COMPETITOR DATABASE'}
                </div>
                <div className="font-semibold text-[24px]">
                    {
                        'Select your competitors from the database or add new ones'
                    }
                </div>
                <div>
                    <span>{`You can add upto `}</span>
                    <span className="font-semibold">{`${DEFAULT_COMPETITOR_COUNT} competitors `}</span>
                    <span>{`before selecting package`}</span>
                </div>
            </div>

            <div className="mx-[10px] lg:mx-[20px]">
                <SelectCompetitorPanel />
            </div>
        </>
    );
};

export default SelectCompetitorSection;
