import { yupResolver } from '@hookform/resolvers/yup';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage } from 'components';
import { useToast } from 'components/Providers/ToastProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { emailSchema } from 'schemas/emailValidationSchema';
import useOtpStore from 'store/useOtpStore';
import { ROUTES } from 'util/Constants';
import BlackButton from '../BlackButton';
import CustomOutlinedInput from '../CustomOutlinedInput';

interface FormData {
    email: string;
}

const EnterEmail = () => {
    const { showMessage } = useToast();
    const { sendOtp } = useOtpStore();

    const { handleSubmit, control, formState } = useForm<FormData>({
        resolver: yupResolver<FormData>(emailSchema),
    });

    const sendOtpMutation = useMutation({
        mutationFn: sendOtp,
        onSuccess: (status) => {
            if (status === 201) {
                showMessage('Success', 'Enter your OTP!');
            }
        },
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
    });

    const onEnterOtpClicked: SubmitHandler<FormData> = ({ email }) => {
        sendOtpMutation.mutate(email);
    };

    return (
        <form onSubmit={handleSubmit(onEnterOtpClicked)}>
            <div className="loginDialog bg-white rounded-[20px] md:px-[36px] sm:px-[24px] px-[16px] py-[48px] flex flex-col gap-[25px]">
                <div className="flex flex-col gap-[10px]">
                    <div className="text-black font-semibold text-[20px] leading-[21px]">
                        {'Log in to your account'}
                    </div>
                    <div className="text-my-black text-[14px] opacity-80 leading-[24px] ">
                        {
                            "Dive back into hello:data! We've been busy gathering in-depth competitor intel and data – it's all waiting for you to explore."
                        }
                    </div>
                </div>
                <div>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <CustomOutlinedInput
                                name="email"
                                value={value}
                                onChange={onChange}
                                autoFocus={true}
                                placeholder="Email Address"
                                startIcon={<EmailOutlinedIcon />}
                            />
                        )}
                    />
                    {formState.errors.email && (
                        <ErrorMessage
                            msg={`${formState.errors.email.message}`}
                        />
                    )}
                </div>
                <BlackButton loading={sendOtpMutation.isPending} type="submit">
                    <div className="text-[14px]">
                        <span>{'Get your '} </span>
                        <span className="font-semibold">{'one time'} </span>
                        <span>{' password'} </span>
                    </div>
                </BlackButton>
                <div className="text-center text-[14px]">
                    <span>{"Don't have an account? "}</span>
                    <Link
                        className="text-my-blue font-bold"
                        to={ROUTES.SIGN_UP}
                    >
                        {'Signup here'}
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default EnterEmail;
