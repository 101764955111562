import { SavedFilter } from 'types/PropertyFilters';
import { UserInfo } from './Types';

const LOCAL_STORAGE_KEYS = {
    TOKEN: 'token',
    USER_INFO: 'userinfo',
    FLAGS: 'flags',
    REFRESH_TOKEN: 'refreshToken',
};

export const logoutUserAndClearLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_INFO);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FLAGS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    window.location.href = '/signin';
};

export const saveAccessAndRefreshTokenToLocalStorage = (
    accessToken: string,
    refreshToken: string,
) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
};

export const saveUserInfoToLocalStorage = (userInfo: UserInfo) =>
    localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_INFO,
        JSON.stringify(userInfo),
    );

export const getTokenFromLocalStorage = () =>
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

export const getUserInfoFromLocalStorage = (): UserInfo | null => {
    const userInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO);
    return userInfo ? JSON.parse(userInfo) : null;
};

export const getRefreshTokenFromLocalStorage = () =>
    localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

export const saveFiltersToLocalStorage = (filters: SavedFilter[]) =>
    localStorage.setItem('filterList', JSON.stringify(filters));

export const getFiltersFromLocalStorage = () =>
    JSON.parse(localStorage.getItem('filterList')!);
