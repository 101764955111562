import { LoadingButton } from '@mui/lab';
import { Divider } from '@mui/material';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';

interface EmailSectionProps {
    openChangeEmailModal: () => void;
    isPending: boolean;
}

const EmailSection = ({
    openChangeEmailModal,
    isPending,
}: EmailSectionProps) => {
    const user = getUserInfoFromLocalStorage()!;
    return (
        <div className="email">
            <div className="font-semibold text-[18px] mb-[14px]">Email</div>
            <div className="flex gap-[10px] flex-wrap items-center">
                <div className="rounded-[8px] bg-my-gray bg-opacity-20 border border-my-gray border-opacity-40 md:max-w-setting w-full p-[18px]">
                    <div className="text-black text-[16px] font-medium">
                        {user?.email}
                    </div>
                    <div className="text-my-black">
                        This email address is associated with your account
                    </div>
                </div>
                <LoadingButton
                    loading={isPending}
                    sx={{
                        textTransform: 'none',
                        '&:hover': { color: 'black' },
                    }}
                >
                    <div
                        className="font-medium cursor-pointer px-[10px] text"
                        onClick={openChangeEmailModal}
                    >
                        Change email address.
                    </div>
                </LoadingButton>
            </div>
            <Divider className="!my-8 !max-w-[722px]" />
        </div>
    );
};

export default EmailSection;
