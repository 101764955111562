import { useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnboardingStore } from 'store/useOnboardingStore';
import useUserStore from 'store/useUserStore';
import { ROUTES } from 'util/Constants';

const SubscriptionSuccess = () => {
    const { fetchUser } = useUserStore();
    const { currentStep, setCurrentStep } = useOnboardingStore();

    useQuery({
        queryKey: ['fetchUser'],
        queryFn: fetchUser,
    });
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => navigate(ROUTES.ONBOARDING), 2000);
    }, [navigate]);

    const hasIncremented = useRef(false);

    if (!hasIncremented.current) {
        setCurrentStep(currentStep + 1);
        hasIncremented.current = true;
    }

    return (
        <Fragment>
            <div>
                Successfully Created Subscription Plan! Wait a few sec, you will
                be redirected.
            </div>
        </Fragment>
    );
};

export default SubscriptionSuccess;
