import { create } from 'zustand';

interface UtilsState {
    topTabIndex: number;
    setTopTabIndex: (index: number) => void;
}

export const useUtilsStore = create<UtilsState>((set) => ({
    topTabIndex: 0,
    setTopTabIndex: (index) => set({ topTabIndex: index }),
}));
