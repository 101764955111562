interface DrawAreaButtonProps {
    title: string;
    className?: string;
    onClick?: () => void;
}

const DrawAreaButton = (props: DrawAreaButtonProps) => {
    const { title = '', className = '', onClick } = props;

    return (
        <div
            className={`relative flex cursor-pointer ${className || ''}`}
            onClick={onClick}
        >
            <div
                className="absolute top-0 left-0 rounded-[5px] opacity-20 w-full h-full z-0"
                style={{
                    background:
                        'linear-gradient(0deg, #3176BC 0%, #4EBBB8 100%)',
                }}
            ></div>
            <div className="flex items-center justify-center w-full gap-[5px] rounded-[5px] text-[#3176BC] text-[12px] m-[4px] z-1 px-[40px] py-[5px] bg-white border border-[#3176BC]">
                <img alt={'DRAW AREA'} src="/image/Draw-Area.png" />
                <span className="truncate py-[5px]">{title}</span>
            </div>
        </div>
    );
};

export default DrawAreaButton;
