import { SavedFilter } from 'types/PropertyFilters';
import { DEFAULT_FILTER } from 'util/Constants';
import {
    getFiltersFromLocalStorage,
    saveFiltersToLocalStorage,
} from 'util/localStorageHelper';
import { FilterType } from 'util/Types';
import { create } from 'zustand';

interface FilterStore {
    savedFilterList: SavedFilter[];
    currentFilter: FilterType;

    setCurrentFilter: (filter: FilterType) => void;

    addFilter: (filter: SavedFilter) => void;
    removeFilter: (idx: number) => void;
    updateFilter: (idx: number, filter: SavedFilter) => void;
}

export const useFiltersStore = create<FilterStore>((set, get) => ({
    get savedFilterList() {
        return getFiltersFromLocalStorage() || [];
    },
    currentFilter: DEFAULT_FILTER,

    setCurrentFilter: (filter) => set(() => ({ currentFilter: filter })),

    addFilter: (filter) => {
        const newFilterList = [...get().savedFilterList, filter];
        set(() => ({ savedFilterList: newFilterList }));
        saveFiltersToLocalStorage(newFilterList);
    },

    removeFilter: (idx) => {
        const newFilterList = [
            ...get().savedFilterList.slice(0, idx),
            ...get().savedFilterList.slice(idx + 1),
        ];
        set(() => ({ savedFilterList: newFilterList }));
        saveFiltersToLocalStorage(newFilterList);
    },

    updateFilter: (idx, filter) => {
        const newFilterList = get().savedFilterList;
        newFilterList[idx] = filter;
        set(() => ({ savedFilterList: newFilterList }));
        saveFiltersToLocalStorage(newFilterList);
    },
}));
