import styles from './OpenAppButton.module.css';
import { ReactComponent as OpenAppButtonSvg } from 'assets/svg/Button-Open-App.svg';

const OpenAppButton = () => {
    return (
        <div
            className={`relative h-[48px] rounded-[30px] bg-my-black text-white cursor-pointer ${styles['open-button']}`}
        >
            <div className="absolute top-0 left-0 h-full w-full flex items-center px-[5px] z-0">
                <div
                    className={`h-[40px] flex items-center rounded-full bg-white ${styles['arrow-container']}`}
                >
                    <div className={`pl-[10px]`}>
                        <OpenAppButtonSvg className={styles['arrow']} />
                    </div>
                </div>
            </div>

            <div className="relative h-full w-full flex items-center z-1">
                <div
                    className={`pl-[50px] pr-[10px] text-white font-semibold ${styles['title']}`}
                >
                    {'Open App'}
                </div>
            </div>
        </div>
    );
};

export default OpenAppButton;
