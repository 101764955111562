import { SelectCompetitorModal } from 'components/Modals';
import { createContext, ReactNode, useContext, useState } from 'react';

interface MainContextType {
    closeCompetitorModal: () => void;
    showSelectCompetitorModal: () => void;
}

const DefaultValue: MainContextType = {
    closeCompetitorModal: () => {},
    showSelectCompetitorModal: () => {},
};

export const MainContext = createContext<MainContextType>(DefaultValue);

const MainContextProvider = ({ children }: { children: ReactNode }) => {
    const [showSelectCopmetitorModalFlag, setShowSelectCopmetitorModalFlag] =
        useState<boolean>(false);

    const showSelectCompetitorModal = () =>
        setShowSelectCopmetitorModalFlag(true);
    const closeCompetitorModal = () => setShowSelectCopmetitorModalFlag(false);

    return (
        <MainContext.Provider
            value={{
                showSelectCompetitorModal,
                closeCompetitorModal,
            }}
        >
            {children}

            <SelectCompetitorModal
                visible={showSelectCopmetitorModalFlag}
                onClose={closeCompetitorModal}
            />
        </MainContext.Provider>
    );
};

export default MainContextProvider;

const useModals = () => useContext(MainContext);

export { useModals };
