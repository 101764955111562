import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { POST } from 'util/AppApi';
import { CompetitorPropertyResponseDTO, PropertyDTO } from 'util/Types';
import { isExceed } from 'util/getUserPlan';
import { create } from 'zustand';

interface PropertyState {
    getMapSearch: (
        _points: { lat: number; lng: number }[],
    ) => Promise<{ properties: PropertyDTO[]; total: number }>;
    getAISearch: (_query: string) => Promise<CompetitorPropertyResponseDTO>;
}

const usePropertyStore = create<PropertyState>((set) => ({
    getMapSearch: async (_points: { lat: number; lng: number }[]) => {
        const isExceedFlag = isExceed();
        if (isExceedFlag)
            return {
                properties: [],
                total: 0,
            };

        try {
            const result = await axiosInstance.post('/properties/map-search', {
                coordinates: _points,
            });

            const data: PropertyDTO[] = result.data.data.properties;
            const totalCount = result.data.data.totalCount;
            return {
                properties: data,
                total: totalCount,
            };
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    getAISearch: async (_query: string) => {
        const isExceedFlag = isExceed();
        if (isExceedFlag) return [];

        try {
            const { status, data: responseData } = await axiosInstance.post(
                '/properties/ai-search',
                { prompt: _query },
            );
            const { data } = responseData;
            return data;
        } catch {
            return [];
        }
    },
}));

export default usePropertyStore;
