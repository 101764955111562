import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ReactComponent as CountryLockSvg } from 'assets/svg/Country-Lock.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Region } from 'types/Region';
import DefaultDropDown from './DefaultDropDown';
import { GetCountryCode } from 'util/Funcs';
import useRegionStore from 'store/useRegionStore';
import { UpgradePlanModal } from 'components/Modals';
import { isPremiumUser } from 'util/getUserPlan';
interface CountryFilterProps {
    lock?: boolean;
    disabled?: boolean;
    placeholder?: string;
    selectedRegion?: Region | null;
    onChanged?: (_c: Region) => void;
}

const CountryFilter = (props: CountryFilterProps) => {
    const {
        lock = false,
        selectedRegion = null,
        onChanged = (c: Region) => {},
        disabled = false,
        placeholder = 'Country',
    } = props;

    const { regionList } = useRegionStore();
    const [showUpgradePlanModal, setShowUpgradePlanModal] =
        useState<boolean>(false);
    const childRef = useRef<{ closePopup: () => void }>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const onSearchTermChanged = (_txt: string) => {
        setSearchTerm(_txt);
    };

    const itemSelected = useCallback(
        (region: Region) => {
            onChanged(region);
            if (childRef.current) {
                childRef.current.closePopup();
            }
        },
        [onChanged],
    );

    const onClearClicked = useCallback(() => {
        onChanged({ name: '', cities: [], nonCities: [] });
    }, [onChanged]);

    const onShowModal = (region: Region) => {
        if (childRef.current) {
            childRef.current.closePopup();
        }
        if (!isPremiumUser()) setShowUpgradePlanModal(true);
    };
    return (
        <>
            <DefaultDropDown
                ref={childRef}
                Title={
                    <div
                        className={`${disabled ? 'text-my-gray' : ''} flex gap-[10px] items-center`}
                    >
                        {selectedRegion?.name && (
                            <ReactCountryFlag
                                svg
                                countryCode={GetCountryCode(
                                    selectedRegion.name,
                                )}
                                style={{ width: '1em', height: '1em' }}
                                title={selectedRegion?.name}
                            />
                        )}

                        <div className="truncate">
                            {selectedRegion?.name || placeholder}
                        </div>
                    </div>
                }
                IsEmpty={!selectedRegion?.name}
                onClearClicked={onClearClicked}
            >
                <div className="px-[10px] py-[5px]">
                    <div className="rounded-[5px] border border-my-gray border-opacity-60 flex items-center justify-between gap-[5px] px-[10px] py-[5px]">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) =>
                                onSearchTermChanged(e.target.value)
                            }
                            className="flex-grow focus:outline-none w-full"
                        />
                        <SearchOutlinedIcon />
                    </div>
                </div>
                <div className="max-h-[300px] overflow-y-scroll" aria-modal>
                    {regionList
                        .filter(
                            ({ name: country, cities, nonCities }) =>
                                country
                                    .toLowerCase()
                                    .includes(searchTerm.toLocaleLowerCase()) &&
                                (cities.length > 0 || nonCities.length > 0),
                        )
                        .map((region, index) => {
                            if (region.cities.length > 0 || !lock) {
                                return (
                                    <div
                                        className="truncate px-[10px] py-[6px] hover:bg-my-gray hover:bg-opacity-60 cursor-pointer flex gap-[10px] items-center"
                                        key={index}
                                        onClick={() => itemSelected(region)}
                                    >
                                        <ReactCountryFlag
                                            svg
                                            countryCode={GetCountryCode(
                                                region.name,
                                            )}
                                            style={{
                                                width: '1em',
                                                height: '1em',
                                            }}
                                            title={region.name}
                                        />
                                        <span>{region.name}</span>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className="flex justify-between items-center pr-[5px] cursor-pointer"
                                        key={index}
                                        onClick={() => onShowModal(region)}
                                    >
                                        <div className="truncate px-[10px] py-[6px] flex gap-[10px] items-center opacity-40">
                                            <ReactCountryFlag
                                                svg
                                                countryCode={GetCountryCode(
                                                    region.name,
                                                )}
                                                style={{
                                                    width: '1em',
                                                    height: '1em',
                                                }}
                                                title={region.name}
                                            />
                                            <span>{region.name}</span>
                                        </div>

                                        <CountryLockSvg />
                                    </div>
                                );
                            }
                        })}
                </div>
            </DefaultDropDown>

            <UpgradePlanModal
                visible={showUpgradePlanModal}
                onClose={() => setShowUpgradePlanModal(false)}
            />
        </>
    );
};

export default CountryFilter;
