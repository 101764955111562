import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as Loading2 } from 'assets/svg/loading-2.svg';
import BlurImage from 'components/BlurImage';
import Markdown from 'react-markdown';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertyStatisticsActionEnum } from 'types/PropertyStatistics';
import { PropertyDTO } from 'util/Types';
import ModalLayout, { DEFAULT_MODAL_Z_INDEX } from '../ModalLayout';
import DetailPane from './DetailPane';
import FeaturePane from './FeaturePane';
import HeaderPane from './HeaderPane';
import Skeleton from 'react-loading-skeleton';
interface PropertyDetailModalProps {
    property: PropertyDTO | null;
    visible: boolean;
    onClose: () => void;
    zIndex?: number;
}

const PropertyDetailModal = (props: PropertyDetailModalProps) => {
    const { savePropertyStatistics, propertyImages, fetchPropertyImages } =
        usePropertiesStore();
    const {
        property,
        visible,
        onClose,
        zIndex = DEFAULT_MODAL_Z_INDEX,
    } = props;

    let originUrl = '';

    if (property?.webPageUrl) {
        const originUrlStr = new URL(property?.webPageUrl || '');
        originUrl = `${originUrlStr.protocol}${originUrlStr.port ? `:${originUrlStr.port}` : ''}//${originUrlStr.hostname}`;
    }

    useQuery({
        queryKey: ['savePropertyStatistics', property?.webPageId],
        queryFn: () =>
            savePropertyStatistics({
                webPageId: property?.webPageId,
                action: PropertyStatisticsActionEnum.VIEW,
            }),
        enabled: () => !!property?.webPageId,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        staleTime: 0,
        retry: 1,
    });

    const { isLoading, data } = useQuery({
        queryKey: ['fetchPropertyImages', property?.webPageId],
        queryFn: () => fetchPropertyImages(property?.webPageId!),
        enabled: () => !!property?.webPageId,
        refetchOnWindowFocus: false,
        staleTime: 60 * 5 * 1000,
        retry: 1,
    });

    const onExplorerListingClicked = () => {
        savePropertyStatistics({
            webPageId: property?.webPageId,
            action: PropertyStatisticsActionEnum.CLICK,
        });
        window.open(property?.webPageUrl);
    };

    const Content = () => (
        <div className="xl:rounded-[10px] bg-white xl:w-[1260px] w-full overflow-hidden leading-[24px] ">
            {/* header containing back arrow button */}
            <div className="h-[50px] flex justify-end items-center px-[16px]">
                <div
                    className="w-[28px] h-[28px] rounded-[5px] flex items-center justify-center border border-my-gray cursor-pointer"
                    onClick={onClose}
                >
                    <CloseRoundedIcon />
                </div>
            </div>

            <div className="relative px-[16px] py-[32px] flex flex-col gap-[12px]">
                <div
                    className="absolute left-0 top-0 w-full h-[100px] z-[0]"
                    style={{
                        background: `url('/image/Property-Detail-Background.png') center center / cover no-repeat`,
                    }}
                ></div>
                {/* information */}
                <div className="z-[1]">
                    <HeaderPane
                        property={property}
                        originalLocation={data?.originalLocation}
                    />
                </div>
                {/* price and details */}
                <div className="flex items-center justify-between">
                    {/* defail information */}
                    <DetailPane
                        property={property}
                        onExplorerListingClicked={onExplorerListingClicked}
                        additionalDetails={data!}
                    />
                </div>

                <Divider className="w-full" />

                <div className="xl:max-h-[280px] lg:max-h-[400px] max-h-[500px] overflow-auto">
                    {property?.description || ''}
                </div>

                <Divider className="w-full" />

                <div className="text-my-blue font-semibold">Original Text</div>

                {isLoading ? (
                    <div className="w-full justify-center items-center">
                        <div className="space-y-2">
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="90%" />
                            <Skeleton height={20} width="75%" />
                            <Skeleton height={20} width="85%" />
                            <Skeleton height={20} width="75%" />
                            <Skeleton height={20} width="85%" />
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="80%" />
                            <Skeleton height={20} width="70%" />
                        </div>
                    </div>
                ) : (
                    <div className="xl:max-h-[280px] lg:max-h-[400px] max-h-[500px] overflow-auto">
                        <Markdown>{data?.draftText || ''}</Markdown>
                    </div>
                )}

                <FeaturePane property={property} />
                {!isLoading && propertyImages.length > 0 && (
                    <>
                        <div className="text-my-blue font-semibold">
                            Property Images
                        </div>
                        <div className="max-h-[200px] overflow-auto">
                            <div className="flex gap-[10px] flex-wrap py-[10px]">
                                {propertyImages.map(
                                    (imageUrl: string, index: number) => {
                                        let url = imageUrl;
                                        if (!imageUrl.includes('http')) {
                                            url = `${originUrl}/${imageUrl}`;
                                        }
                                        return (
                                            <BlurImage
                                                imgurl={url}
                                                key={index}
                                            />
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
    return (
        <ModalLayout
            visible={visible}
            onClose={onClose}
            showCloseIcon={false}
            zIndex={zIndex}
        >
            <div className="xl:block hidden">
                <Content />
            </div>

            <div className="xl:hidden block h-screen w-screen fixed overflow-auto top-0 left-0 bg-white">
                <Content />
                <div className="h-[48px]"></div>
                <div className="fixed bottom-0 left-0 w-screen bg-white flex items-center justify-end gap-[10px] h-[48px] border-t border-my-gray px-[16px] z-[3]">
                    <Button
                        variant="contained"
                        onClick={onExplorerListingClicked}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '5px',
                            height: '32px',
                            backgroundColor: '#4EBBBB',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#4EBBBB',
                            },
                        }}
                        className="truncate"
                    >
                        Explore Listing
                    </Button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default PropertyDetailModal;
