import BottomCard from './BottomCard';
import Layout from './Layout';

const AdvancedFilter = () => {
    return (
        <Layout
            title={'Advance filter'}
            Body={
                <div>
                    <img
                        alt={'Advanced Filter '}
                        src="/image/Landing/Landing-Feature-AdvancedFilter.png"
                        className="mx-auto"
                    />
                </div>
            }
            Bottom={
                <BottomCard
                    title={'Global property data at your fingertips'}
                    content={
                        'Search and save years worth of competitor listing data in one place to help you make the best decision.'
                    }
                />
            }
        />
    );
};

export default AdvancedFilter;
