import axios from 'axios';
import { UserInfo } from './Types';

const TOKEN_FIELD = 'token';
const USER_FIELD = 'userinfo';
const FLAG_FIELD = 'flags';

export const GetTokenFromStorage = () => localStorage.getItem(TOKEN_FIELD);
export const SaveTokenToStorage = (token: string) =>
  localStorage.setItem(TOKEN_FIELD, token);
export const RemoveTokenFromStorage = () =>
  localStorage.removeItem(TOKEN_FIELD);

export const GetUserFromStorage = () => {
  try {
    const result: UserInfo = JSON.parse(localStorage.getItem(USER_FIELD) || '');
    return result;
  } catch {}
  return null;
};
export const SaveUserToStorage = (userInfo: UserInfo) =>
  localStorage.setItem(USER_FIELD, JSON.stringify(userInfo));
export const RemoveUserFromStorage = () => localStorage.removeItem(USER_FIELD);

export interface Flags {
  selectCompetitorTooltip: boolean;
  addCompetitorTooltip: boolean;
}
const DEFAULT_FLAGS = {
  selectCompetitorTooltip: false,
  addCompetitorTooltip: false,
};
let flag: Flags = DEFAULT_FLAGS;

export const LoadInitialData = () => {
  try {
    flag = JSON.parse(localStorage.getItem(FLAG_FIELD) || '');
  } catch {
    flag = DEFAULT_FLAGS;
  }
};

export const InitAppdata = () => {
  localStorage.removeItem(FLAG_FIELD);
  localStorage.removeItem(USER_FIELD);
  localStorage.removeItem(TOKEN_FIELD);
};

export const SetFlag = (k: keyof Flags, v: boolean) => {
  flag = { ...flag, [k]: v };
  localStorage.setItem(FLAG_FIELD, JSON.stringify(flag));
};

export const GetFlag = (k: keyof Flags): boolean => {
  return flag[k];
};
export type ERROR_CODE_TYPE = 200 | 201 | 400 | 404 | 500 | 900;

export const ERROR_CODE = {
  CODE_SUCCESS: 200,
  CODE_CREATED: 201,
  CODE_ERROR: 400,
  CODE_NOT_FOUND: 404,
  CODE_SERVER_ERROR: 500,
  CODE_CANNOT: 900,
};

const PATCH = async (
  url: string,
  data: any,
  formdata: boolean = true,
): Promise<any> => {
  try {
    const token = GetTokenFromStorage();
    const config = {
      headers: {
        'Content-Type': formdata ? 'multipart/form-data' : 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios.patch(
      `${process.env.REACT_APP_BACKEND_HOST}${url}`,
      data,
      config,
    );
    return result;
  } catch (error: any) {
    console.log(
      'error while post reqeust to ',
      url,
      error.response.data.message,
    );
    return {
      status: ERROR_CODE.CODE_CANNOT,
      data: error.response.data.message,
    };
  }
};

const POST = async (
  url: string,
  data: any,
  auth: boolean = true,
): Promise<any> => {
  try {
    const token = GetTokenFromStorage();
    const config = auth
      ? {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      : {};
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}${url}`,
      data,
      config,
    );
    return result;
  } catch (error: any) {
    console.log(
      'error while post reqeust to ',
      url,
      error.response.data.message,
    );
    return {
      status: ERROR_CODE.CODE_CANNOT,
      data: error.response.data.message,
    };
  }
};

const GET = async (url: string, auth: boolean = true): Promise<any> => {
  try {
    const token = GetTokenFromStorage();
    const config = auth
      ? {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    return await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}${url}`,
      config,
    );
  } catch (error: any) {
    console.log(
      'error while get reqeust to ',
      url,
      error.response.data.message,
    );
    return {
      status: ERROR_CODE.CODE_CANNOT,
      data: error.response.data.message,
    };
  }
};

export { PATCH, POST, GET };
