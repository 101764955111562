import { yupResolver } from '@hookform/resolvers/yup';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage } from 'components';
import { useToast } from 'components/Providers/ToastProvider';
import { Fragment, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { signUpSchema } from 'schemas/signupValidationSchema';
import { useAuthStore } from 'store/useAuthStore';
import { AzureLocation } from 'types/Azure';
import { ROUTES } from 'util/Constants';
import { BlackButton, CustomOutlinedInput } from '..';
import LocationInput from './LocationInput';
import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

interface FormData {
    fullName: string;
    email: string;
}

const EnterInfo = () => {
    const [address, setAddress] = useState<string>('');
    const { showMessage } = useToast();
    const { signUp } = useAuthStore();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>({
        resolver: yupResolver(signUpSchema),
    });

    const { mutate, isPending } = useMutation({
        mutationFn: signUp,
        mutationKey: ['signUp'],
        onSuccess: () => {
            showMessage(
                'Sucsess',
                'Please check your email to verify your account',
            );
            reset();
            setAddress('');
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });

    const onSignUpClicked: SubmitHandler<FormData> = async ({
        email,
        fullName,
    }) => {
        if (!address) {
            showMessage('Error', 'Please select a location');
            return;
        }

        mutate({ email, fullName, address });
    };

    const onLocationClicked = (address: AzureLocation) => {
        setAddress(address.title);
    };

    const inputFields = [
        {
            name: 'fullName',
            placeholder: 'Your Full name',
            icon: <PersonOutlineOutlinedIcon />,
        },
        {
            name: 'email',
            placeholder: 'Email Address',
            icon: <EmailOutlinedIcon />,
        },
    ];

    return (
        <form
            className="rightpanel flex lg:items-center lg:justify-normal justify-center px-[16px] pt-[76px] lg:px-[48px] lg:pt-[0px]"
            onSubmit={handleSubmit(onSignUpClicked)}
        >
            <div className="flex flex-col max-w-[550px]">
                <div>
                    <div className="flex gap-[5px] items-center text-[18px] pb-[30px]">
                        <MainLogoSvg />
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <div className="text-black font-semibold text-[20px] leading-[21px]">
                            Create your new account
                        </div>
                        <div className="text-[#121212] text-[14px] leading-[24px] opacity-80">
                            Unleash your inner real estate mastermind! Join us
                            and dive into the world of competitive real estate
                            analysis.
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-[16px] py-[32px]">
                    {inputFields.map((field, index) => (
                        <Fragment key={field.name}>
                            <Controller
                                key={field.name}
                                name={field.name as keyof FormData}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <CustomOutlinedInput
                                        name={field.name}
                                        autoFocus={index === 0}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={field.placeholder}
                                        startIcon={field.icon}
                                    />
                                )}
                            />
                            {errors?.[field.name as keyof FormData] && (
                                <ErrorMessage
                                    msg={
                                        errors?.[field.name as keyof FormData]
                                            ?.message
                                    }
                                />
                            )}
                        </Fragment>
                    ))}

                    <div className="h-[50px] min-h-[50px]">
                        <LocationInput
                            value={address}
                            onLocationClicked={onLocationClicked}
                        />
                    </div>

                    <div className="h-[50px] min-h-[50px]">
                        <BlackButton
                            loading={isPending}
                            className="w-full"
                            type="submit"
                        >
                            Sign up
                        </BlackButton>
                    </div>
                </div>

                <div className="text-center text-[14px]">
                    <span>{'Already have an account? '}</span>
                    <Link
                        className="text-my-blue font-bold"
                        to={ROUTES.SIGN_IN}
                    >
                        Log in here
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default EnterInfo;
