import { getCode } from 'country-list';
import { ERROR_CODE } from 'util/AppApi';
import { ExtraCountryCodes, FAV_PREFIX } from './Constants';
import { FilterType } from './Types';

export const GetTwoLetterName = (name: string): string => {
    if (!name) return '??';

    const parts = name.split(' ');
    if (parts.length === 1) {
        const word1 = parts[0];
        return word1.length >= 2 ? word1.substring(0, 2) : `${word1[0]}?`;
    }

    return `${parts[0][0]}${parts[1][0]}`;
};

export const GetRandomColorClassName = (): string => {
    const randR = Math.random() * 255;
    const randG = Math.random() * 255;
    const randB = Math.random() * 255;

    return `rgb(${randR}, ${randG}, ${randB})`;
};

export const CheckTwoArrayIsSame = (
    arr1: string[],
    arr2: string[],
): boolean => {
    const compareFunc = (a: string, b: string) => {
        if (a === b) return 0;
        if (a > b) return 1;
        return -1;
    };

    if (
        Array.isArray(arr1) &&
        Array.isArray(arr2) &&
        arr2.length !== arr1.length
    )
        return false;

    //can not sort const items
    let ar1 = [...arr1];
    let ar2 = [...arr2];

    ar1 = ar1.sort(compareFunc);
    ar2 = ar2.sort(compareFunc);

    return JSON.stringify(ar1) === JSON.stringify(ar2);
};

//used when check if filter is empty - savefilter dialog
export const checkEmptyFilter = (key: keyof FilterType, value: any) => {
    switch (key) {
        case 'type':
        case 'listingType':
        case 'country':
        case 'city':
            return value !== '';
        case 'constructionArea':
        case 'plotArea':
        case 'price':
            return value.from !== 0 || value.to !== 0;
        case 'bedbath':
            return value.bedIdx + value.bathIdx !== 0;
        default:
            return false;
    }
};

export const getFilterCount = (filter: FilterType) => {
    const keys = Object.keys(filter || {});

    let count = 0;
    keys.forEach((key) => {
        const tKey: keyof FilterType = key as keyof FilterType;
        count += checkEmptyFilter(tKey, filter[tKey]) ? 1 : 0;
    });
    return count;
};

export const GetUniqueString = () => {
    return new Date().toISOString();
};

//used in competitor table, if diff is less than 1 day, it's new competitor
export const isNew = (dt: string | Date /**string or Date */) => {
    if (dt === '' || dt === undefined || dt === null) return true;

    let oldDate: Date = new Date();

    if (typeof dt === 'string') oldDate = new Date(dt);
    if (dt instanceof Date) oldDate = dt;

    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const dayOffset = (Date.now() - oldDate.getTime()) / DAY_IN_MS;
    // const dayOffset = (new Date('2024-04-24T22:01:59.250Z') - oldDate) / DAY_IN_MS;
    return dayOffset <= 1 && dayOffset >= 0;
};
//used when showing competitor table
export const convertDateIntoLocaleString = (
    dt: string | Date /** string */,
) => {
    if (typeof dt === 'string')
        return new Date(dt).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    else
        return dt.toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
};

export const IsValidResponse = (code: number) => {
    switch (code) {
        case ERROR_CODE.CODE_SUCCESS:
        case ERROR_CODE.CODE_CREATED: {
            //success - go to otp code page
            return true;
        }
        case ERROR_CODE.CODE_ERROR:
        case ERROR_CODE.CODE_NOT_FOUND:
        case ERROR_CODE.CODE_CANNOT: {
            //
            return false;
        }
        default:
            break;
    }
    return false;
};

export const formatNumber = (n: number) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const GetCountryCode = (countryTitle: string) => {
    return (
        getCode(countryTitle)! ||
        ExtraCountryCodes[countryTitle as keyof typeof ExtraCountryCodes]
    );
};

export const getDomainFavLink = (url: string) =>
    `${FAV_PREFIX}${new URL(url).hostname}`;
