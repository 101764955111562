import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider } from '@mui/material';
import { ReactComponent as MobileFilterSvg } from 'assets/svg/MobileFilter.svg';
import { ReactComponent as SaveFilterPreferenceSvg } from 'assets/svg/SaveFilterPreference.svg';
import { FilterSetPane } from 'components/Filter';
import { useRef, useState } from 'react';
import { useFiltersStore } from 'store/useFiltersStore';
import { DEFAULT_FILTER } from 'util/Constants';
import { getFilterCount } from 'util/Funcs';
import { FilterType } from 'util/Types';

const MobileFilterSection = ({
    onOpenSaveModal,
}: {
    onOpenSaveModal: () => void;
}) => {
    const resultCount = 1323;
    const [expanded, setExpanded] = useState<boolean>(false);
    const { currentFilter, setCurrentFilter } = useFiltersStore();
    const childRef = useRef<{
        getFilter: () => FilterType;
        clearFilter: () => void;
    }>(null);

    const onClearClicked = () => {
        if (childRef.current) childRef.current.clearFilter();
        setCurrentFilter(DEFAULT_FILTER);
    };

    const onApplyClicked = () => {
        const filter = childRef.current?.getFilter();
        if (filter) setCurrentFilter(filter);
        setExpanded(false);
    };

    return (
        <>
            <div
                className="rounded-[5px] h-[42px] border border-my-blue w-full px-[12px] text-my-blue flex justify-between items-center cursor-pointer"
                onClick={() => setExpanded(true)}
            >
                <span>{`Filter (${getFilterCount(currentFilter)})`}</span>
                <MobileFilterSvg />
            </div>
            <div className="flex gap-[10px] w-max pt-[10px] text-[12px]">
                {getFilterCount(currentFilter) > 0 && (
                    <div
                        className="flex items-center gap-[5px] cursor-pointer text-my-red"
                        onClick={onClearClicked}
                    >
                        <div
                            className={`rounded-full w-[20px] h-[20px] flex justify-center items-center cursor-pointer border border-my-red`}
                        >
                            <div className="leading-[25px] text-[25px]">
                                {'×'}
                            </div>
                        </div>
                        <div>{'Clear'}</div>
                    </div>
                )}
                <div
                    className="flex items-center gap-[5px] cursor-pointer text-my-brown"
                    onClick={onOpenSaveModal}
                >
                    <SaveFilterPreferenceSvg />
                    <span> {'Save Filter Preferences'} </span>
                </div>
            </div>
            {expanded && (
                <div className="fixed w-screen h-screen left-0 top-0 bg-white flex flex-col z-[10]">
                    <div className="p-[16px] h-[58px] flex justify-between items-center">
                        <div className="text-center">{`${resultCount} property result found`}</div>
                        <div onClick={() => setExpanded(false)}>
                            <CloseIcon />
                        </div>
                    </div>
                    <Divider className="w-full" />
                    <div className="p-[20px] flex-grow overflow-scroll flex flex-col gap-[10px]">
                        <FilterSetPane
                            value={currentFilter}
                            ref={childRef}
                            lock={true}
                        />
                    </div>
                    <Divider className="w-full" />
                    <div className="p-[16px] h-[67px] grid grid-cols-2 gap-[16px]">
                        <Button
                            onClick={onClearClicked}
                            variant="outlined"
                            color="error"
                        >
                            {'Clear'}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onApplyClicked}
                        >
                            {'Apply'}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default MobileFilterSection;
