import { Divider } from '@mui/material';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import { ReactComponent as DrawMapSearchSvg } from 'assets/svg/Main/Main-DrawMapSearch.svg';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { AzureMapModal } from 'components/Modals';
import { useModals } from 'pages/MainProvider';

const DrawMapPanel = () => {
    const { showSelectCompetitorModal } = useModals();
    const [showMapModal, setShowMapModal] = useState<boolean>(false);

    const onDrawMapButtonClicked = () => {
        setShowMapModal(true);
    };

    return (
        <div className="flex md:flex-row flex-col px-[16px] py-[10px] gap-[5px] rounded-[10px] bg-white font-medium text-[12px] border border-my-gray border-opacity-60 md:h-[66px] ">
            <div className="flex-grow flex h-[44px]">
                {/* temp removed */}
                <div className="md:w-fit w-full">
                    <LoadingButton
                        onClick={onDrawMapButtonClicked}
                        variant="outlined"
                        className={`!rounded-[5px] !h-full !px-[25px] !w-full`}
                        sx={{
                            color: '#3176BC',
                            textTransform: 'none',
                            backgroundColor: '#FFF',
                            fontSize: '12px',
                            gap: '10px',
                            '&:hover': { color: 'black' },
                        }}
                    >
                        <DrawMapSearchSvg />
                        <span>{'Draw map to Search'}</span>
                    </LoadingButton>
                </div>
            </div>

            <Divider orientation="vertical" />

            <div className="md:pl-[20px] h-[44px] md:w-fit w-full">
                <LoadingButton
                    onClick={showSelectCompetitorModal}
                    variant="outlined"
                    className={`!rounded-[5px] !h-full !px-[25px] !w-full`}
                    sx={{
                        color: '#FFF',
                        textTransform: 'none',
                        backgroundColor: '#3176BC',
                        fontSize: '12px',
                        gap: '10px',
                        '&:hover': { color: 'black' },
                    }}
                >
                    <PlusSvg />
                    <span>{'Add Competitor'}</span>
                </LoadingButton>
            </div>

            {showMapModal && (
                <AzureMapModal
                    visible={showMapModal}
                    onClose={() => setShowMapModal(false)}
                />
            )}
        </div>
    );
};

export default DrawMapPanel;
