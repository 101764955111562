import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "util/Constants";
import { useEffect } from "react";

const SubscriptionSuccess = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        setTimeout(() => navigate(ROUTES.ONBOARDING), 2000);
    }, []);

    return (
        <div>
            {'Failed to Creating Subscription Plan!'}
        </div>
    )
}

export default SubscriptionSuccess;