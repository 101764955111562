import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useOnboardingStore } from 'store/useOnboardingStore';
import { RegisterAccount } from 'util/Types';
import { CustomOutlinedInput } from '../../auth';

const CompanySize = ['1 - 10', '11 - 50', '51 - 100', '101+'];

const CopmanyAccountSection = ({
    onChange,
}: {
    onChange: (ac: RegisterAccount) => void;
}) => {
    const { account, setAccount } = useOnboardingStore();

    const handleOnChange = (_key: keyof RegisterAccount, v: string) => {
        const newAcc = { ...account, [_key]: v };
        setAccount(newAcc);
        onChange(newAcc);
    };

    return (
        <div className="flex flex-col gap-[16px] py-[16px]">
            <div className="font-semibold text-[16px]">{'Company Name'}</div>
            <CustomOutlinedInput
                name="website"
                startIcon={<SubtitlesOutlinedIcon />}
                placeholder="Your company Name"
                value={account.companyName}
                onChange={(e) => handleOnChange('companyName', e.target.value)}
                className="max-w-[450px]"
            />
            <div className="font-semibold text-[16px]">{'Company Size'}</div>
            <div className="flex w-fit">
                {CompanySize.map((item: string, index: number) => {
                    let roundedClass = '';
                    if (index === 0) roundedClass = 'rounded-l-[8px]';
                    if (index === CompanySize.length - 1)
                        roundedClass = 'rounded-r-[8px]';

                    return (
                        <div
                            className={`${item === account.companySize ? 'bg-my-gray' : ''} h-[56px] cursor-pointer hover:bg-my-gray px-[20px] flex items-center justify-center border border-my-gray ${roundedClass}`}
                            key={index}
                            onClick={() => handleOnChange('companySize', item)}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>

            <div className="font-semibold text-[16px]">
                {'Your real estate website link (optional)'}
            </div>
            <div className="leading-[24px]">
                {
                    'Sharing your real estate website link would help us suggest the best competitors that match your needs '
                }
            </div>
            <div>
                <CustomOutlinedInput
                    name="website"
                    startIcon={<LanguageOutlinedIcon />}
                    placeholder="https://www.example.com"
                    value={account.websiteUrl}
                    onChange={(e) =>
                        handleOnChange('websiteUrl', e.target.value)
                    }
                    className="max-w-[450px]"
                />
            </div>

            <div className="font-semibold text-[16px] leading-[21px]">
                {'Where did you hear about us?'}
            </div>
            <FormControl>
                <Select
                    value={account.referralSource}
                    onChange={(e) =>
                        handleOnChange('referralSource', e.target.value)
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                        return selected;
                    }}
                    className="max-w-[250px] w-full h-[48px] rounded-[8px] border-[#DFE1E1] text-[14px] leading-6 px-1"
                >
                    <MenuItem value={'Google'} className="text-[14px]">
                        Google
                    </MenuItem>
                    <MenuItem value={'LinkedIn'} className="text-[14px]">
                        LinkedIn
                    </MenuItem>
                    <MenuItem value={'Events'} className="text-[14px]">
                        Events
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default CopmanyAccountSection;
