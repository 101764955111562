import useCompetitorsStore from 'store/useCompetitorsStore';
import { MAX_LIMIT } from 'util/Constants';
import { getUserPlan, isExceed } from 'util/getUserPlan';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';
import { ReactComponent as CompetitorDatabaseSvg } from 'assets/svg/Competitor-Database.svg';
import { ReactComponent as CompetitorSelectedSvg } from 'assets/svg/Competitor-Selected.svg';
import { ReactComponent as CompetitorSwappedSvg } from 'assets/svg/Competitor-Swapped.svg';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const HeaderSection = () => {
    const isExceedFlag = isExceed();
    const plan = getUserPlan();

    const { totalCount, selectedCompetitors } = useCompetitorsStore();

    return (
        <div className="grid md:grid-cols-3 grid-cols-1 md:gap-[16px] gap-[10px] px-[20px] py-[8px] bg-my-gray bg-opacity-40">
            <div className="rounded-[5px] h-[48px] flex justify-between py-[10px] px-[12px] bg-white border border-my-gray">
                <div>
                    <div className="text-[10px]">{'Competitor Database'}</div>
                    <div className="font-medium">{totalCount}</div>
                </div>
                <CompetitorDatabaseSvg />
            </div>

            <div
                className={`rounded-[5px] h-[48px] flex justify-between gap-[12px] py-[10px] px-[12px] ${isExceedFlag ? 'bg-[#FFC034] bg-opacity-10 border-[#FFC034] border-opacity-40' : 'bg-white border-my-gray'} border`}
            >
                <div
                    className={`flex-grow ${isExceedFlag ? 'text-[#FFC034]' : ''}`}
                >
                    <div className="text-[10px] text-black">
                        {'Total Selected'}
                    </div>

                    <div className="flex items-center">
                        <div className="font-medium flex-grow">
                            {`${selectedCompetitors.length} / `}
                            {plan.maxTrackUpCount === MAX_LIMIT
                                ? 'Unlimited'
                                : `${plan?.maxTrackUpCount}`}
                        </div>
                        {isExceedFlag && (
                            <div className="text-[#ECA200] text-[10px] flex gap-[2px] items-center">
                                <WarningRoundedIcon className="!text-[16px]" />
                                <span>Exceed limit!</span>
                            </div>
                        )}
                    </div>
                </div>
                <CompetitorSelectedSvg />
            </div>

            <div className="rounded-[5px] h-[48px] flex justify-between py-[10px] px-[12px] bg-white border border-my-gray">
                <div>
                    <div className="text-[10px]">
                        {'Can be swapped this month'}
                    </div>
                    <div className="font-medium">
                        {plan.maxSwapCount === MAX_LIMIT
                            ? 'Unlimited'
                            : plan.maxSwapCount}{' '}
                        /
                        {plan.maxSwapCount === MAX_LIMIT
                            ? 'Unlimited'
                            : plan.maxTrackUpCount}
                    </div>
                </div>
                <CompetitorSwappedSvg />
            </div>
        </div>
    );
};

export default HeaderSection;
