import ModalLayout from 'components/Modals/ModalLayout';
import { useToast } from 'components/Providers/ToastProvider';
import SelectCompetitorPanel from 'components/SelectCompetitor';
import { useEffect } from 'react';
import { getUserPlan, isExceed } from 'util/getUserPlan';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';

const SelectCompetitorModal = ({
    visible,
    onClose,
}: {
    visible: boolean;
    onClose: () => void;
}) => {
    const { showMessage } = useToast();
    const isExceedFlag = isExceed();
    const currentPlan = getUserPlan();
    const user = getUserInfoFromLocalStorage();

    useEffect(() => {
        if (
            isExceedFlag &&
            visible &&
            user?.savedCompetitors.length &&
            user.savedCompetitors.length > 0
        )
            showMessage(
                `Alert`,
                `You're currently tracking ${user.savedCompetitors.length} competitors over your limit. Please adjust your selection.`,
            );
    }, [visible]);

    return (
        <>
            <ModalLayout visible={visible} onClose={onClose}>
                <div
                    className={
                        'bg-white pt-[40px] rounded-[10px] lg:max-w-[1200px] md:block hidden'
                    }
                >
                    <SelectCompetitorPanel />
                </div>

                <div className="md:hidden h-screen w-screen bg-white fixed left-0 top-0 overflow-scroll pt-[30px]">
                    <div
                        className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]"
                        onClick={onClose}
                    >
                        {'✖'}
                    </div>
                    <SelectCompetitorPanel />
                </div>
            </ModalLayout>
        </>
    );
};

export default SelectCompetitorModal;
