import { ReactComponent as ManageFilterSvg } from 'assets/svg/ManageFilter.svg';
import { ReactComponent as SavedFilterSvg } from 'assets/svg/SavedFilter.svg';
import DefaultDropDown from 'components/Filter/DefaultDropDown';
import ManageFilterModal from 'components/Modals/ManageFilter';
import { useFilterLogic } from 'hooks/useFilterLogic';
import { useRef, useState } from 'react';
import { SavedFilter } from 'types/PropertyFilters';
import { DEFAULT_FILTER } from 'util/Constants';

const MobileFilterPanel = ({ onManage }: { onManage: () => void }) => {
    const childRef = useRef<{ closePopup: () => void }>(null);

    const { currentIndex, savedFilterList, onFilterClicked, setCurrentFilter } =
        useFilterLogic(childRef);

    return (
        <div className="px-[16px] py-[10px] border-t border-b border-my-gray flex justify-between text-[12px]">
            <div className="h-[40px]">
                <DefaultDropDown
                    ref={childRef}
                    Title={
                        <div className="flex gap-[5px] items-center min-w-[100px]">
                            <SavedFilterSvg />
                            <div className="max-w-[140px] truncate">
                                {currentIndex >= 0
                                    ? `Saved (${savedFilterList[currentIndex].title})`
                                    : 'Saved'}
                            </div>
                        </div>
                    }
                    IsEmpty={currentIndex < 0}
                    onClearClicked={() => setCurrentFilter(DEFAULT_FILTER)}
                >
                    <div className="max-h-[300px] p-[10px] min-h-[50px]">
                        <div className="flex flex-col gap-[10px] text-black">
                            {savedFilterList.map(
                                (filter: SavedFilter, index: number) => (
                                    <div
                                        className={`cursor-pointer rounded-[10px] h-[36px] max-w-[200px] truncate font-medium px-[5px] flex items-center border border-opacity-60 ${currentIndex === index ? 'border-my-green text-my-green' : 'border-my-gray '}`}
                                        key={index}
                                        onClick={() => onFilterClicked(filter)}
                                    >
                                        {filter.title}
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                </DefaultDropDown>
            </div>

            <div
                className="text-my-blue flex gap-[5px] items-center px-[10px] cursor-pointer"
                onClick={onManage}
            >
                <ManageFilterSvg />
                <span>Manage</span>
            </div>
        </div>
    );
};

const LgFilterPanel = ({ onManage }: { onManage: () => void }) => {
    const { currentIndex, savedFilterList, onFilterClicked } = useFilterLogic();

    return (
        <div className="min-h-[48px] border-y border-my-gray border-opacity-60 justify-between flex">
            <div className="flex gap-[5px] items-center bg-my-gray bg-opacity-40 px-[20px] border-r border-my-gray">
                <SavedFilterSvg />
                <span>Saved</span>
            </div>
            <div className="flex-grow flex gap-[10px] flex-wrap text-black items-center p-[10px]">
                {savedFilterList.map((filter: SavedFilter, index: number) => (
                    <div
                        className={`cursor-pointer rounded-[10px] h-[36px] font-medium px-[5px] flex items-center border border-opacity-60 ${currentIndex === index ? 'border-my-green text-my-green' : 'border-my-gray '}`}
                        key={index}
                        onClick={() => onFilterClicked(filter)}
                    >
                        <div className="max-w-[200px] truncate">
                            {filter.title}
                        </div>
                    </div>
                ))}
            </div>
            <div
                className="text-my-blue flex gap-[5px] items-center px-[10px] cursor-pointer border-l border-my-gray"
                onClick={onManage}
            >
                <ManageFilterSvg />
                <span>Manage</span>
            </div>
        </div>
    );
};

const SavedFilterPanel = () => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const onOpenManageFilterModal = () => {
        setExpanded(true);
    };

    return (
        <>
            <div className="md:block hidden">
                <LgFilterPanel onManage={onOpenManageFilterModal} />
            </div>

            <div className="md:hidden ">
                <MobileFilterPanel onManage={onOpenManageFilterModal} />
            </div>

            <ManageFilterModal
                visible={expanded}
                onClose={() => setExpanded(false)}
            />
        </>
    );
};

export default SavedFilterPanel;
