import React, { useState, useEffect } from 'react';

interface NumberAnimationProps {
    start: number;
    end: number;
    duration?: number; // in milliseconds
    suffix?: string;
    startanimate: boolean;
}

const NumberAnimation: React.FC<NumberAnimationProps> = ({
    start,
    end,
    startanimate,
    duration = 2000,
    suffix = '',
}) => {
    const [number, setNumber] = useState<number>(start);

    useEffect(() => {
        const startTime = performance.now();

        const animate = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1); // Ensure progress doesn't exceed 1
            const currentNumber = Math.floor(progress * (end - start) + start); // Calculate the current number

            setNumber(currentNumber);

            if (progress < 1) {
                requestAnimationFrame(animate); // Continue animation until done
            }
        };

        if (startanimate) requestAnimationFrame(animate); // Start animation
    }, [start, end, duration, startanimate]);

    return (
        <>
            {number}
            {suffix}
        </>
    );
};

export default NumberAnimation;
