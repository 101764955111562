import BottomCard from './BottomCard';
import Layout from './Layout';

const EnrichedProspectData = () => {
    return (
        <Layout
            title={'Enriched prospect data'}
            Body={
                <div className="mr-[-24px] overflow-hidden h-full my-[20px] ml-[20px]">
                    <img
                        alt={'Enriched Prospect '}
                        src="/image/Landing/Landing-Feature-Enriched.png"
                        className="w-full mx-auto"
                    />
                </div>
            }
            Bottom={
                <BottomCard
                    title={'Access to up-to-date property listings'}
                    content={
                        'Uncover the details! Explore features like bedrooms, plot area, amenities, and more.'
                    }
                />
            }
        />
    );
};

export default EnrichedProspectData;
