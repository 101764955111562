import axios, { AxiosError } from 'axios';
import {
  getRefreshTokenFromLocalStorage,
  getTokenFromLocalStorage,
  logoutUserAndClearLocalStorage,
  saveAccessAndRefreshTokenToLocalStorage,
} from 'util/localStorageHelper';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_HOST}`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage();
    if (!config.headers['SKIP-AUTH'] && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    delete config.headers['SKIP-AUTH'];
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (typeof error.response === 'undefined') {
      return Promise.reject(
        'A network error occurred. Please check your internet connection.',
      );
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshTkn = getRefreshTokenFromLocalStorage();
        const { data } = await axiosInstance.post('/auth/refresh', {
          refreshToken: refreshTkn,
        });
        const { accessToken, refreshToken } = data;
        saveAccessAndRefreshTokenToLocalStorage(accessToken, refreshToken!);

        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        if ((refreshError as AxiosError)?.response?.status === 403) {
          logoutUserAndClearLocalStorage();
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
