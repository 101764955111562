import usePropertiesStore from 'store/usePropertiesStore';

const ExportPanel = () => {
    const { totalPropertiesCount } = usePropertiesStore();

    return (
        <div className="flex justify-between items-center gap-[10px]">
            <div className="text-my-blue font-semibold text-[16px]">{`${totalPropertiesCount} property result found`}</div>

            <div className="flex gap-[10px] items-center justify-between"></div>
        </div>
    );
};

export default ExportPanel;
