import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useUserStore from 'store/useUserStore';

type UploadUserProfileImageOptions = {
    onSuccess?: () => void;
    successMessage?: string;
};

const useUploadUserProfileImage = ({
    onSuccess,
    successMessage = 'Successfully Changed Avatar.',
}: UploadUserProfileImageOptions) => {
    const { showMessage } = useToast();
    const { uploadUserProfileImage } = useUserStore();

    return useMutation({
        mutationFn: uploadUserProfileImage,
        mutationKey: ['uploadUserProfileImage'],
        onSuccess: () => {
            showMessage('Success', successMessage!);
            if (onSuccess) onSuccess();
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });
};

export default useUploadUserProfileImage;
