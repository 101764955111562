import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useRef,
    useEffect,
} from 'react';

interface TooltipContextType {
    tooltip: ReactNode | null;
    showTooltip: (content: ReactNode) => void;
    hideTooltip: () => void;
}

const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

export const useTooltip = (): TooltipContextType => {
    const context = useContext(TooltipContext);
    if (!context) {
        throw new Error('useTooltip must be used within a TooltipProvider');
    }
    return context;
};

export const TooltipProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [tooltip, setTooltip] = useState<ReactNode | null>(null);

    const showTooltip = (content: ReactNode) => {
        setExpanded(true);
        setTooltip(content);
    };

    const hideTooltip = () => {
        setExpanded(false);
        setTooltip(null);
    };

    useEffect(() => {
        document.body.style['overflow'] = expanded ? 'hidden' : 'auto';
    }, [expanded]);

    return (
        <TooltipContext.Provider value={{ tooltip, showTooltip, hideTooltip }}>
            {children}
            {expanded && (
                <div className="fixed w-screen h-screen z-[100] left-0 top-0 bg-my-black bg-opacity-30">
                    <div className="relative w-full h-full">{tooltip}</div>
                </div>
            )}
        </TooltipContext.Provider>
    );
};
