import useOtpStore from 'store/useOtpStore';
import { HeaderTitle } from '..';
import EnterEmail from './EnterEmail';
import EnterOtp from './EnterOtp';

const SignInPage = () => {
    const { email } = useOtpStore();

    return (
        <>
            <div className="h-screen w-screen md:flex lg:items-center md:justify-center">
                <div
                    className={`flex flex-col gap-[40px] md:w-[760px] md:px-[30px] px-[18px] pt-[70px] lg:pt-0`}
                >
                    <HeaderTitle />
                    {email ? <EnterOtp /> : <EnterEmail />}
                </div>
            </div>
        </>
    );
};

export default SignInPage;
