import DoneIcon from '@mui/icons-material/Done';
import { Divider } from '@mui/material';
import ButtonWithIcon from 'components/ButtonWithIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { getUserInfoFromLocalStorage } from 'util/localStorageHelper';
import { AccountFormData } from './Account';

interface FullNameSectionProps {
    register: UseFormRegister<AccountFormData>;
    isSubmitting: boolean;
    watchFullName: string;
    watchImage: File | null;
}

const FullNameSection: React.FC<FullNameSectionProps> = ({
    register,
    isSubmitting,

    watchFullName,
    watchImage,
}) => {
    const user = getUserInfoFromLocalStorage()!;
    return (
        <>
            <div className="font-semibold text-[18px] mb-[14px]">Full Name</div>
            <input
                type="text"
                placeholder="John Doe"
                className="border border-my-gray rounded-[8px] h-[42px] px-[20px] md:w-[342px] w-full"
                {...register('fullName')}
            />
            <div className="mt-[20px]">
                {isSubmitting ? (
                    <LoadingSpinner loading={isSubmitting} />
                ) : (
                    <ButtonWithIcon
                        text="Save Changes"
                        disabled={
                            user.fullName === watchFullName && !watchImage
                        }
                        className={`!rounded-[8px] !h-[42px] ${
                            user.fullName === watchFullName && !watchImage
                                ? '!bg-my-gray'
                                : '!bg-my-black'
                        }`}
                        icon={<DoneIcon />}
                    />
                )}
            </div>
            <Divider className="!my-8 !max-w-setting" />
        </>
    );
};

export default FullNameSection;
