import { GradientButton } from 'components';

const AIPowerSearchSection = () => {
    return (
        <div>
            <div className="lg:px-[100px] md:px-[32px] px-[12px] lg:max-w-hello mx-auto">
                <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:gap-[90px] gap-[72px]">
                    <div className="flex items-center justify-center lg:hidden">
                        <img
                            alt={'AI Search Button '}
                            src="/image/Landing/Landing-AI-Search-Button.png"
                        />
                    </div>

                    <div>
                        {/* button */}
                        <div className="flex pb-[20px]">
                            <GradientButton>
                                <div className="flex items-center justify-between rounded-[5px] h-[30px] px-[15px] gap-[10px]">
                                    <img
                                        alt={'AI Search Icon'}
                                        src="/image/Landing/Landing-Ai-Powered-Search-Icon.png"
                                        className="w-[14px] h-[14px]"
                                    />
                                    <span>{`Ai-powered Search`}</span>
                                </div>
                            </GradientButton>
                        </div>

                        <div className="flex flex-col gap-[42px] text-my-black ">
                            <div>
                                <div className="font-extrabold md:text-[36px] text-[28px] font-nunito">
                                    {
                                        'Intelligent Search Right at Your Fingertips'
                                    }
                                </div>
                                <div className="text-[14px] opacity-80 leading-[28px]">
                                    {
                                        "Hello:data isn't just about competitor analysis. Find your dream property from any competitor, without searching for hours."
                                    }
                                </div>
                            </div>

                            <div className="">
                                <div className="font-semibold text-[16px]">
                                    {'Search Everything'}
                                </div>
                                <div className="text-opacity-80 leading-[28px]">
                                    {
                                        "You can find any competitor's listing simply by typing what you are looking for. hello:data will provide the best possible combination of results based on your request."
                                    }
                                </div>
                            </div>

                            <div className="">
                                <div className="font-semibold text-[16px]">
                                    {'Draw on map'}
                                </div>
                                <div className="text-opacity-80 leading-[28px]">
                                    {
                                        "Easily discover competitor properties in your desired location! Simply draw a search area on the map, and we'll pinpoint all relevant listings within that zone."
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:flex hidden items-center justify-center">
                        <img
                            alt={'AI Search Button '}
                            src="/image/Landing/Landing-AI-Search-Button.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIPowerSearchSection;
