import { ReactNode } from 'react';
import MainHedaer from './MainHeader';
import MainContextProvider from './MainProvider';

const MainLayout = ({ children }: { children: ReactNode }) => {
    return (
        <MainContextProvider>
            <div className="relative">
                {/* Mainheader height is 72 */}
                <div className="relative z-[1]">
                    <MainHedaer />
                    {children}
                </div>
            </div>
        </MainContextProvider>
    );
};

export default MainLayout;
