import { MenuItem, Select } from "@mui/material";
import { useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { GetUserFromStorage, POST } from "util/AppApi";
import { useToast } from "components/Providers/ToastProvider";
import { IsValidResponse } from "util/Funcs";

interface PreferenceDTO {
    language: 'English',    //language
    currency: 'EUR' | 'USD' | 'GBP' | 'AUD' | 'CAD',
    notificationListings: "Daily" | "Weekly" | "Monthly" //notificationListings
}

const langCandidates: PreferenceDTO['language'][] = ['English'];
const currencyCandidates: PreferenceDTO['currency'][] = ['EUR', 'USD', 'GBP', 'AUD', 'CAD'];
const emailPeriodCandidates: PreferenceDTO['notificationListings'][] = ['Daily', 'Weekly', 'Monthly'];

const langValues: { t: PreferenceDTO['language'], v: string }[] = [
    { t: "English", v: 'en' },
]

const periodValues: { t: PreferenceDTO['notificationListings'], v: string }[] = [
    { t: "Daily", v: 'day' },
    { t: "Weekly", v: 'week' },
    { t: "Monthly", v: 'month' },
]

const defaultPreference: PreferenceDTO = {
    language: "English",
    currency: 'EUR',
    notificationListings: 'Daily'
}

const CustomMenuItem = ({ v }: { v: PreferenceDTO['currency'] }) => {
    return (
        <div className="flex items-center gap-[10px]">
            <ReactCountryFlag
                svg
                countryCode={v.slice(0, 2)}
                style={{ width: "2em", height: "2em" }}
            />
            <span> {v} </span>
        </div>
    )
}


const PreferenceTab = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const user = GetUserFromStorage();
    // const { userToken, user } = useSelector((state: RootState) => state.auth);
    const [preferenceData, setPreferenceData] = useState<PreferenceDTO>(defaultPreference);
    const { showMessage } = useToast();

    const handleOnChange = (k: keyof PreferenceDTO, v: string) => {
        const newData = { ...preferenceData, [k]: v };
        setPreferenceData(newData);

        handleSaveChange(newData);
    }

    const handleSaveChange = async (_data: PreferenceDTO) => {
        const newUserInfo = {
            ...user,
            notificationListings: periodValues.filter(_v => _v.t === _data.notificationListings)[0].v,
            language: _data.language.slice(0, 2).toLowerCase(),
            currency: _data.currency
        }

        setLoading(true);
        const { status } = await POST('/user/update-user', newUserInfo);
        if (!IsValidResponse(status))
            showMessage('Failed', 'Can not update your info.');

        setLoading(false);
    }

    return (
        <div className="flex flex-col gap-[15px] md:w-[722px]">
            <div className="flex justify-between items-center">
                <div className="font-medium leading-[24px]">Language</div>
                <Select
                    value={preferenceData.language}
                    onChange={(e) => handleOnChange("language", e.target.value)}
                    renderValue={(selected) => selected}
                    className="!w-[120px] h-[36px] !text-[12px]"
                >
                    {
                        langCandidates.map((v: PreferenceDTO['language'], index: number) => <MenuItem key={index} value={v} className="!text-[12px]">{v}</MenuItem>)
                    }
                </Select>
            </div>

            <div className="flex justify-between items-center">
                <div className="font-medium leading-[24px]">Currency</div>
                <Select
                    value={preferenceData.currency}
                    onChange={(e) => {
                        handleOnChange("currency", e.target.value);
                    }}
                    renderValue={(selected) => <CustomMenuItem v={selected} />}
                    className="!w-[120px] h-[36px] !text-[12px]"
                >
                    {
                        currencyCandidates.map((v: PreferenceDTO['currency'], index: number) => {
                            return (
                                <MenuItem value={v} className="!text-[12px]" key={index} >
                                    <CustomMenuItem v={v} />
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </div>

            <div className="flex justify-between items-center">
                <div className="font-medium leading-[24px]">New Listing Email</div>
                <Select
                    value={preferenceData.notificationListings}
                    onChange={(e) => {
                        handleOnChange("notificationListings", e.target.value);
                    }}
                    renderValue={(selected) => selected}
                    className="!w-[120px] h-[36px] !text-[12px]"
                >
                    {
                        emailPeriodCandidates.map((v: PreferenceDTO['notificationListings'], index: number) => <MenuItem key={index} value={v} className="!text-[12px]">{v}</MenuItem>)
                    }
                </Select>
            </div>
        </div>
    )
}

export default PreferenceTab;