import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

interface BlackButtonProps {
  children: ReactNode;
  loading?: boolean;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const BlackButton = ({
  children,
  loading = false,
  onClick = () => {},
  className = '',
  type = 'button',
}: BlackButtonProps) => {
  return (
    <LoadingButton
      onClick={onClick}
      variant='outlined'
      className={`!rounded-[8px] h-[50px] !border-my-gray ${className}`}
      loading={loading}
      loadingIndicator={<CircularProgress color='primary' size={24} />}
      sx={{
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#252525',
        '&:hover': {
          color: 'black',
        },
      }}
      type={type}
    >
      {children}
    </LoadingButton>
  );
};

export default BlackButton;
