import BottomCard from './BottomCard';
import Layout from './Layout';

const ExportDoc = () => {
    return (
        <Layout
            title={'Export as document'}
            Body={
                <div>
                    <img
                        alt={'Export Doc '}
                        src="/image/Landing/Landing-Feature-ExportDoc.png"
                        className="mx-auto"
                    />
                </div>
            }
            Bottom={
                <BottomCard
                    title={'Leverage the data to an even greater extent'}
                    content={
                        'Export all competitor data or favorite listings with just one click.'
                    }
                />
            }
        />
    );
};

export default ExportDoc;
