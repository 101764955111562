// src/theme.ts
import { createTheme } from '@mui/material/styles';

const DefaultMUITheme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'Poppins',
  },
});

export default DefaultMUITheme;
