import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface BeginPointMarkerProps {
    isDrawing: boolean;
    onClick?: () => void;
}

const BeginPointMaker = (props: BeginPointMarkerProps) => {
    const { isDrawing, onClick } = props;

    return (
        <div
            className={`w-[30px] h-[30px] rounded-full ${isDrawing ? 'bg-my-blue bg-opacity-80' : 'bg-my-black'} text-white flex items-center justify-center !text-[14px] translate-y-[50%]`}
            onClick={onClick}
        >
            {!isDrawing && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
        </div>
    );
};

export default BeginPointMaker;
