import { PropertyDTO } from 'util/Types';
import { ReactComponent as AddressDotSvg } from 'assets/svg/address-dot.svg';
import { ReactComponent as HelpIcon } from 'assets/svg/HelpIcon.svg';
import { useRef, useState } from 'react';
import { Menu } from '@mui/material';
import OriginalLocationToolTip from '../OriginalLocation';

const HeaderPane = ({
    property,
    originalLocation,
}: {
    property: PropertyDTO | null;
    originalLocation: any;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expandMobileHeader: boolean = Boolean(anchorEl);

    if (!property) return null;

    const onClickExpand = () => {
        if (ref) setAnchorEl(ref.current);
    };

    const onClose = () => setAnchorEl(null);

    return (
        <div
            className="py-[28px] px-[16px] rounded-[10px] bg-white mx-auto"
            style={{
                boxShadow: '0px 12px 20px 12px rgba(109, 141, 173, 0.08)',
            }}
        >
            <div className="flex flex-col gap-[12px]">
                <div className="font-semibold text-[24px] leading-normal">
                    {property.title}
                </div>

                <div className="flex items-center flex-wrap gap-[10px]">
                    <div className="text-my-blue font-semibold">
                        {property.details.propertyType}
                    </div>
                    {property?.address.Country && (
                        <div className="flex items-center gap-[10px]">
                            <AddressDotSvg className="text-my-blue" />
                            <span>{property?.address.Country}</span>
                        </div>
                    )}
                    {property?.address.Neighborhood && (
                        <div className="flex items-center gap-[10px]">
                            <AddressDotSvg className="text-my-blue" />
                            <span>{property?.address.Neighborhood}</span>
                        </div>
                    )}
                    {property?.address.City && (
                        <div className="flex items-center gap-[10px]">
                            <AddressDotSvg className="text-my-blue" />
                            <span>{property?.address.City}</span>
                        </div>
                    )}
                    {property?.address.State && (
                        <div className="flex items-center gap-[10px]">
                            <AddressDotSvg className="text-my-blue" />
                            <div>{property?.address.State || ''}</div>
                        </div>
                    )}
                    <div
                        className="flex items-center gap-[10px] cursor-pointer"
                        ref={ref}
                        onClick={onClickExpand}
                    >
                        <HelpIcon />
                    </div>
                </div>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={expandMobileHeader}
                onClose={onClose}
                sx={{
                    '& .MuiPaper-root': {
                        background: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <OriginalLocationToolTip
                    originalLocation={originalLocation}
                    onClose={onClose}
                />
            </Menu>
        </div>
    );
};

export default HeaderPane;
