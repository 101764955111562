const StatCard = ({ label, value }: { label: string; value: number }) => {
    return (
        <div className="rounded-[10px] border border-my-gray border-opacity-60 bg-my-gray bg-opacity-20">
            <div className="font-semibold px-[20px] py-[10px] h-full flex flex-col justify-center">
                <div className="text-[10px]">{label}</div>
                <div className="text-[20px]">{value}</div>
            </div>
        </div>
    );
};

export default StatCard;
