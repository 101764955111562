import { ReactComponent as FacebookSvg } from 'assets/svg/Social-Facebook.svg';
import { ReactComponent as TiktokSvg } from 'assets/svg/Social-Instagram.svg';
import { ReactComponent as LinkedInSvg } from 'assets/svg/Social-LinkedIn.svg';
import { ReactComponent as PSvg } from 'assets/svg/Social-P.svg';
import { ReactComponent as TwitterSvg } from 'assets/svg/Social-X.svg';
import { ReactComponent as JSvg } from 'assets/svg/Social-J.svg';
import { ReactComponent as YoutubSvg } from 'assets/svg/Social-Youtube.svg';
import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

import { ROUTES } from 'util/Constants';

const FooterSection = () => {
    return (
        <div className="lg:px-[100px] md:px-[32px] px-[12px]">
            <div className="lg:flex gap-[98px] pb-[48px] lg:max-w-hello mx-auto ">
                <div className="lg:max-w-[385px] pb-[40px] flex flex-col gap-[24px]">
                    <div className="flex gap-[5px] items-center text-[18px]">
                        <MainLogoSvg />
                    </div>
                    <div className="leading-[24px]">
                        {
                            "Hello:data empowers you to win in real estate by providing competitor analysis tools. Gain insights into competitor activity and understand your clients' needs to make data-driven decisions."
                        }
                    </div>
                    <div className="flex gap-[10px] items-center">
                        <a href="#">
                            {' '}
                            <FacebookSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <TiktokSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <LinkedInSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <PSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <TwitterSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <JSvg />{' '}
                        </a>
                        <a href="#">
                            {' '}
                            <YoutubSvg />{' '}
                        </a>
                    </div>
                </div>

                <div className="grid lg:grid-cols-4 grid-cols-2 gap-[85px]">
                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">
                            {'Quick Links'}
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="#featured">{'Feature'}</a>
                            <a href="#pricing">{'Pricing'}</a>
                            <a href="#faq">{'FAQ'}</a>
                            <a href={ROUTES.SIGN_IN}>{'Sign in'}</a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">
                            {'Company'}
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="#">{'Hello:here'}</a>
                            <a href="#">{'RoadMmap'}</a>
                            <a href="#">{'Contact us'}</a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">
                            {'Resources'}
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="#">{'Terms of use'}</a>
                            <a href="#">{'Privacty policy'}</a>
                            <a href="#">{'Terms & Condition'}</a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">
                            {'Other products'}
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="#">{'Mallorcamagic'}</a>
                            <a href="#">{'AI property matching app'}</a>
                            <a href="#">{'Hello:magic'}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="leading-[24px] md:flex justify-between lg:max-w-hello m-auto pb-[72px]">
                <div>{'© 2024 - Hello:data All rights reserved.'}</div>
                <div>
                    <span>{'A product of '}</span>
                    <span className="text-my-blue">{'Hello:here 💙'}</span>
                </div>
            </div>
        </div>
    );
};

export default FooterSection;
