import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { create } from 'zustand';

interface AuthState {
    signUp: (body: {
        fullName: string;
        email: string;
        address: string;
    }) => Promise<void>;
    userEmailAndId: {
        email: string;
        userId: string;
    };
    resendVerficationEmail: () => Promise<number>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
    userEmailAndId: { email: '', userId: '' },
    signUp: async (body) => {
        try {
            const { data, status } = await axiosInstance.post(
                `/user/register`,
                body,
                {
                    headers: {
                        'SKIP-AUTH': true,
                    },
                },
            );
            if (status === 201) {
                set({
                    userEmailAndId: {
                        email: body.email,
                        userId: data.data.userId,
                    },
                });
            }
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    resendVerficationEmail: async () => {
        const { userId } = get().userEmailAndId;
        try {
            const { status } = await axiosInstance.get(
                `/user/resend-mail/${userId}`,
            );
            return status;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));
