import { FormControl, MenuItem, Select } from '@mui/material';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { DEFAULT_PAGE } from 'util/Constants';

const stringlist = [15, 50];

const PrevButton = ({ isActive }: { isActive: boolean }) => {
    return (
        <div
            className={`${isActive ? 'border-opacity-40' : 'border-opacity-30 opacity-25'} cursor-pointer flex items-center justify-center w-[36px] h-[28px] rounded-l-[5px] border border-[#121212]`}
        >
            <span className="text-[20px]">{'<'}</span>
        </div>
    );
};

const NextButton = ({ isActive }: { isActive: boolean }) => {
    return (
        <div
            className={`${isActive ? 'border-opacity-40' : 'border-opacity-20 opacity-25'} cursor-pointer flex items-center justify-center w-[36px] h-[28px] rounded-r-[5px] border border-[#121212]`}
        >
            <span className="text-[20px]">{'>'}</span>
        </div>
    );
};

const CompetitorPropertyPagination = () => {
    const {
        fetchPropertiesForCompetitorCurrentPage,
        fetchPropertiesForCompetitorSetCurrentPage,
        fetchPropertiesForCompetitorCountPerPage,
        fetchPropertiesForCompetitorSetCountPerPage,
    } = usePropertiesStore();
    const { selectedSavedCompetitor } = useCompetitorsStore();

    const totalCount = selectedSavedCompetitor?.propertyCount!;
    const totalPages = Math.ceil(
        totalCount / fetchPropertiesForCompetitorCountPerPage,
    );

    const resetQueries = () => {
        queryClient.resetQueries({
            queryKey: [
                'fetchPropertiesForCompetitor',
                selectedSavedCompetitor?.companyId,
            ],
        });
    };

    const increaseCurrentPage = () => {
        const newpage = Math.max(
            fetchPropertiesForCompetitorCurrentPage + 1,
            DEFAULT_PAGE,
        );
        if (newpage <= totalPages) {
            fetchPropertiesForCompetitorSetCurrentPage(newpage);
            resetQueries();
        }
    };

    const decreaseCurrentPage = () => {
        const newpage = Math.min(
            fetchPropertiesForCompetitorCurrentPage - 1,
            totalPages,
        );
        if (newpage >= DEFAULT_PAGE) {
            fetchPropertiesForCompetitorSetCurrentPage(newpage);
            resetQueries();
        }
    };

    return (
        <div className="flex gap-[10px] items-center w-fit sm:text-[14px] text-[12px] font-medium">
            <div className="h-[28px]">
                <FormControl fullWidth className="!h-full">
                    <Select
                        value={fetchPropertiesForCompetitorCountPerPage}
                        onChange={(e) =>
                            fetchPropertiesForCompetitorSetCountPerPage(
                                Number(e.target.value),
                            )
                        }
                        displayEmpty
                        renderValue={(selected) => {
                            return `${selected} per page`;
                        }}
                        className="!w-full !h-full rounded-[8px] border-[#DFE1E1] leading-6 px-1 !text-[12px]"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                        {stringlist.map((item: number, idx: number) => (
                            <MenuItem
                                value={item}
                                key={idx}
                                sx={{ height: '24px' }}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <span>{`${(fetchPropertiesForCompetitorCurrentPage - 1) * fetchPropertiesForCompetitorCountPerPage + 1} - ${Math.min(fetchPropertiesForCompetitorCurrentPage * fetchPropertiesForCompetitorCountPerPage, totalCount)} of ${totalCount}`}</span>
            </div>

            <div className="flex">
                <div onClick={decreaseCurrentPage}>
                    <PrevButton
                        isActive={fetchPropertiesForCompetitorCurrentPage > 1}
                    />
                </div>
                <div onClick={increaseCurrentPage}>
                    <NextButton
                        isActive={
                            fetchPropertiesForCompetitorCurrentPage <=
                            totalPages - 1
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CompetitorPropertyPagination;
