import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import {
    getUserInfoFromLocalStorage,
    saveUserInfoToLocalStorage,
} from 'util/localStorageHelper';
import { UserInfo } from 'util/Types';
import { create } from 'zustand';

interface UserState {
    verifyUserEmail: (email: string) => Promise<string>;
    userInfo: Record<string, any>;
    fetchUser: () => Promise<UserInfo>;
    updateUserInfo: (data: Partial<UserInfo>) => Promise<void>;
    uploadUserProfileImage: (data: FormData) => Promise<number>;
    deleteUserAccount: () => Promise<number>;
}

const useUserStore = create<UserState>((set) => ({
    verifyUserEmail: async (token: string) => {
        try {
            const { data } = await axiosInstance.get(
                `/user/verify-mail/?token=${token}`,
                {
                    headers: {
                        'SKIP-AUTH': true,
                    },
                },
            );
            return data.data.message;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
        }
    },
    userInfo: {},
    fetchUser: async () => {
        try {
            const { data } = await axiosInstance.get('/user/get-user');
            saveUserInfoToLocalStorage(data.data);
            set({ userInfo: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
        }
    },
    updateUserInfo: async (bodyData) => {
        try {
            const { data } = await axiosInstance.patch(
                '/user/update-user',
                bodyData,
            );
            const { fullName, phone } = data.data;
            const userInfo = getUserInfoFromLocalStorage()!;
            userInfo.fullName = fullName;
            userInfo.phone = phone;
            saveUserInfoToLocalStorage(userInfo);
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    uploadUserProfileImage: async (formData) => {
        try {
            const { data, status } = await axiosInstance.patch(
                '/user/upload-profile-picture',
                formData,
            );
            const user = data.data.user;
            saveUserInfoToLocalStorage(user);
            return status;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    deleteUserAccount: async () => {
        try {
            const { status } = await axiosInstance.delete('/user/delete-user');
            return status;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useUserStore;
